import React from "react";

const Banner = ({ pageName, backgroundImage }) => {
  return (
    <div
      className="relative bg-cover bg-center h-[250px] flex items-center justify-center"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
      }}
    >
      <div className="absolute inset-0 bg-[#33bbf3] bg-opacity-20"></div>
      <div className="relative z-10 text-center">
        <h1 className="text-2xl font-bold text-white md:text-3xl lg:text-4xl">
          {pageName}
        </h1>
      </div>
    </div>
  );
};

export default Banner;
