import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/common/ScrollToTop";
import Layout from "./Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import PartList from "./pages/PartList";
import PartRequest from "./pages/PartRequest";
import Resources from "./pages/Resources";
import CustomerService from "./pages/CustomerService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndCondition from "./pages/TermsAndCondition";
import ContactUs from "./pages/ContactUs";
import Warranty from "./pages/Warranty";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/part-list" element={<PartList />}></Route>
          <Route path="/part-request" element={<PartRequest />}></Route>
          <Route path="/resources" element={<Resources />}></Route>
          <Route path="/customer-service" element={<CustomerService />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route
            path="/terms-and-conditions"
            element={<TermsAndCondition />}
          ></Route>
          <Route path="/warranty-policy" element={<Warranty/>}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
