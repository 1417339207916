import React, { useState, useEffect, useRef, useCallback } from "react";

const testimonials = [
  {
    id: 1,
    name: "John Doe",
    message:
      "I'd rate Global Auto Expert a 10 out of 10. Not only that, I intend to use your services again. I was impressed with a particular staff's tendency to do exactly what he said he would.",
  },
  {
    id: 2,
    name: "Jane Smith",
    message:
      "Exceptional service and attention to detail! I'm glad I chose Global Auto Expert.",
  },
  {
    id: 3,
    name: "Mike Johnson",
    message:
      "Quick and reliable service. My go-to place for auto parts from now on.",
  },
  {
    id: 4,
    name: "Emily Davis",
    message:
      "Affordable prices and great customer support. Highly recommended!",
  },
  {
    id: 5,
    name: "Sarah Lee",
    message:
      "Professional and courteous team. They went above and beyond my expectations.",
  },
];

const Testimonial = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const testimonialRef = useRef(null);

  const handleNext = useCallback(() => {
    setCurrentTestimonial((prev) => {
      const newIndex = (prev + 1) % testimonials.length;
      slideTestimonial(newIndex, "left");
      return newIndex;
    });
  }, []);

  const handlePrev = useCallback(() => {
    setCurrentTestimonial((prev) => {
      const newIndex = (prev - 1 + testimonials.length) % testimonials.length;
      slideTestimonial(newIndex, "right");
      return newIndex;
    });
  }, []);

  const slideTestimonial = (newIndex, direction) => {
    if (testimonialRef.current) {
      testimonialRef.current.style.transition = "none";
      testimonialRef.current.style.transform =
        direction === "left" ? "translateX(100%)" : "translateX(-100%)";

      setTimeout(() => {
        testimonialRef.current.style.transition = "transform 0.5s ease-in-out";
        testimonialRef.current.style.transform = "translateX(0)";
      }, 50);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(timer);
  }, [handleNext]);

  return (
    <section className="bg-gradient-to-r from-[#4f24db] to-[#1490dd] py-6 sm:py-8 px-4 md:px-8">
      <div className="max-w-4xl mx-auto text-center">
        <p className="text-white text-base sm:text-lg md:text-xl mb-4 sm:mb-6">
          What Our Happy Clients say about us
        </p>
        <h1 className="text-white text-xl sm:text-2xl md:text-4xl font-bold mb-4 sm:mb-6">
          TESTIMONIAL
        </h1>
        <h2 className="text-white text-sm sm:text-base md:text-xl mb-6 sm:mb-8">
          The highest standards. The happiest customers.
          <br />
          <span className="font-light text-xs sm:text-sm">
            Rated by store owner
          </span>
        </h2>

        <div className="relative w-full max-w-2xl mx-auto">
          <div className="bg-white p-4 sm:p-6 md:p-8 rounded-lg shadow-lg mb-6 sm:mb-8 mx-auto w-full sm:w-[90%] md:w-[80%]">
            <div
              ref={testimonialRef}
              className="text-gray-800 text-xs sm:text-sm md:text-lg mb-4 md:mb-8 transition-transform duration-500 ease-in-out text-center"
            >
              "{testimonials[currentTestimonial].message}"
            </div>
            <div className="absolute left-1/2 -bottom-4 transform -translate-x-1/2 w-0 h-0 border-l-[12px] border-r-[12px] border-t-[16px] border-l-transparent border-r-transparent border-t-white"></div>
          </div>

          <button
            onClick={handlePrev}
            className="absolute left-0 sm:left-4 top-1/2 transform -translate-y-1/2 bg-white text-blue-600 p-1 sm:p-2 rounded-full hover:bg-blue-100 transition-colors"
            aria-label="Previous testimonial"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 sm:h-6 sm:w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>

          <button
            onClick={handleNext}
            className="absolute right-0 sm:right-4 top-1/2 transform -translate-y-1/2 bg-white text-blue-600 p-1 sm:p-2 rounded-full hover:bg-blue-100 transition-colors"
            aria-label="Next testimonial"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 sm:h-6 sm:w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>

        <div className="relative mt-6 sm:mt-8">
          <div className="text-white text-xs sm:text-sm md:text-xl font-semibold px-4 sm:px-6 py-2 sm:py-3 inline-block border-2 sm:border-4 border-yellow-300 rounded-[50%]">
            {testimonials[currentTestimonial].name}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
