import React from "react";
import auto_parts from "../../assets/product/Autoparts2.png";
import car_accessories from "../../assets/product/resources-accessories.jpg";
import recreational from "../../assets/product/recreational.png";
import car_care from "../../assets/product/car care.jpg";
import wheel from "../../assets/product/wheel.jpeg";
import luxury from "../../assets/product/luxury.webp";

const cardsData = [
  { image: auto_parts, title: "Auto Part Insights", count: 15 },
  { image: car_accessories, title: "Car Accessories", count: 6 },
  { image: car_care, title: "Car Care", count: 3 },
  { image: recreational, title: "Recreational Vehicles", count: 5 },
  { image: luxury, title: "Tips & Tricks", count: 6 },
  { image: wheel, title: "Tire and Wheels", count: 6 },
];

const Card = ({ image, title, count }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full">
      <img src={image} alt={title} className="w-full h-52 object-cover" />
      <div className="p-4 relative">
        <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
        <span className="absolute top-4 right-4 bg-blue-500 text-white rounded-full w-8 h-8 flex items-center justify-center text-sm font-bold">
          {count}
        </span>
      </div>
    </div>
  );
};

const CardsList = () => {
  return (
    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 md:p-20 p-10">
      {cardsData.map((card, index) => (
        <Card
          key={index}
          image={card.image}
          title={card.title}
          count={card.count}
        />
      ))}
    </div>
  );
};

export default CardsList;
