import React from "react";
import Banner from "../components/common/Banner";
import banner from "../assets/part.jpg";
import BottomCarousel from "../components/common/BottomCarousel";
import { useNavigate } from "react-router-dom";
import warranty from "../assets/warenty.png";

const Warranty = () => {
  const navigate = useNavigate();
  return (
    <div>
      {/* Banner Section */}
      <Banner pageName="Warranty" backgroundImage={banner} />

    
      {/* Warranty Content Section */}
      <div className="max-w-6xl mx-auto md:p-10 p-5">
        <h1 className="text-3xl text-blue-600 font-semibold text-start mb-5">
          GLOBAL AUTO EXPERTS 30 DAY WARRANTY
        </h1>
        <div className="text-lg text-gray-700">
          <div className="flex flex-row gap-6 justify-center items-center">
            <div>
              <p className="mb-4 text-justify">
                At Global Auto Experts, we offer high-quality auto parts with
                confidence. All parts are covered by a 30-day warranty from the
                date of delivery. Please note that this warranty applies to
                parts only, covering defective, damaged, or incorrectly shipped
                items. Labor costs and storage fees are not included.
              </p>
              <p className="mb-4 text-justify">
                In case a part is returned due to personal reasons, or for
                reasons that do not meet the specifications mentioned in this
                warranty, Global Auto Experts reserves the right to apply a 25%
                restocking fee and deduct the shipping costs from the refund.
                Return shipping costs are the responsibility of the buyer and
                will not be reimbursed.
              </p>
            </div>
            <div>
              <img src={warranty} alt="Warranty Details" className="mx-auto md:block hidden" />
            </div>
          </div>
          <p className="mb-4 text-justify">
            In the event that the part is defective we will first attempt to
            replace it and if a replacement cannot be sent, we will issue a
            refund for the total sale amount. Parts must be installed by a
            certified mechanic. We may require a diagnostic report, service
            documents, and/or pictures to validate & process warranty claims.
          </p>
          <p className="mb-4 text-justify">
            The first part must be returned before a replacement is provided. In
            the event a reorder is made prior to return of the first part we
            will require payment for the second part and we will refund the
            initial order upon return. Return shipping costs will not be
            reimbursed. Upon contacting Quality Auto Parts please provide a
            detailed explanation as to why the part is being returned and
            provide supporting documents.
          </p>
          <p className="mb-4 text-justify">
            Our warranty does not extend to items delivered to the address
            provided at the time of sale as indicated by tracking information,
            but left unclaimed, lost, or stolen.
          </p>
          <p className="mb-4 text-justify">
            Items ordered for <strong>'testing purposes only'</strong> will not
            be available for return. Verify with a mechanical or body expert the
            actual part needed before ordering.
          </p>
          <p className="mb-4 text-justify">
            Items modified, tampered with, or taken apart for testing will not
            be available for return. Items returned that are not the original
            part shipped will not be refunded.{" "}
            <strong>
              PARTS ARE UNIQUELY IDENTIFIED BY MARKINGS ON THE PART.
            </strong>{" "}
            Items returned that are not in the original condition shipped will
            not be refunded. Warranty is not applicable for Parts shipped
            outside USA.
          </p>
          <p className="mb-4 text-justify">
            Parts must be returned within the 30 days warranty period. We do not
            accept returns or issue refunds after the 30 days warranty period.
          </p>
        </div>
      </div>

      {/* Additional Part-Specific Warranties */}
      <div className="max-w-6xl mx-auto md:p-10 p-5">
        <h2 className="text-xl text-[#034387] rounded-md bg-[#cce5ff] font-semibold mb-5 p-4">
          Exceptions and Part-Specific Warranties
        </h2>
        <div className="text-lg text-gray-700 space-y-6">
          <p>
            <strong>Engines:</strong> Are complete assemblies, these may include
            manifolds, oil pan, timing belts and covers, wiring, fuel injection
            or carburettor, we guarantee only the long block these accessories
            may need to be replaced for better performance. Accessories such as
            but not limited to Turbos, Starters, air compressors, alternator,
            power steering pumps, optical distributors or electrical water pumps
            are usually not included nor under warranty when included along with
            Engine sale. If these parts are purchased separately, they will be
            under warranty. Other items that may need to be replaced are the
            water pump, distributor cap, spark plugs and wires and possibly
            timing chain. Quality Auto Parts will not be held liable for these
            parts listed as they are considered high-wear parts.
          </p>
          <p>
            <strong>Transmissions:</strong> We guarantee that it will shift
            well, gears to be good and bearings to be good. Thoroughly clean all
            components of the transmission including oil pan before assembly.
            Replace oil and filter in all automatic transmissions. You must
            replace ALL seals and gaskets prior to installation. Flush & flow
            test cooler and lines. Fully engage torque converter in front pump.
            Manual transmission you must install a new clutch, pressure plate,
            and slave cylinder. You must also have your fly wheel turned prior
            to installation. Fill and check fluid to insure proper levels.
          </p>
          <p>
            <strong>Axle Assembly:</strong> Are sold as assembly form hub to
            hub, they are guaranteed to have good gears and bearings. Most axles
            will have surface rust, this will not impact performance. Parts
            cannot be returned due to rust. We do not guarantee or warranty on
            Brake parts or backing plate.
          </p>
          <p>
            <strong>Electronic Modules:</strong> Such as engine computers and
            body control modules, transmission modules, transfer case modules,
            ABS control modules are only guaranteed to have the correct
            manufacturer part number and are not sold for testing purposes. All
            electrical items have a REPLACEMENT WARRANTY only. Up to one
            replacement will be sent in order to rectify a non-working module.
            Modules may need to be “reset or reprogramed” by the dealer. Quality
            Auto Parts will not accept returns or issue refund until the
            customer can provide written documentation from an Authorized Dealer
            proving the part was reprogrammed and still not functioning
            properly.
          </p>
          <p>
            <strong>Instrument and Speedometer Clusters:</strong> We do not
            guarantee mileage. For particular vehicles such as but not limited
            to Lexus, Audi, BMW, Mercedes and Cadillac the customer will be
            required to take the cluster to a dealership to have the unit
            reprogrammed. Quality Auto Parts will not return or refund any units
            until the customer can provide written documentation from an
            Authorized Dealer proving the part was reprogrammed and still not
            functioning properly.
          </p>
          <p>
            <strong>Cylinder Heads:</strong> Guaranteed to not be warped or
            cracked. Rings and valves may need re-machining.
          </p>
          <p>
            <strong>Suspension:</strong> Bushings, ball joints, and CV joints
            are not warranted on any part.
          </p>
          <p>
            <strong>Doors & Tailgates:</strong> Warranty applies only to the
            door shell, not accessories like windows or hinges.
          </p>
          <p>
            <strong>Other Body Parts:</strong> Color match is not guaranteed,
            and repainting may be necessary for a proper match.
          </p>
        </div>
      </div>

      {/* Return Policy Section */}
      <div className="max-w-6xl mx-auto md:p-10 p-5">
        <h2 className="text-xl text-[#034387] rounded-md bg-[#cce5ff] font-semibold mb-5 p-4">
          Return Policy
        </h2>
        <div className="text-lg text-gray-700 space-y-6">
          <p>
            All returns must be notified and approved by our customer service
            department. DO NOT SEND THE PART BACK BEFORE RECEIVING A{" "}
            <strong>“RMA”</strong>. Parts returned without return merchandise
            authorization (RMA) will attract a 25% restocking fee and shipping
            cost.
          </p>
          <p>
            Returning the part is the responsibility of the customer; we do not
            cover return shipping costs. We must be provided with a return
            tracking number when the item is returned. The item must be packed
            well to avoid shipping damage & returned within 7 business days of
            the receipt of the RMA.
          </p>
          <p>
            The return address will be provided in the RMA. Items returned
            damaged in transit will void the warranty and no refund will be
            issued. Once the item is returned, it will be inspected and upon
            approval, we will issue a replacement or refund.
          </p>
          <p>
            We stand behind our warranties and want to make you, our customer,
            at ease when purchasing a used auto part. If you have any questions
            feel free to call us {""}{" "}
            <span className="text-blue-500"> +1-888-711-6990</span>
          </p>
        </div>
      </div>

      {/* Find Your Orders Section */}
      <div className="bg-blue-600 py-5">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-center md:justify-between px-5">
          <div className="flex flex-col items-center md:items-start gap-1 text-center md:text-left">
            <p className="text-white md:text-5xl text-3xl font-semibold mb-3 md:mb-0">
              FIND YOUR ORDERS?
            </p>
            <span className="md:text-4xl text-2xl text-white">
              +1-888-711-6990
            </span>
          </div>
          <button
            onClick={() => navigate("/part-request")}
            className="bg-yellow-400 text-black font-bold py-2 px-6 mt-4 md:mt-0 rounded-md hover:bg-yellow-300"
          >
            Find My Part Now
          </button>
        </div>
      </div>

      {/* Bottom Carousel Section */}
      <BottomCarousel />
    </div>
  );
};

export default Warranty;
