import React, { useState } from "react";
import logo from "../../assets/expo2.png";
import sideLogo from "../../assets/expo.png";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "../context/NavigationContext";

const Navbar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { activeItem, handleNavigation } = useNavigation();
  const navigate = useNavigate();

  const handleSidebarNavigation = (path) => {
    handleNavigation(path, navigate);
    setSidebarOpen(false);
  };

  const menuItems = [
    { name: "Home", path: "/" },
    { name: "About Us", path: "/about" },
    { name: "Part List", path: "/part-list" },
    { name: "Part Request", path: "/part-request" },
    { name: "Resources", path: "/resources" },
    { name: "Customer Service", path: "/customer-service" },
  ];

  return (
    <>
      {/* Navbar */}
      <nav className="flex justify-between items-center px-6 py-6 bg-white shadow-md">
        <div className="flex items-center space-x-4">
          <img src={logo} alt="Logo" className="h-10" />
        </div>
        <ul className="hidden lg:flex space-x-6 text-gray-700">
          {menuItems.map((item) => (
            <li key={item.path}>
              <div
                onClick={() => handleNavigation(item.path, navigate)}
                className={`cursor-pointer px-4 py-2 rounded-lg transition-colors duration-200 ${
                  activeItem === item.path
                    ? "bg-blue-600 text-white font-bold"
                    : "hover:text-red-600"
                }`}
              >
                {item.name}
              </div>
            </li>
          ))}
        </ul>
        <button
          className="lg:hidden text-2xl text-gray-700 focus:outline-none"
          onClick={() => setSidebarOpen(true)}
        >
          ☰
        </button>
      </nav>

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-gradient-to-r from-[#090979] to-[#00d4ff] bg-opacity-70 shadow-md transform ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 z-50`}
      >
        <button
          className="text-2xl text-white font-bold absolute top-4 left-4 focus:outline-none"
          onClick={() => setSidebarOpen(false)}
        >
          ×
        </button>
        <div className="flex flex-col items-center mt-8">
          <img src={sideLogo} alt="Logo" className="h-16 w-auto" />
        </div>
        <ul className="mt-12 space-y-4 px-6 text-white">
          {menuItems?.map((item) => (
            <li key={item.path}>
              <div
                onClick={() => handleSidebarNavigation(item.path)}
                className={`block px-4 py-2 rounded-lg transition-colors duration-200 ${
                  activeItem === item.path
                    ? "bg-blue-600 text-white font-bold"
                    : "hover:text-red-300"
                }`}
              >
                {item.name}
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Overlay for Sidebar */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}
    </>
  );
};

export default Navbar;
