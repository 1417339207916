import React from "react";
import image from "../../assets/banner1.jpg";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "../context/NavigationContext";

const Store = () => {
  const navigate = useNavigate();
  const { handleNavigation } = useNavigation();

  const motionVariantsTop = {
    hidden: { opacity: 0, y: -40 },
    visible: { opacity: 1, y: 0 },
  };

  const motionProperties = {
    initial: "hidden",
    whileInView: "visible",
    transition: { duration: 1, delay: 0.5 },
    viewport: { once: true },
  };

  return (
    <>
      <div
        className="relative px-8 py-24 mt-5"
        style={{
          backgroundImage: `url(${image})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        {/* Black overlay with opacity */}
        <div className="absolute inset-0 bg-gradient-to-r from-[#4f24db] to-[#1490dd] opacity-80"></div>

        <div className="relative flex flex-col justify-center items-center gap-8 px-4 md:px-10 lg:px-20">
          {/* Welcome Text */}
          <div className="flex flex-col justify-center items-center gap-6 w-full lg:w-1/2">
            <div>
              <motion.h1
                {...motionProperties}
                variants={motionVariantsTop}
                className="md:text-6xl text-center text-xl font-bold text-white tracking-wider"
              >
                WELCOME
              </motion.h1>
              <motion.h1
                {...motionProperties}
                variants={motionVariantsTop}
                className="md:text-3xl text-center text-xl font-semibold text-white tracking-wider"
              >
                TO OUR STORE
              </motion.h1>
            </div>
          </div>

          {/* Content Section */}
          <div className="relative md:p-8  shadow-lg w-full">
            <p className="text-white text-center md:text-lg">
              At Global Auto Experts, we are dedicated to being your go-to
              source for premium-quality used OEM automotive parts and
              accessories. Since 2014, we’ve proudly served hundreds of
              thousands of customers worldwide, offering tailored solutions to
              help you find the perfect parts for your vehicle.
            </p>
            <p className="text-white text-center md:text-lg mt-4">
              Based in Philadelphia, Pennsylvania, our extensive network of
              trusted suppliers spans across North America, ensuring we can
              locate even the hardest-to-find parts for your car, truck, or SUV.
              Our mission is to provide you with reliable, affordable options
              that are conveniently sourced near your home or business.
            </p>
          </div>

          {/* About Us Button */}
          <motion.div
            {...motionProperties}
            variants={motionVariantsTop}
            className="mt-6"
          >
            <button
              className="px-6 py-3 text-black font-bold bg-yellow-500 rounded-lg hover:bg-yellow-600 transition duration-300"
              onClick={() => handleNavigation("/about", navigate)}
            >
              ABOUT US
            </button>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Store;
