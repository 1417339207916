import React from "react";

const SearchByPart = () => {
  const carMakes = [
    ["AMC", "Acura", "Alfa-Romeo", "Audi", "BMW"],
    ["Buick", "Cadillac", "Chevy", "Chrysler", "Cooper"],
    ["Daihatsu", "Dodge", "Eagle", "Fiat-Lancia", "Ford"],
    ["GMC", "Honda", "Hummer", "Hyundai", "Infiniti"],
    ["Isuzu", "Jaguar", "Jeep", "Land-Rover", "Lexus"],
    ["Mazda", "Mercedes-Benz", "Mercury", "Mitsubishi", "Nissan"],
    ["Opel", "Peugeot", "Plymouth", "Pontiac", "Porsche"],
    ["Rover", "Saab", "Saturn", "Scion", "Subaru"],
    ["Toyota", "Volkswagen", "Volvo", "Yugo", "Renault"],
  ];

  return (
    <div className="md:p-20 p-10">
      <h1 className="text-xl font-bold mb-4">Search by Car Make</h1>
      <div className="grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-4 gap-4 md:px-10">
        {carMakes?.flat().map((make, index) => (
          <div
            key={index}
            className="border border-gray-300 p-2 rounded-md text-center shadow-sm hover:bg-gray-100"
          >
            {make}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchByPart;
