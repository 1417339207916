import React from "react";
import Banner from "../components/common/Banner";
import banner from "../assets/part.jpg";
import BottomCarousel from "../components/common/BottomCarousel";
import message from "../assets/one inbox.png";
import { useNavigate } from "react-router-dom";

const CustomerService = () => {
  const navigate = useNavigate();
  return (
    <div>
      {/* Banner Section */}
      <Banner pageName="Customer Service" backgroundImage={banner} />

      {/* Customer Service Information Section */}
      <div className="bg-gray-100 lg:py-20 py-10 lg:px-32 px-10 flex lg:flex-row flex-col justify-center items-center gap-12">
        <div className=" text-justify lg:w-1/2 w-full">
          <p className="text-gray-700 text-lg mb-6">
            We have a dedicated team of customer service agents available to
            assist you with your order. Due to the personal attention we provide
            each customer, we staff this department to be available to you
            throughout the day.
          </p>
          <p className="text-gray-700 text-lg mb-4">
            Hours of operation are from Monday to Saturday from 7am CST till 7pm
            CST. You can reach us directly by dialing
            <span className="text-blue-600 font-semibold"> +1-888-711-6990</span>
            .
          </p>
        </div>
        <div className="lg:w-1/2 w-full">
          <img src={message} alt="inbox" />
        </div>
      </div>

      {/* Find Your Orders Section */}
      <div className="bg-blue-600 py-5">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-center md:justify-between px-5">
          <div className="flex flex-col items-center md:items-start gap-1 text-center md:text-left">
            <p className="text-white md:text-5xl text-3xl font-semibold mb-3 md:mb-0">
              FIND YOUR ORDERS?
            </p>
            <span className="md:text-4xl text-2xl text-white">
              +1-888-711-6990
            </span>
          </div>
          <button
            onClick={() => navigate("/part-request")}
            className="bg-yellow-400 text-black font-bold py-2 px-6 mt-4 md:mt-0 rounded-md hover:bg-yellow-300"
          >
            Find My Part Now
          </button>
        </div>
      </div>

      {/* Bottom Carousel */}
      <BottomCarousel />
    </div>
  );
};

export default CustomerService;
