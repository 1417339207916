import React from "react";
import part from "../../assets/auto.png";

const AboutSection = () => {
  return (
    <div className="bg-gray-100 md:p-20 p-10">
      <div className="container mx-auto px-4">
        {/* Content */}
        <div className="flex flex-col md:flex-row items-center gap-10">
          {/* Text Content */}
          <div className="md:w-2/3 mb-8 md:mb-0">
            <p className="text-lg text-gray-700 mb-4 text-justify">
              At Global Auto Experts, we are dedicated to being your go-to
              source for premium-quality used OEM automotive parts and
              accessories. Since 2014, we’ve proudly served hundreds of
              thousands of customers worldwide, offering tailored solutions to
              help you find the perfect parts for your vehicle.
            </p>
            <p className="text-lg text-gray-700 mb-4 text-justify">
              Based in Philadelphia, Pennsylvania, our extensive network of
              trusted suppliers spans across North America, ensuring we can
              locate even the hardest-to-find parts for your car, truck, or SUV.
              Our mission is to provide you with reliable, affordable options
              that are conveniently sourced near your home or business.
            </p>
            <p className="text-lg text-gray-700 text-justify">
              Once you place an order, we’ll ship it directly to your doorstep,
              workplace, or mechanic to get you back on the road quickly and
              hassle-free. Trust us to deliver the quality, affordability, and
              convenience you deserve.
              <a
                href="tel:+1-888-711-6990"
                className="text-blue-600 font-semibold hover:underline"
              >
                +1-888-711-6990
              </a>
              .
            </p>
          </div>

          {/* Image */}
          <div className="md:w-1/3 w-full">
            <img
              src={part} // Replace this with the actual image URL
              alt="Auto parts"
              className="w-full rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
