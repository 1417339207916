import React from "react";
import Banner from "../components/common/Banner";
import banner from "../assets/part.jpg";
import BottomCarousel from "../components/common/BottomCarousel";
import { useNavigate } from "react-router-dom";
import email from "../assets/email.png";
import ring from "../assets/ringing.png";

const ContactUs = () => {
  const navigate = useNavigate();
  return (
    <div>
      {/* Banner Section */}
      <Banner pageName="Contact Us" backgroundImage={banner} />

      <div className="flex flex-col md:flex-row justify-center xl:gap-40 lg:gap-32 items-center md:px-20 md:py-32 p-10 bg-gray-100 text-black">
        {/* Left: Car Image */}
        <div className=" flex flex-col gap-2">
          <h2 className="text-3xl font-bold">Global Auto Experts</h2>
          <p className="mt-2 md:text-2xl text-xl">
            30 S 15th St Ste 1550 Num 173221, Philadelphia, PA 19102
          </p>
        </div>

        {/* Right: Contact Information */}
        <div className="text-center md:mt-0">
          <div className="mt-4 space-y-4">
            <p className="flex items-center gap-4">
              <img
                src={email}
                alt="Email"
                className="md:w-10 md:h-10 h-6 w-6"
              />
              <span className="md:text-2xl text-lg">
                info@globalautoexperts.us
              </span>
            </p>
            <p className="flex items-center gap-4">
              <img src={ring} alt="Phone" className="md:w-10 md:h-10 h-6 w-6" />
              <span className="md:text-2xl text-lg">+1-888-711-6990</span>
            </p>
          </div>
        </div>
      </div>

      {/* Find Your Orders Section */}
      <div className="bg-blue-600 py-5">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-center md:justify-between px-5">
          <div className="flex flex-col items-center md:items-start gap-1 text-center md:text-left">
            <p className="text-white md:text-5xl text-3xl font-semibold mb-3 md:mb-0">
              FIND YOUR ORDERS?
            </p>
            <span className="md:text-4xl text-2xl text-white">
              +1-888-711-6990
            </span>
          </div>
          <button
            onClick={() => navigate("/part-request")}
            className="bg-yellow-400 text-black font-bold py-2 px-6 mt-4 md:mt-0 rounded-md hover:bg-yellow-300"
          >
            Find My Part Now
          </button>
        </div>
      </div>

      {/* Bottom Carousel Section */}
      <BottomCarousel />
    </div>
  );
};

export default ContactUs;
