import React from "react";
import image from "../../assets/banner6.jpg";
import { motion } from "framer-motion";
import icon1 from "../../assets/open-hand.png";
import icon2 from "../../assets/car1.png";
import icon3 from "../../assets/truck.png";
import icon4 from "../../assets/discount-sticker.png";

const Services = () => {
  const motionVariantsTop = {
    hidden: { opacity: 0, y: -40 },
    visible: { opacity: 1, y: 0 },
  };

  const motionProperties = {
    initial: "hidden",
    whileInView: "visible",
    transition: { duration: 1, delay: 0.5 },
    viewport: { once: true },
  };

  return (
    <>
      <div
        className="relative px-8 py-24 mt-5"
        style={{
          backgroundImage: `url(${image})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        {/* Black overlay with opacity */}
        <div className="absolute inset-0 bg-black opacity-50"></div>

        <div className="relative flex flex-col justify-center items-center gap-8 px-4 md:px-10 lg:px-20">
          <div className="flex flex-col justify-center items-center gap-6 w-full lg:w-1/2">
            <div>
              <motion.h1
                {...motionProperties}
                variants={motionVariantsTop}
                className="md:text-6xl text-center text-xl font-bold text-white tracking-wider"
              >
                SERVICES
              </motion.h1>
              <motion.h1
                {...motionProperties}
                variants={motionVariantsTop}
                className="md:text-3xl text-center text-xl font-semibold text-white tracking-wider"
              >
                MILLIONS OF QUALITY USED OEM PARTS
              </motion.h1>
            </div>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-8 w-full">
            {/* Reusable Service Card Component */}
            {[
              { icon: icon1, text: "QUICK QUOTES THAT SAVE YOU MONEY" },
              { icon: icon4, text: "SAVE OVER 50% OFF DEALER PRICES" },
              { icon: icon3, text: "FAST DELIVERY TO YOUR HOME OR MECHANIC" },
              { icon: icon2, text: "HOME OR MECHANIC, WE SERVICE ALL MAJOR MAKES" },
            ]?.map((service, index) => (
              <div
                key={index}
                className="group flex flex-col items-center justify-center text-center bg-white bg-opacity-10 p-6 rounded-lg shadow-md transition-all duration-500 "
              >
                <div
                  className="p-6 rounded-full bg-blue-300 mb-4 transition-transform duration-500   hover:bg-white hover:bg-opacity-100"
                >
                  <img
                    src={service.icon}
                    alt={service.text}
                    className="w-12 h-12 group-hover:rotate-360 group-hover:scale-110"
                  />
                </div>
                <h2 className="text-white  font-bold text-xl md:text-2xl">
                  {service.text}
                </h2>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
