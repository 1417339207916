import React from "react";
import { motion } from "framer-motion";
import image1 from "../../assets/acura.jpg";
import image2 from "../../assets/AMC.jpg";
import image3 from "../../assets/audi.png";
import image4 from "../../assets/BMW.png";
import image5 from "../../assets/British-Leyland.png";
import image6 from "../../assets/Cooper.png";
import image7 from '../../assets/alpha.png';
import image8 from '../../assets/lam.png';

const logos = [
  { src: image1, alt: "Brand 1" },
  { src: image2, alt: "Brand 2" },
  { src: image3, alt: "Brand 3" },
  { src: image4, alt: "Brand 4" },
  { src: image5, alt: "Brand 5" },
  { src: image6, alt: "Brand 6" },
  { src: image7, alt: "Brand 7" },
  { src: image8, alt: "Brand 8" },

];

const BottomCarousel = () => {
    const totalWidth = logos.length * (256 + 32);
  return (
    <div className="w-full bg-white py-8">
      <div className="overflow-hidden relative">
        <motion.div
          className="flex items-center space-x-8"
          animate={{
            x: [-totalWidth / 2, -totalWidth],
          }}
          transition={{
            x: {
              repeat: Infinity,
              repeatType: "loop",
              duration: 60, // Increased duration for slower movement
              ease: "linear",
            },
          }}
        >
          {/* Double the logos for seamless loop */}
          {[...logos, ...logos].map((brand, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-64 h-32 bg-white border border-gray-200 rounded-lg p-6 flex items-center justify-center hover:shadow-lg transition-shadow duration-300"
            >
              <img
                src={brand.src}
                alt={brand.alt}
                className="max-w-full max-h-full object-contain"
              />
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default BottomCarousel;
