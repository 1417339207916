import React from "react";
import { useNavigate } from "react-router-dom";
import img1 from "../../assets/product/qap-electriccar.webp";
import img2 from "../../assets/product/blower-motor.png";
import img3 from "../../assets/product/engine (1).png";
import img4 from "../../assets/product/computer.png";
import { useNavigation } from "../context/NavigationContext";

const Card = () => {
  const navigate = useNavigate();
  const { handleNavigation } = useNavigation();

  const navigateToPartList = (tab) => {
    navigate("/part-list", { state: { activeTab: tab } });
  };

  return (
    <div className="container mx-auto md:p-20 p-10 grid grid-cols-1 lg:grid-cols-2 gap-6">
      {/* Card 1 */}
      <div className="flex flex-col sm:flex-row items-center bg-gradient-to-r from-[#4f24db] to-[#1490dd] p-6 text-white rounded-lg shadow-lg">
        <div className="flex-1 text-center sm:text-left mb-4 sm:mb-0 sm:mr-6">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2">
            DRIVETRAIN
          </h1>
          <h2 className="text-lg sm:text-xl md:text-2xl font-semibold mb-3">
            PARTS
          </h2>
          <p className="text-sm sm:text-base mb-4 max-w-xs mx-auto sm:mx-0">
            Quality Used Engine & Transmission Parts sold as complete assemblies
          </p>
          <button
            onClick={() => {
              handleNavigation("/part-list", navigate);
              navigateToPartList("part-type");
            }}
            className="text-black bg-yellow-300 py-2 px-4 rounded-md hover:bg-yellow-400 transition duration-300 w-full sm:w-auto"
          >
            Know More
          </button>
        </div>
        <div className="flex-shrink-0">
          <img
            src={img1}
            alt="Drivetrain Parts"
            className="rounded-md w-full sm:w-40 md:w-48 h-auto object-cover"
          />
        </div>
      </div>

      {/* Card 2 */}
      <div className="flex flex-col sm:flex-row items-center bg-gradient-to-r from-[#1490dd] to-[#4f24db] p-6 text-white rounded-lg shadow-lg">
        <div className="flex-1 text-center sm:text-left mb-4 sm:mb-0 sm:mr-6">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2">
            SUSPENSION
          </h1>
          <h2 className="text-lg sm:text-xl md:text-2xl font-semibold mb-3">
            PARTS
          </h2>
          <p className="text-sm sm:text-base mb-4 max-w-xs mx-auto sm:mx-0">
            Reliable and durable suspension parts for smooth rides
          </p>
          <button
            onClick={() => {
              handleNavigation("/part-list", navigate);
              navigateToPartList("part-type");
            }}
            className="text-black bg-yellow-300 py-2 px-4 rounded-md hover:bg-yellow-400 transition duration-300 w-full sm:w-auto"
          >
            Know More
          </button>
        </div>
        <div className="flex-shrink-0">
          <img
            src={img2}
            alt="Suspension Parts"
            className="rounded-md w-full sm:w-40 md:w-48 h-auto object-cover"
          />
        </div>
      </div>

      {/* Card 3 */}
      <div className="flex flex-col sm:flex-row items-center bg-gradient-to-r from-[#1490dd] to-[#4f24db] p-6 text-white rounded-lg shadow-lg">
        <div className="flex-1 text-center sm:text-left mb-4 sm:mb-0 sm:mr-6">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2">
            BRAKING
          </h1>
          <h2 className="text-lg sm:text-xl md:text-2xl font-semibold mb-3">
            SYSTEM
          </h2>
          <p className="text-sm sm:text-base mb-4 max-w-xs mx-auto sm:mx-0">
            High-quality braking systems for optimal safety
          </p>
          <button
            onClick={() => {
              handleNavigation("/part-list", navigate);
              navigateToPartList("part-type");
            }}
            className="text-black bg-yellow-300 py-2 px-4 rounded-md hover:bg-yellow-400 transition duration-300 w-full sm:w-auto"
          >
            Know More
          </button>
        </div>
        <div className="flex-shrink-0">
          <img
            src={img3}
            alt="Braking System"
            className="rounded-md w-full sm:w-40 md:w-48 h-auto object-cover"
          />
        </div>
      </div>

      {/* Card 4 */}
      <div className="flex flex-col sm:flex-row items-center bg-gradient-to-r from-[#4f24db] to-[#1490dd] p-6 text-white rounded-lg shadow-lg">
        <div className="flex-1 text-center sm:text-left mb-4 sm:mb-0 sm:mr-6">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2">
            ELECTRICAL
          </h1>
          <h2 className="text-lg sm:text-xl md:text-2xl font-semibold mb-3">
            SYSTEMS
          </h2>
          <p className="text-sm sm:text-base mb-4 max-w-xs mx-auto sm:mx-0">
            Efficient and reliable electrical system components
          </p>
          <button
            onClick={() => {
              handleNavigation("/part-list", navigate);
              navigateToPartList("part-type");
            }}
            className="text-black bg-yellow-300 py-2 px-4 rounded-md hover:bg-yellow-400 transition duration-300 w-full sm:w-auto"
          >
            Know More
          </button>
        </div>
        <div className="flex-shrink-0">
          <img
            src={img4}
            alt="Electrical Systems"
            className="rounded-md w-full sm:w-40 md:w-48 h-auto object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Card;
