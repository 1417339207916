import React from 'react'
import Welcome from '../components/home/Welcome'
import BottomCarousel from '../components/common/BottomCarousel'
import Blog from '../components/home/Blog'
import Testimonial from '../components/home/Testimonial'
import Services from '../components/home/Services'
import Store from '../components/home/Store'
import Product from '../components/home/product'
import Card from '../components/home/Card'

const Home = () => {
  return (
    <div>
        <Welcome/>
        <Card/>
        <Store/>
        <Product/>
        <Services/>
        <Blog/>
        <Testimonial/>
        <BottomCarousel/>
    </div>
  )
}

export default Home