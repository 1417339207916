import React from "react";
import { motion } from "framer-motion";
import motor from "../../assets/product/blower-motor.png";
import computer from "../../assets/product/computer.png";
import cylinder from "../../assets/product/cylinder-head.png";
import front from "../../assets/product/front-door-glass.png";
import head from "../../assets/product/head.png";
import gap from "../../assets/product/qap-electriccar.webp";
import icon from "../../assets/product/qap-mechanicalcar.webp";
import spin from "../../assets/product/spindle-knuckle-front.png";

const products = [
  { id: 1, name: "Door Assembly - Rear or Back", image: motor },
  { id: 2, name: "Spindle/Knuckle - Front", image: computer },
  { id: 3, name: "Door Glass - Front Doors", image: cylinder },
  { id: 4, name: "Headlamp Assembly", image: front },
  { id: 5, name: "Spindle/Knuckle - Front", image: head },
  { id: 6, name: "Door Glass - Front Doors", image: gap },
  { id: 7, name: "Headlamp Assembly", image: icon },
  { id: 8, name: "Headlamp Assembly", image: spin },
];

const ProductCarousel = () => {
  const cardWidth = 300; // Updated card width
  const cardSpacing = 32; // Space between cards
  const totalWidth = products.length * (cardWidth + cardSpacing);

  return (
    <div className="bg-gray-100 py-10">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-2">PRODUCTS</h2>
        <p className="text-lg text-gray-600 mb-8">MOST POPULAR!</p>

        <div className="w-full py-8">
          <div className="overflow-hidden relative">
            <motion.div
              className="flex items-center space-x-8"
              animate={{
                x: [0, -totalWidth / 2], // Only move enough to show new cards
              }}
              transition={{
                x: {
                  repeat: Infinity,
                  repeatType: "loop",
                  duration: 60, // Adjust speed by changing duration
                  ease: "linear",
                },
              }}
              style={{ width: `${totalWidth}px` }} // Ensure motion div stretches to accommodate cards
            >
              {/* Double the products array for seamless loop */}
              {[...products, ...products].map((product, index) => (
                <div
                  key={index}
                  className="flex-shrink-0 w-72 h-72  bg-white border border-gray-200 rounded-lg flex flex-col items-center justify-between hover:shadow-lg transition-shadow duration-300"
                >
                  {product.image ? (
                    <img
                      src={product.image}
                      alt={product.name}
                      className="w-full h-48 object-cover p-4"
                    />
                  ) : (
                    <div className="w-full h-48 flex items-center justify-center bg-gray-200">
                      <span className="text-gray-500">No Image Found</span>
                    </div>
                  )}
                  <div className="bg-gray-400 w-full flex items-center justify-center p-4 h-16">
                    <h3 className="text-lg font-semibold text-gray-800 text-center">
                      {product.name}
                    </h3>
                  </div>
                </div>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCarousel;
