import React from "react";
import { useNavigate } from "react-router-dom";

const WhyChooseUs = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-gray-100 ">
      {/* Why Choose Us Section */}
      <div className="w-full  p-10 bg-gray-300">
        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">
          Why Choose Us?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="flex items-center">
            <span className="text-blue-500 text-2xl font-bold mr-3">
              &#8226;
            </span>
            <p className="text-gray-700">Easily Find Quality Used Parts</p>
          </div>
          <div className="flex items-center">
            <span className="text-blue-500 text-2xl font-bold mr-3">
              &#8226;
            </span>
            <p className="text-gray-700">
              Great Service Backed By A Great Team
            </p>
          </div>
          <div className="flex items-center">
            <span className="text-blue-500 text-2xl font-bold mr-3">
              &#8226;
            </span>
            <p className="text-gray-700">Parts Stored Inside Our Facility</p>
          </div>
          <div className="flex items-center">
            <span className="text-blue-500 text-2xl font-bold mr-3">
              &#8226;
            </span>
            <p className="text-gray-700">Save Money Buying Used Parts</p>
          </div>
          <div className="flex items-center">
            <span className="text-blue-500 text-2xl font-bold mr-3">
              &#8226;
            </span>
            <p className="text-gray-700">
              Top Highest Green Quality Operations
            </p>
          </div>
          <div className="flex items-center">
            <span className="text-blue-500 text-2xl font-bold mr-3">
              &#8226;
            </span>
            <p className="text-gray-700">Serving all over 15+ Years</p>
          </div>
          <div className="flex items-center">
            <span className="text-blue-500 text-2xl font-bold mr-3">
              &#8226;
            </span>
            <p className="text-gray-700">Great Standard & Extended Warranty</p>
          </div>
        </div>
      </div>

      {/* Find Your Orders Section */}
      <div className="bg-blue-600 py-5">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-center md:justify-between px-5">
          <div className="flex flex-col items-center md:items-start gap-1 text-center md:text-left">
            <p className="text-white md:text-5xl text-3xl font-semibold mb-3 md:mb-0">
              FIND YOUR ORDERS?
            </p>
            <span className="md:text-4xl text-2xl text-white">
              +1-888-711-6990
            </span>
          </div>
          <button
            onClick={() => navigate("/part-request")}
            className="bg-yellow-400 text-black font-bold py-2 px-6 mt-4 md:mt-0 rounded-md hover:bg-yellow-300"
          >
            Find My Part Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
