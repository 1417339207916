import React from "react";
import Banner from "../components/common/Banner";
import banner from "../assets/part.jpg";
import CardsList from "../components/part/CardList";
import BottomCarousel from "../components/common/BottomCarousel";
import { useNavigate } from "react-router-dom";

const Resources = () => {
  const navigate=useNavigate();
  return (
    <div>
      <Banner pageName="RESOURCES AND ARTICLE" backgroundImage={banner} />
      <CardsList />
      {/* Find Your Orders Section */}
      <div className="bg-blue-600 py-5">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-center md:justify-between px-5">
          <div className="flex flex-col items-center md:items-start gap-1 text-center md:text-left">
            <p className="text-white md:text-5xl text-3xl font-semibold mb-3 md:mb-0">
              FIND YOUR ORDERS?
            </p>
            <span className="md:text-4xl text-2xl text-white">
              +1-888-711-6990
            </span>
          </div>
          <button
            onClick={() => navigate("/part-request")}
            className="bg-yellow-400 text-black font-bold py-2 px-6 mt-4 md:mt-0 rounded-md hover:bg-yellow-300"
          >
            Find My Part Now
          </button>
        </div>
      </div>
      <BottomCarousel />
    </div>
  );
};

export default Resources;
