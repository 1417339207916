import React from "react";
import Banner from "../components/common/Banner";
import banner from "../assets/part.jpg";
import BottomCarousel from "../components/common/BottomCarousel";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div>
      {/* Banner Section */}
      <Banner pageName="Privacy Policy" backgroundImage={banner} />

      {/* Privacy Policy Section */}
      <div className="bg-white md:p-10 p-5">
        <div className="max-w-6xl mx-auto px-5">
          <p className="text-gray-700 text-lg text-justify leading-7 mb-4">
            Global Auto Experts certifies that your information is safe and
            secure when making transactions over the Internet.
          </p>
          <p className="text-gray-700 text-lg text-justify leading-7 mb-4">
            Through this Internet Privacy Statement, we want to reassure users
            of the Site that we will not sell, share, or rent user information
            to others in a manner different than as set forth in this Internet
            Privacy Statement.
          </p>
          <p className="text-gray-700 text-lg text-justify leading-7 mb-4">
            We use a credit card processing company to bill purchasers of goods
            from the Site, and we also use outside distributors and delivery
            companies to assist us in fulfilling and delivering orders. We share
            with them only the information necessary to enable such card
            processing and fulfillment and delivery of goods. Our users'
            personal information will not be shared, sold, or rented to any
            organization outside of Global Auto Experts and our financial and
            order fulfillment affiliates.
          </p>
          <p className="text-gray-700 text-lg text-justify leading-7">
            Global Auto Experts occasionally sends our customers and users of
            the Site announcements and updates, containing important information
            which we believe will be of value to them. Users of the Site can
            always update their personally identifying information that they
            have provided to Global Auto Experts by e-mailing us at{" "}
            <a
              href="mailto:info@globalautoexperts.us"
              className="text-blue-500 underline"
            >
              info@globalautoexperts.us
            </a>
            .
          </p>
        </div>
      </div>

      {/* Find Your Orders Section */}
      <div className="bg-blue-600 py-5">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-center md:justify-between px-5">
          <div className="flex flex-col items-center md:items-start gap-1 text-center md:text-left">
            <p className="text-white md:text-5xl text-3xl font-semibold mb-3 md:mb-0">
              FIND YOUR ORDERS?
            </p>
            <span className="md:text-4xl text-2xl text-white">
              +1-888-711-6990
            </span>
          </div>
          <button
            onClick={() => navigate("/part-request")}
            className="bg-yellow-400 text-black font-bold py-2 px-6 mt-4 md:mt-0 rounded-md hover:bg-yellow-300"
          >
            Find My Part Now
          </button>
        </div>
      </div>

      {/* Bottom Carousel Section */}
      <BottomCarousel />
    </div>
  );
};

export default PrivacyPolicy;
