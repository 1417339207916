import React from "react";
import royal2 from "../../assets/product/car care.jpg";
import tips from "../../assets/product/luxury.webp";

const BlogCard = ({ imageUrl, title, readMoreUrl }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <img className="w-full h-64 object-cover" src={imageUrl} alt={title} />
      <div className="p-6">
        <h3 className="text-2xl font-bold mb-2">{title}</h3>
        <a
          href={readMoreUrl}
          className="inline-block bg-yellow-500 hover:bg-yellow-700 text-black font-bold py-2 px-4 rounded mt-4"
        >
          Read More
        </a>
      </div>
    </div>
  );
};

const Blog = () => {
  const blogPosts = [
    {
      imageUrl: royal2,
      title: "Car Care",
      readMoreUrl: "#",
    },
    {
      imageUrl: tips,
      title: "Tips & Tricks",
      readMoreUrl: "#",
    },
  ];

  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold mb-8">Blog</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
          {blogPosts.map((post, index) => (
            <BlogCard
              key={index}
              imageUrl={post.imageUrl}
              title={post.title}
              readMoreUrl={post.readMoreUrl}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
