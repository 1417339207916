import React from "react";
import Banner from "../components/common/Banner";
import banner from "../assets/part.jpg";
import BottomCarousel from "../components/common/BottomCarousel";
import { useNavigate } from "react-router-dom";

const TermsAndCondition = () => {
  const navigate = useNavigate();

  return (
    <div>
      {/* Banner Section */}
      <Banner pageName="Terms and Conditions" backgroundImage={banner} />

      {/* Pennsylvania Tax Section */}
      <div className="bg-white md:p-10 p-5">
        <div className="max-w-6xl mx-auto px-5">
          <h2 className="text-3xl font-bold text-blue-600 mb-6 text-start">
            Pennsylvania Tax
          </h2>
          <p className="text-gray-700 text-lg leading-7 mb-4 text-justify">
            Pennsylvania sales or use tax may be due in connection with the purchase and delivery of tangible personal property to Pennsylvania individuals and businesses.
          </p>
          <p className="text-gray-700 text-lg leading-7 text-justify">
            The purchaser is required to file a use tax return if tax is due in connection with the purchase and delivery in the Commonwealth. This notice is required pursuant to the provisions of the Tax Reform Code of 1971. 72 P.S. § 7213.2.
          </p>
        </div>
      </div>

      {/* Find Your Orders Section */}
      <div className="bg-blue-600 py-5">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-center md:justify-between px-5">
          <div className="flex flex-col items-center md:items-start gap-1 text-center md:text-left">
            <p className="text-white md:text-5xl text-3xl font-semibold mb-3 md:mb-0">
              FIND YOUR ORDERS?
            </p>
            <span className="md:text-4xl text-2xl text-white">
              +1-888-711-6990
            </span>
          </div>
          <button
            onClick={() => navigate("/part-request")}
            className="bg-yellow-400 text-black font-bold py-2 px-6 mt-4 md:mt-0 rounded-md hover:bg-yellow-300"
          >
            Find My Part Now
          </button>
        </div>
      </div>

      {/* Bottom Carousel Section */}
      <BottomCarousel />
    </div>
  );
};

export default TermsAndCondition;
