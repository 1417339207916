import React from 'react'
import Banner from '../components/common/Banner'
import banner from '../assets/aboutBanner.jpg';
import AboutSection from '../components/about/AboutSection';
import WhyChooseUs from '../components/common/WhyChooseUs';
import BottomCarousel from '../components/common/BottomCarousel';

const About = () => {
  return (
    <div>
        <Banner pageName="Why Buy From Us ?" backgroundImage={banner}/>
        <AboutSection/>
        <WhyChooseUs/>
        <BottomCarousel/>
    </div>
  )
}

export default About