import React from "react";
import car from "../../assets/car.png";
import email from "../../assets/email.png";
import ring from "../../assets/ringing.png";
import { useNavigate } from "react-router-dom";
import { Facebook, Instagram, Linkedin, Twitter } from "lucide-react";
import { useNavigation } from "../context/NavigationContext";

const Footer = () => {
  const navigate = useNavigate();
  const { handleNavigation } = useNavigation();

  const navigateToPartList = (tab) => {
    // Navigate to Part List page and pass the tab as state
    navigate("/part-list", { state: { activeTab: tab } });
  };

  return (
    <footer className="bg-gray-900 text-gray-200">
      {/* Top Section with Gradient */}
      <div className="flex flex-col xl:flex-row justify-center xl:gap-40 lg:gap-32 items-center px-20 py-32 bg-gradient-to-r from-blue-600 to-purple-600 text-white">
        {/* Left: Car Image */}
        <div className="flex-shrink-0 md:block hidden">
          <img src={car} alt="Car" className="h-full xl:h-80 w-full" />
        </div>

        {/* Right: Contact Information */}
        <div className="text-left md:mt-0">
          <h2 className="text-3xl font-bold">Global Auto Experts</h2>
          <p className="mt-2 md:text-2xl text-xl">
            30 S 15th St Ste 1550 Num 173221, Philadelphia, PA 19102
          </p>

          <div className="mt-4 space-y-4">
            <p className="flex items-center gap-4">
              <img
                src={email}
                alt="Email"
                className="md:w-10 md:h-10 h-6 w-6"
              />
              <span className="md:text-2xl text-lg">
                info@globalautoexperts.us
              </span>
            </p>
            <p className="flex items-center gap-4">
              <img src={ring} alt="Phone" className="md:w-10 md:h-10 h-6 w-6" />
              <span className="md:text-2xl text-lg">+1-888-711-6990</span>
            </p>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 px-6 py-16 text-center">
        {/* Part Request */}
        <div>
          <h3 className="text-xl font-bold mb-4">Part Request</h3>
          <ul className="space-y-2">
            <li>
              <div
                onClick={() => handleNavigation("/part-request", navigate)}
                className="hover:underline cursor-pointer"
              >
                Find A Part
              </div>
            </li>
            <li>
              <div
                onClick={() => navigateToPartList("car-make")}
                className="hover:underline cursor-pointer"
              >
                Search By Car Make
              </div>
            </li>
            <li>
              <div
                onClick={() => navigateToPartList("part-type")}
                className="hover:underline cursor-pointer"
              >
                Search By Part Type
              </div>
            </li>
          </ul>
        </div>

        {/* Customer Service */}
        <div>
          <h3 className="text-xl font-bold mb-4">Customer Service</h3>
          <ul className="space-y-2">
            <li>
              <div
                onClick={() => handleNavigation("/customer-service", navigate)}
                className="hover:underline cursor-pointer"
              >
                Submit A Ticket
              </div>
            </li>
            <li>
              <div
                onClick={() => handleNavigation("/warranty-policy", navigate)}
                className="hover:underline cursor-pointer"
              >
                Warranty Policy
              </div>
            </li>
            <li>
              <div
                onClick={() => handleNavigation("/contact-us", navigate)}
                className="hover:underline cursor-pointer"
              >
                Contact Us
              </div>
            </li>
          </ul>
        </div>

        {/* Resources */}
        <div>
          <h3 className="text-xl font-bold mb-4">Resources</h3>
          <ul className="space-y-2">
            <li>
              <div
                onClick={() => handleNavigation("/resources", navigate)}
                className="hover:underline cursor-pointer"
              >
                Articles
              </div>
            </li>
            <li>
              <div className="hover:underline cursor-pointer">Sitemap</div>
            </li>
            <li>
              <div className="hover:underline cursor-pointer">
                Important Links
              </div>
            </li>
          </ul>
        </div>

        {/* About Us */}
        <div>
          <h3 className="text-xl font-bold mb-4">About Us</h3>
          <ul className="space-y-2">
            <li>
              <div
                onClick={() => handleNavigation("/about", navigate)}
                className="hover:underline cursor-pointer"
              >
                Why GAE?
              </div>
            </li>
            <li>
              <div
                onClick={() => handleNavigation("/privacy-policy", navigate)}
                className="hover:underline cursor-pointer"
              >
                Privacy Policy
              </div>
            </li>
            <li>
              <div
                onClick={() =>
                  handleNavigation("/terms-and-conditions", navigate)
                }
                className="hover:underline cursor-pointer"
              >
                Terms and Conditions
              </div>
            </li>
          </ul>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="bg-gray-800 flex md:flex-row flex-col items-center justify-between px-6 py-4 text-center">
        <div className="flex space-x-4 mb-4">
          <a
            href="https://www.facebook.com/share/at467HMjVrbcF1Qv/?mibextid=LQQJ4d"
            className="text-gray-400 hover:text-white"
          >
            <Facebook size={24} />
          </a>
          <a
            href="https://www.instagram.com/globalautoexperts/profilecard/?igsh=MW1kd2ZnbHY5ZjhpaA=="
            className="text-gray-400 hover:text-white"
          >
            <Instagram size={24} />
          </a>
          <a
            href="https://www.linkedin.com/company/globalautoexperts/"
            className="text-gray-400 hover:text-white"
          >
            <Linkedin size={24} />
          </a>
          <a
            href="https://x.com/globalautoexpts?s=11"
            className="text-gray-400 hover:text-white"
          >
            <Twitter size={24} />
          </a>
        </div>
        <p>© 2024 globalautoexperts.us. All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
