import React, { useRef, useState } from "react";
import banner from "../../assets/car.jpg";
import emailjs from "emailjs-com";

const serviceID = "service_biuhj1d";
const templateID = "template_w8xezqg";
const userID = "hZnpuMKHDvdT9aUbn";

const Welcome = () => {
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    zip: "",
    make: "",
    model: "",
    year: "",
    part: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("formData", formData);

    // const {name,email,phone,zip,make,model,year}=formData;
    emailjs.sendForm(serviceID, templateID, formRef.current, userID).then(
      (result) => {
        console.log(result.text);
        alert("Your message has been sent successfully!");
        // Reset form after successful submission
        setFormData({
          name: "",
          email: "",
          phone: "",
          zip: "",
          make: "",
          model: "",
          year: "",
          part: "",
        });
      },
      (error) => {
        console.error(error.text);
        alert("Failed to send message. Please try again later.");
      }
    );
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header Section */}
      <div
        className="relative bg-cover bg-center min-h-screen flex items-center justify-center"
        style={{ backgroundImage: `url(${banner})` }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* Content */}
        <div className="relative z-10 container mx-auto px-4 py-16">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
            {/* Left Column - Text Content */}
            <div className="text-center lg:text-left text-white">
              <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl font-bold mb-4">
                Best Online Marketplace <br /> Buy Quality Used Auto Parts
              </h1>
              <p className="text-base sm:text-lg mb-8">
                Choose High-Quality Recycled OEM Parts
              </p>
            </div>

            {/* Right Column - Form and Call Section */}
            <div className="w-full space-y-6">
              {/* Form Section */}
              <div className="bg-white shadow-lg rounded-lg">
                <div className="bg-[#2563eb] p-4 sm:p-6 rounded-t-lg">
                  <h2 className="text-2xl sm:text-4xl text-white font-semibold text-center">
                    Find A Part Now
                  </h2>
                </div>
                <form
                  className="p-4 sm:p-6"
                  ref={formRef}
                  onSubmit={handleSubmit}
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex flex-col">
                      <label className="text-black mb-2">
                        Part <span className="text-red-500">*</span>
                      </label>
                      <select
                        name="part"
                        value={formData?.part}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 bg-white text-black"
                      >
                        <option>Select Part</option>
                        <option value="A Pillar">A Pillar</option>
                        <option value="A Pillar Trim">A Pillar Trim</option>
                        <option value="A/C Bracket">A/C Bracket</option>
                        <option value="A/C Compressor">A/C Compressor</option>
                        <option value="A/C Compressor Clutch Only">
                          A/C Compressor Clutch Only
                        </option>
                        <option value="A/C Condenser">A/C Condenser</option>
                        <option value="A/C Condenser Fan">
                          A/C Condenser Fan
                        </option>
                        <option value="A/C Control Computer">
                          A/C Control Computer
                        </option>
                        <option value="A/C Evaporator">A/C Evaporator</option>
                        <option value="A/C Evaporator Housing only">
                          A/C Evaporator Housing only
                        </option>
                        <option value="A/C Heater Control (see also Radio or TV Screen)">
                          A/C Heater Control (see also Radio or TV Screen)
                        </option>
                        <option value="A/C Hose">A/C Hose</option>
                        <option value="A/C Wiring Harness">
                          A/C Wiring Harness
                        </option>
                        <option value="Accelerator Cable">
                          Accelerator Cable
                        </option>
                        <option value="Accelerator Parts">
                          Accelerator Parts
                        </option>
                        <option value="Adaptive Cruise Projector">
                          Adaptive Cruise Projector
                        </option>
                        <option value="Air Bag">Air Bag</option>
                        <option value="Air Bag Clockspring">
                          Air Bag Clockspring
                        </option>
                        <option value="Air Bag Ctrl Module">
                          Air Bag Ctrl Module
                        </option>
                        <option value="Air Box/Air Cleaner">
                          Air Box/Air Cleaner
                        </option>
                        <option value="Air Cond./Heater Vents">
                          Air Cond./Heater Vents
                        </option>
                        <option value="Air Flow Meter">Air Flow Meter</option>
                        <option value="Air Pump">Air Pump</option>
                        <option value="Air Ride Compressor">
                          Air Ride Compressor
                        </option>
                        <option value="Air Shutter">Air Shutter</option>
                        <option value="Air Tube/Resonator">
                          Air Tube/Resonator
                        </option>
                        <option value="Alternator">Alternator</option>
                        <option value="Alternator Bracket">
                          Alternator Bracket
                        </option>
                        <option value="Amplifier/Radio">Amplifier/Radio</option>
                        <option value="Antenna">Antenna</option>
                        <option value="Anti-Lock Brake Computer">
                          Anti-Lock Brake Computer
                        </option>
                        <option value="Anti-Lock Brake Pump">
                          Anti-Lock Brake Pump
                        </option>
                        <option value="Armrest">Armrest</option>
                        <option value="Ash Tray/Lighter">
                          Ash Tray/Lighter
                        </option>
                        <option value="Audiovisual (A/V) (see also TV Screen)">
                          Audiovisual (A/V) (see also TV Screen)
                        </option>
                        <option value="Automatic Headlight Dimmer">
                          Automatic Headlight Dimmer
                        </option>
                        <option value="Auto. Trans. Cooler">
                          Auto. Trans. Cooler
                        </option>
                        <option value="Axle Actuator (4WD)">
                          Axle Actuator (4WD)
                        </option>
                        <option value="Axle Assy Fr (4WD w. Housing)">
                          Axle Assy Fr (4WD w. Housing)
                        </option>
                        <option value="Axle Assy Rear (w. Housing)">
                          Axle Assy Rear (w. Housing)
                        </option>
                        <option value="Axle Beam Front (2WD, incl I Beam Susp)">
                          Axle Beam Front (2WD, incl I Beam Susp)
                        </option>
                        <option value="Axle Beam Rear (FWD)">
                          Axle Beam Rear (FWD)
                        </option>
                        <option value="Axle Flange">Axle Flange</option>
                        <option value="Axle Housing Only">
                          Axle Housing Only
                        </option>
                        <option value="Axle Shaft">Axle Shaft</option>
                        <option value="B Pillar Trim">B Pillar Trim</option>
                        <option value="Back Door (above rear bumper)">
                          Back Door (above rear bumper)
                        </option>
                        <option value="Back Door Glass">Back Door Glass</option>
                        <option value="Back Door Handle, Inside">
                          Back Door Handle, Inside
                        </option>
                        <option value="Back Door Handle, Outside">
                          Back Door Handle, Outside
                        </option>
                        <option value="Back Door Hinge">Back Door Hinge</option>
                        <option value="Back Door Moulding">
                          Back Door Moulding
                        </option>
                        <option value="Back Door Shell">Back Door Shell</option>
                        <option value="Back Door Trim Panel">
                          Back Door Trim Panel
                        </option>
                        <option value="Back Glass">Back Glass</option>
                        <option value="Back Glass Regulator">
                          Back Glass Regulator
                        </option>
                        <option value="Back Glass Shock">
                          Back Glass Shock
                        </option>
                        <option value="Backing Plate, Front">
                          Backing Plate, Front
                        </option>
                        <option value="Backing Plate, Rear">
                          Backing Plate, Rear
                        </option>
                        <option value="Backup Camera">Backup Camera</option>
                        <option value="Backup Light">Backup Light</option>
                        <option value="Battery">Battery</option>
                        <option value="Battery Cable">Battery Cable</option>
                        <option value="Battery Terminal">
                          Battery Terminal
                        </option>
                        <option value="Battery Tray">Battery Tray</option>
                        <option value="Bed, Pickup">Bed, Pickup</option>
                        <option value="Bed Floor (Pickup)">
                          Bed Floor (Pickup)
                        </option>
                        <option value="Bed Front Panel (Pickup)">
                          Bed Front Panel (Pickup)
                        </option>
                        <option value="Bed Liner">Bed Liner</option>
                        <option value="Bed Side, Pickup">
                          Bed Side, Pickup
                        </option>
                        <option value="Bell Housing">Bell Housing</option>
                        <option value="Belt Tensioner">Belt Tensioner</option>
                        <option value="Blind Spot Camera">
                          Blind Spot Camera
                        </option>
                        <option value="Blower Motor">Blower Motor</option>
                        <option value="Body Wiring Harness">
                          Body Wiring Harness
                        </option>
                        <option value="Brake/Clutch Pedal">
                          Brake/Clutch Pedal
                        </option>
                        <option value="Brake Booster">Brake Booster</option>
                        <option value="Brake Proportioning Valve">
                          Brake Proportioning Valve
                        </option>
                        <option value="Brake Rotor/Drum, Front">
                          Brake Rotor/Drum, Front
                        </option>
                        <option value="Brake Rotor/Drum, Rear">
                          Brake Rotor/Drum, Rear
                        </option>
                        <option value="Brake Shoes/Pads">
                          Brake Shoes/Pads
                        </option>
                        <option value="Brush Guard">Brush Guard</option>
                        <option value="Bug Screen">Bug Screen</option>
                        <option value="Bumper Assy (Front) includes cover">
                          Bumper Assy (Front) includes cover
                        </option>
                        <option value="Bumper Assy (Rear) includes cover">
                          Bumper Assy (Rear) includes cover
                        </option>
                        <option value="Bumper Bracket (Misc)">
                          Bumper Bracket (Misc)
                        </option>
                        <option value="Bumper Cover (Front)">
                          Bumper Cover (Front)
                        </option>
                        <option value="Bumper Cover (Rear)">
                          Bumper Cover (Rear)
                        </option>
                        <option value="Bumper End Cap">Bumper End Cap</option>
                        <option value="Bumper Energy Absorber (Front)">
                          Bumper Energy Absorber (Front)
                        </option>
                        <option value="Bumper Energy Absorber (Rear)">
                          Bumper Energy Absorber (Rear)
                        </option>
                        <option value="Bumper Face Bar (Front)">
                          Bumper Face Bar (Front)
                        </option>
                        <option value="Bumper Face Bar (Rear)">
                          Bumper Face Bar (Rear)
                        </option>
                        <option value="Bumper Filler Panel">
                          Bumper Filler Panel
                        </option>
                        <option value="Bumper Guard (Front)">
                          Bumper Guard (Front)
                        </option>
                        <option value="Bumper Guard (Rear)">
                          Bumper Guard (Rear)
                        </option>
                        <option value="Bumper Impact Strip">
                          Bumper Impact Strip
                        </option>
                        <option value="Bumper Reinforcement (Front)">
                          Bumper Reinforcement (Front)
                        </option>
                        <option value="Bumper Reinforcement (Rear)">
                          Bumper Reinforcement (Rear)
                        </option>
                        <option value="Bumper Shock">Bumper Shock</option>
                        <option value="Bumper Step Pad">Bumper Step Pad</option>
                        <option value="C Pillar Trim">C Pillar Trim</option>
                        <option value="Cab">Cab</option>
                        <option value="Cab Back Panel">Cab Back Panel</option>
                        <option value="Cab Clip, no cowl">
                          Cab Clip, no cowl
                        </option>
                        <option value="Cab Corner">Cab Corner</option>
                        <option value="Cabin Air Filter">
                          Cabin Air Filter
                        </option>
                        <option value="Cabin Fuse Box">Cabin Fuse Box</option>
                        <option value="Caliper">Caliper</option>
                        <option value="Camera Projector">
                          Camera Projector
                        </option>
                        <option value="Camshaft">Camshaft</option>
                        <option value="Camshaft Housing">
                          Camshaft Housing
                        </option>
                        <option value="Carburetor (see also Throttle Body)">
                          Carburetor (see also Throttle Body)
                        </option>
                        <option value="Cargo Cover/Shade">
                          Cargo Cover/Shade
                        </option>
                        <option value="Cargo Lamp">Cargo Lamp</option>
                        <option value="Carpet">Carpet</option>
                        <option value="Carrier (see also Differential)">
                          Carrier (see also Differential)
                        </option>
                        <option value="Carrier Case">Carrier Case</option>
                        <option value="CD Player/Radio">CD Player/Radio</option>
                        <option value="Center Cap (Wheel)">
                          Center Cap (Wheel)
                        </option>
                        <option value="Center Pillar">Center Pillar</option>
                        <option value="Charging Port Door Assembly">
                          Charging Port Door Assembly
                        </option>
                        <option value="Chassis Control Computer (not Engine)">
                          Chassis Control Computer (not Engine)
                        </option>
                        <option value="Clock">Clock</option>
                        <option value="Clockspring (Air Bag)">
                          Clockspring (Air Bag)
                        </option>
                        <option value="Clutch Cable">Clutch Cable</option>
                        <option value="Clutch Disc">Clutch Disc</option>
                        <option value="Clutch Fork">Clutch Fork</option>
                        <option value="Clutch Master Cylinder">
                          Clutch Master Cylinder
                        </option>
                        <option value="Coil/Air Spring">Coil/Air Spring</option>
                        <option value="Coil/Igniter">Coil/Igniter</option>
                        <option value="Column Switch">Column Switch</option>
                        <option value="Computer Box Engine">
                          Computer Box Engine
                        </option>
                        <option value="Computer Box Not Engine">
                          Computer Box Not Engine
                        </option>
                        <option value="Condenser">Condenser</option>
                        <option value="Condenser/Radiator mtd. Cooling Fan">
                          Condenser/Radiator mtd. Cooling Fan
                        </option>
                        <option value="Connecting Rod, Engine">
                          Connecting Rod, Engine
                        </option>
                        <option value="Console, Front">Console, Front</option>
                        <option value="Console, Rear">Console, Rear</option>
                        <option value="Control Arm, Front Lower">
                          Control Arm, Front Lower
                        </option>
                        <option value="Control Arm, Front Upper">
                          Control Arm, Front Upper
                        </option>
                        <option value="Control Arm, Rear Lower">
                          Control Arm, Rear Lower
                        </option>
                        <option value="Control Arm, Rear Upper">
                          Control Arm, Rear Upper
                        </option>
                        <option value="Convertible Top">Convertible Top</option>
                        <option value="Convertible Top Boot">
                          Convertible Top Boot
                        </option>
                        <option value="Convertible Top Lift">
                          Convertible Top Lift
                        </option>
                        <option value="Convertible Top Motor">
                          Convertible Top Motor
                        </option>
                        <option value="Convertible Windscreen">
                          Convertible Windscreen
                        </option>
                        <option value="Coolant Pump">Coolant Pump</option>
                        <option value="Cooling Fan (Rad and Con mtd.)">
                          Cooling Fan (Rad and Con mtd.)
                        </option>
                        <option value="Core (Radiator) Support">
                          Core (Radiator) Support
                        </option>
                        <option value="Cowl">Cowl</option>
                        <option value="Cowl Vent Panel">Cowl Vent Panel</option>
                        <option value="Crank Pulley (Harmonic Balancer)">
                          Crank Pulley (Harmonic Balancer)
                        </option>
                        <option value="Crankshaft">Crankshaft</option>
                        <option value="Cruise Control Computer">
                          Cruise Control Computer
                        </option>
                        <option value="Cruise Control Servo/Regulator">
                          Cruise Control Servo/Regulator
                        </option>
                        <option value="Cruise Speed Controler">
                          Cruise Speed Controler
                        </option>
                        <option value="Cylinder Head (Engine)">
                          Cylinder Head (Engine)
                        </option>
                        <option value="D Pillar">D Pillar</option>
                        <option value="Dash/Interior/Seat Switch">
                          Dash/Interior/Seat Switch
                        </option>
                        <option value="Dash Bezel (see also Instrument or Radio Bezel)">
                          Dash Bezel (see also Instrument or Radio Bezel)
                        </option>
                        <option value="Dash Pad">Dash Pad</option>
                        <option value="Dash Panel">Dash Panel</option>
                        <option value="Dash Wiring Harness">
                          Dash Wiring Harness
                        </option>
                        <option value="Deck Lid Assembly">
                          Deck Lid Assembly
                        </option>
                        <option value="Deck Lid/Trunk Lid Shell">
                          Deck Lid/Trunk Lid Shell
                        </option>
                        <option value="Diesel Particulate Filter">
                          Diesel Particulate Filter
                        </option>
                        <option value="Differential Assembly (see also Carrier)">
                          Differential Assembly (see also Carrier)
                        </option>
                        <option value="Differential Case Only">
                          Differential Case Only
                        </option>
                        <option value="Differential Flange Only">
                          Differential Flange Only
                        </option>
                        <option value="Distributor">Distributor</option>
                        <option value="Door Back (door above rear bumper)">
                          Door Back (door above rear bumper)
                        </option>
                        <option value="Door Front">Door Front</option>
                        <option value="Door Handle, Inside">
                          Door Handle, Inside
                        </option>
                        <option value="Door Handle, Outside">
                          Door Handle, Outside
                        </option>
                        <option value="Door Lock Striker">
                          Door Lock Striker
                        </option>
                        <option value="Door Mirror Cover">
                          Door Mirror Cover
                        </option>
                        <option value="Door Outer Repair Panel, Back">
                          Door Outer Repair Panel, Back
                        </option>
                        <option value="Door Outer Repair Panel, Front">
                          Door Outer Repair Panel, Front
                        </option>
                        <option value="Door Outer Repair Panel, Rear">
                          Door Outer Repair Panel, Rear
                        </option>
                        <option value="Door Rear (side of vehicle)">
                          Door Rear (side of vehicle)
                        </option>
                        <option value="Door Shell, Back">
                          Door Shell, Back
                        </option>
                        <option value="Door Shell, Front">
                          Door Shell, Front
                        </option>
                        <option value="Door Shell, Rear">
                          Door Shell, Rear
                        </option>
                        <option value="Door Window Crank Handle">
                          Door Window Crank Handle
                        </option>
                        <option value="Drag Link">Drag Link</option>
                        <option value="Drive-By-Wire">Drive-By-Wire</option>
                        <option value="Drive Shaft, Front">
                          Drive Shaft, Front
                        </option>
                        <option value="Drive Shaft, Rear">
                          Drive Shaft, Rear
                        </option>
                        <option value="Driving Lamp Bezel">
                          Driving Lamp Bezel
                        </option>
                        <option value="EGR Valve">EGR Valve</option>
                        <option value="Electric Door Motor (not Window)">
                          Electric Door Motor (not Window)
                        </option>
                        <option value="Electric Window Motor">
                          Electric Window Motor
                        </option>
                        <option value="Electrical Part Misc">
                          Electrical Part Misc
                        </option>
                        <option value="Electronic Transmission Shifter">
                          Electronic Transmission Shifter
                        </option>
                        <option value="Emblem">Emblem</option>
                        <option value="Emergency Brake">Emergency Brake</option>
                        <option value="Engine">Engine</option>
                        <option value="Engine Block">Engine Block</option>
                        <option value="Engine Computer">Engine Computer</option>
                        <option value="Engine Core">Engine Core</option>
                        <option value="Engine Cover">Engine Cover</option>
                        <option value="Engine Cradle">Engine Cradle</option>
                        <option value="Engine Cylinder Head">
                          Engine Cylinder Head
                        </option>
                        <option value="Engine Fuse Box">Engine Fuse Box</option>
                        <option value="Engine Mounts">Engine Mounts</option>
                        <option value="Engine Oil Pan">Engine Oil Pan</option>
                        <option value="Engine Wiring Harness">
                          Engine Wiring Harness
                        </option>
                        <option value="Exhaust Assembly">
                          Exhaust Assembly
                        </option>
                        <option value="Exhaust Cross Pipe">
                          Exhaust Cross Pipe
                        </option>
                        <option value="Exhaust Fluid Pump">
                          Exhaust Fluid Pump
                        </option>
                        <option value="Exhaust Fluid Tank">
                          Exhaust Fluid Tank
                        </option>
                        <option value="Exhaust Heat Shield">
                          Exhaust Heat Shield
                        </option>
                        <option value="Exhaust Lead Pipe">
                          Exhaust Lead Pipe
                        </option>
                        <option value="Exhaust Manifold">
                          Exhaust Manifold
                        </option>
                        <option value="Exhaust Muffler">Exhaust Muffler</option>
                        <option value="Exhaust Pipe">Exhaust Pipe</option>
                        <option value="Exhaust Resonator">
                          Exhaust Resonator
                        </option>
                        <option value="Exhaust Tail Pipe">
                          Exhaust Tail Pipe
                        </option>
                        <option value="Fan Blade">Fan Blade</option>
                        <option value="Fan Clutch">Fan Clutch</option>
                        <option value="Fender">Fender</option>
                        <option value="Fender Extension/Flare">
                          Fender Extension/Flare
                        </option>
                        <option value="Fender Inner Panel">
                          Fender Inner Panel
                        </option>
                        <option value="Fender Moulding">Fender Moulding</option>
                        <option value="Fender Skirt">Fender Skirt</option>
                        <option value="Fender/Wheelhouse Inner Liner">
                          Fender/Wheelhouse Inner Liner
                        </option>
                        <option value="Flex Plate">Flex Plate</option>
                        <option value="Floor Mats">Floor Mats</option>
                        <option value="Floor Pan">Floor Pan</option>
                        <option value="Floor Shift Assembly">
                          Floor Shift Assembly
                        </option>
                        <option value="Flywheel">Flywheel</option>
                        <option value="Fog Lamp">Fog Lamp</option>
                        <option value="Fog Lamp Bezel">Fog Lamp Bezel</option>
                        <option value="Fog Lamp Bracket">
                          Fog Lamp Bracket
                        </option>
                        <option value="Fog Lamp Rear">Fog Lamp Rear</option>
                        <option value="Fog Lamp Switch">Fog Lamp Switch</option>
                        <option value="Frame">Frame</option>
                        <option value="Frame Front Section Only">
                          Frame Front Section Only
                        </option>
                        <option value="Frame Horn">Frame Horn</option>
                        <option value="Frame Upper &amp; Lower Rails">
                          Frame Upper &amp; Lower Rails
                        </option>
                        <option value="Front Axle Assembly (4WD w Housing)">
                          Front Axle Assembly (4WD w Housing)
                        </option>
                        <option value="Front Axle Beam (2WD, incl I Beam Susp)">
                          Front Axle Beam (2WD, incl I Beam Susp)
                        </option>
                        <option value="Front Axle Shaft">
                          Front Axle Shaft
                        </option>
                        <option value="Front Bumper Assembly (includes cover)">
                          Front Bumper Assembly (includes cover)
                        </option>
                        <option value="Front Bumper Cover">
                          Front Bumper Cover
                        </option>
                        <option value="Front Bumper Face Bar">
                          Front Bumper Face Bar
                        </option>
                        <option value="Front Bumper Guard">
                          Front Bumper Guard
                        </option>
                        <option value="Front Bumper Reinforcement">
                          Front Bumper Reinforcement
                        </option>
                        <option value="Front Console">Front Console</option>
                        <option value="Front Door">Front Door</option>
                        <option value="Front Door Glass">
                          Front Door Glass
                        </option>
                        <option value="Front Door Handle, Inside">
                          Front Door Handle, Inside
                        </option>
                        <option value="Front Door Handle, Outside">
                          Front Door Handle, Outside
                        </option>
                        <option value="Front Door Hinge">
                          Front Door Hinge
                        </option>
                        <option value="Front Door Mirror">
                          Front Door Mirror
                        </option>
                        <option value="Front Door Mirror Glass">
                          Front Door Mirror Glass
                        </option>
                        <option value="Front Door Moulding">
                          Front Door Moulding
                        </option>
                        <option value="Front Door Regulator">
                          Front Door Regulator
                        </option>
                        <option value="Front Door Shell">
                          Front Door Shell
                        </option>
                        <option value="Front Door Switch">
                          Front Door Switch
                        </option>
                        <option value="Front Door Trim Panel">
                          Front Door Trim Panel
                        </option>
                        <option value="Front Door Vent Glass">
                          Front Door Vent Glass
                        </option>
                        <option value="Front Door Vent Glass Regulator">
                          Front Door Vent Glass Regulator
                        </option>
                        <option value="Front Door Window Motor">
                          Front Door Window Motor
                        </option>
                        <option value="Front Drive Shaft">
                          Front Drive Shaft
                        </option>
                        <option value="Front End Assembly (Nose)">
                          Front End Assembly (Nose)
                        </option>
                        <option value="Front Seat Belt Assembly">
                          Front Seat Belt Assembly
                        </option>
                        <option value="Front Valance">Front Valance</option>
                        <option value="Front Window Regulator">
                          Front Window Regulator
                        </option>
                        <option value="Fuel Cap">Fuel Cap</option>
                        <option value="Fuel Cell">Fuel Cell</option>
                        <option value="Fuel Cooler">Fuel Cooler</option>
                        <option value="Fuel Distributor (&amp; Misc. Injection)">
                          Fuel Distributor (&amp; Misc. Injection)
                        </option>
                        <option value="Fuel Filler Door">
                          Fuel Filler Door
                        </option>
                        <option value="Fuel Filler Neck">
                          Fuel Filler Neck
                        </option>
                        <option value="Fuel Gauge">Fuel Gauge</option>
                        <option value="Fuel Injector (&amp; Misc. Injection)">
                          Fuel Injector (&amp; Misc. Injection)
                        </option>
                        <option value="Fuel Injector Pump">
                          Fuel Injector Pump
                        </option>
                        <option value="Fuel Line">Fuel Line</option>
                        <option value="Fuel Pump">Fuel Pump</option>
                        <option value="Fuel Rail (&amp; Misc. Injection)">
                          Fuel Rail (&amp; Misc. Injection)
                        </option>
                        <option value="Fuel Tank">Fuel Tank</option>
                        <option value="Fuel Tank Sending Unit">
                          Fuel Tank Sending Unit
                        </option>
                        <option value="Fuse Box (Cabin)">
                          Fuse Box (Cabin)
                        </option>
                        <option value="Fuse Box (Engine)">
                          Fuse Box (Engine)
                        </option>
                        <option value="Gas Cap">Gas Cap</option>
                        <option value="Gas Tank">Gas Tank</option>
                        <option value="Gate Interior Trim Panel">
                          Gate Interior Trim Panel
                        </option>
                        <option value="Gate Window Regulator">
                          Gate Window Regulator
                        </option>
                        <option value="Gate/Lid">Gate/Lid</option>
                        <option value="Gauge (Misc)">Gauge (Misc)</option>
                        <option value="Generator">Generator</option>
                        <option value="Glass, Back">Glass, Back</option>
                        <option value="Glass, Front Door">
                          Glass, Front Door
                        </option>
                        <option value="Glass, Front Vent">
                          Glass, Front Vent
                        </option>
                        <option value="Glass, Quarter Window">
                          Glass, Quarter Window
                        </option>
                        <option value="Glass, Rear Door">
                          Glass, Rear Door
                        </option>
                        <option value="Glass, Rear Vent">
                          Glass, Rear Vent
                        </option>
                        <option value="Glass, Special (see also Sunroof/TTop)">
                          Glass, Special (see also Sunroof/TTop)
                        </option>
                        <option value="Glass, Windshield">
                          Glass, Windshield
                        </option>
                        <option value="Glove Box">Glove Box</option>
                        <option value="GPS Screen (see also Radio or Heater/AC Control)">
                          GPS Screen (see also Radio or Heater/AC Control)
                        </option>
                        <option value="Grille">Grille</option>
                        <option value="Grille Moulding">Grille Moulding</option>
                        <option value="Grille Surround">Grille Surround</option>
                        <option value="Harmonic Balancer (Crank Pulley)">
                          Harmonic Balancer (Crank Pulley)
                        </option>
                        <option value="Hatch/Trunk Lid">Hatch/Trunk Lid</option>
                        <option value="Head (Cylinder)">Head (Cylinder)</option>
                        <option value="Header Panel">Header Panel</option>
                        <option value="Headlight Assembly">
                          Headlight Assembly
                        </option>
                        <option value="Headlight Ballast">
                          Headlight Ballast
                        </option>
                        <option value="Headlight Bracket">
                          Headlight Bracket
                        </option>
                        <option value="Headlight Bucket">
                          Headlight Bucket
                        </option>
                        <option value="Headlight Bulb">Headlight Bulb</option>
                        <option value="Headlight Cover (Plastic)">
                          Headlight Cover (Plastic)
                        </option>
                        <option value="Headlight Door">Headlight Door</option>
                        <option value="Headlight Housing">
                          Headlight Housing
                        </option>
                        <option value="Headlight Igniter">
                          Headlight Igniter
                        </option>
                        <option value="Headlight Lens">Headlight Lens</option>
                        <option value="Headlight Motor">Headlight Motor</option>
                        <option value="Headlight Switch (Column Mounted)">
                          Headlight Switch (Column Mounted)
                        </option>
                        <option value="Headlight Switch (Dash Mounted)">
                          Headlight Switch (Dash Mounted)
                        </option>
                        <option value="Headlight Switch (see also Column Switch)">
                          Headlight Switch (see also Column Switch)
                        </option>
                        <option value="Headlight Washer Motor Only">
                          Headlight Washer Motor Only
                        </option>
                        <option value="Headlight Wiper Motor Only">
                          Headlight Wiper Motor Only
                        </option>
                        <option value="Headliner">Headliner</option>
                        <option value="Headrest">Headrest</option>
                        <option value="Heads Up Display">
                          Heads Up Display
                        </option>
                        <option value="Heat Pump Reversing Valve">
                          Heat Pump Reversing Valve
                        </option>
                        <option value="Heater Assy">Heater Assy</option>
                        <option value="Heater Core">Heater Core</option>
                        <option value="Heater Motor">Heater Motor</option>
                        <option value="Heater/AC Control (see also Radio or TV Screen)">
                          Heater/AC Control (see also Radio or TV Screen)
                        </option>
                        <option value="Hood">Hood</option>
                        <option value="Hood Deflector">Hood Deflector</option>
                        <option value="Hood Hinge">Hood Hinge</option>
                        <option value="Hood Insulation Pad">
                          Hood Insulation Pad
                        </option>
                        <option value="Hood Latch">Hood Latch</option>
                        <option value="Hood Ornament">Hood Ornament</option>
                        <option value="Hood Prop">Hood Prop</option>
                        <option value="Hood Scoop">Hood Scoop</option>
                        <option value="Hood Shock">Hood Shock</option>
                        <option value="Horn">Horn</option>
                        <option value="Hub">Hub</option>
                        <option value="Hub Cap/Wheel Cover (display w image)">
                          Hub Cap/Wheel Cover (display w image)
                        </option>
                        <option value="Hub Cap/Wheel Cover (display w/o image)">
                          Hub Cap/Wheel Cover (display w/o image)
                        </option>
                        <option value="Hub, Lockout (4WD)">
                          Hub, Lockout (4WD)
                        </option>
                        <option value="HVAC Actuator">HVAC Actuator</option>
                        <option value="Hybrid Converter/Inverter">
                          Hybrid Converter/Inverter
                        </option>
                        <option value="Idler Arm">Idler Arm</option>
                        <option value="Ignition Module (see also Ignitor/Coil)">
                          Ignition Module (see also Ignitor/Coil)
                        </option>
                        <option value="Ignition Switch">Ignition Switch</option>
                        <option value="Ignitor/Coil">Ignitor/Coil</option>
                        <option value="Info Screen (see also Radio or Heater/AC Control)">
                          Info Screen (see also Radio or Heater/AC Control)
                        </option>
                        <option value="Information Label">
                          Information Label
                        </option>
                        <option value="Inside Door Handle">
                          Inside Door Handle
                        </option>
                        <option value="Instrument Cluster (see also Speedo)">
                          Instrument Cluster (see also Speedo)
                        </option>
                        <option value="Instrument Cluster Bezel">
                          Instrument Cluster Bezel
                        </option>
                        <option value="Instrument Face Plate">
                          Instrument Face Plate
                        </option>
                        <option value="Intake Manifold">Intake Manifold</option>
                        <option value="Intercooler">Intercooler</option>
                        <option value="Intercooler Pipe">
                          Intercooler Pipe
                        </option>
                        <option value="Interior Complete">
                          Interior Complete
                        </option>
                        <option value="Interior Light">Interior Light</option>
                        <option value="Interior Trim Panel (Gate/Lid)">
                          Interior Trim Panel (Gate/Lid)
                        </option>
                        <option value="Interior Trim Panel (Quarter)">
                          Interior Trim Panel (Quarter)
                        </option>
                        <option value="Interior Trim Panel, Door (Back)">
                          Interior Trim Panel, Door (Back)
                        </option>
                        <option value="Interior Trim Panel, Door (Front)">
                          Interior Trim Panel, Door (Front)
                        </option>
                        <option value="Interior Trim Panel, Door (Rear)">
                          Interior Trim Panel, Door (Rear)
                        </option>
                        <option value="Inverter Cooler">Inverter Cooler</option>
                        <option value="Jack Assembly">Jack Assembly</option>
                        <option value="Keys/Latches and Locks">
                          Keys/Latches and Locks
                        </option>
                        <option value="Key Remote/Fob">Key Remote/Fob</option>
                        <option value="Kick Panel">Kick Panel</option>
                        <option value="Knee Assembly (see also Strut Assy)">
                          Knee Assembly (see also Strut Assy)
                        </option>
                        <option value="Lamp Wiring Harness">
                          Lamp Wiring Harness
                        </option>
                        <option value="Latch, Front Door">
                          Latch, Front Door
                        </option>
                        <option value="Latch, Rear Door">
                          Latch, Rear Door
                        </option>
                        <option value="Latch, Back Door">
                          Latch, Back Door
                        </option>
                        <option value="Latches">Latches</option>
                        <option value="Leaf Spring, Front">
                          Leaf Spring, Front
                        </option>
                        <option value="Leaf Spring, Rear">
                          Leaf Spring, Rear
                        </option>
                        <option value="License Lamp">License Lamp</option>
                        <option value="License Plate Bracket">
                          License Plate Bracket
                        </option>
                        <option value="Lid/Gate">Lid/Gate</option>
                        <option value="Lid Interior Trim Panel">
                          Lid Interior Trim Panel
                        </option>
                        <option value="Liftgate Assembly">
                          Liftgate Assembly
                        </option>
                        <option value="Liftgate Shell">Liftgate Shell</option>
                        <option value="Lock Actuator">Lock Actuator</option>
                        <option value="Lockout Hub, 4X4">
                          Lockout Hub, 4X4
                        </option>
                        <option value="Locks">Locks</option>
                        <option value="Lug Wrench">Lug Wrench</option>
                        <option value="Luggage Rack">Luggage Rack</option>
                        <option value="Marker/Fog Light, Front">
                          Marker/Fog Light, Front
                        </option>
                        <option value="Marker/Side Light, Rear">
                          Marker/Side Light, Rear
                        </option>
                        <option value="Master Cylinder">Master Cylinder</option>
                        <option value="Mirror, Door">Mirror, Door</option>
                        <option value="Mirror, Rear View">
                          Mirror, Rear View
                        </option>
                        <option value="Misc. Electrical">
                          Misc. Electrical
                        </option>
                        <option value="Moulding (Back Door)">
                          Moulding (Back Door)
                        </option>
                        <option value="Moulding (Fender)">
                          Moulding (Fender)
                        </option>
                        <option value="Moulding (Front Door)">
                          Moulding (Front Door)
                        </option>
                        <option value="Moulding (Lid/Hatch/Gate)">
                          Moulding (Lid/Hatch/Gate)
                        </option>
                        <option value="Moulding (Quarter Panel/Bed Side)">
                          Moulding (Quarter Panel/Bed Side)
                        </option>
                        <option value="Moulding (Rear Door)">
                          Moulding (Rear Door)
                        </option>
                        <option value="Moulding (Rocker)">
                          Moulding (Rocker)
                        </option>
                        <option value="Moulding (Windshield)">
                          Moulding (Windshield)
                        </option>
                        <option value="Mouldings (Misc)">
                          Mouldings (Misc)
                        </option>
                        <option value="Mud Flap">Mud Flap</option>
                        <option value="Neutral Safety Switch">
                          Neutral Safety Switch
                        </option>
                        <option value="Night Vision Camera">
                          Night Vision Camera
                        </option>
                        <option value="Nose (Front End Assembly)">
                          Nose (Front End Assembly)
                        </option>
                        <option value="Oil Cooler">Oil Cooler</option>
                        <option value="Oil Filter Adapter">
                          Oil Filter Adapter
                        </option>
                        <option value="Oil Pan, Engine">Oil Pan, Engine</option>
                        <option value="Oil Pan, Transmission">
                          Oil Pan, Transmission
                        </option>
                        <option value="Oil Pump, Engine">
                          Oil Pump, Engine
                        </option>
                        <option value="Outside Door Handle">
                          Outside Door Handle
                        </option>
                        <option value="Overdrive Unit (see also Transmission)">
                          Overdrive Unit (see also Transmission)
                        </option>
                        <option value="Owners Manual">Owners Manual</option>
                        <option value="Paddle Shifter">Paddle Shifter</option>
                        <option value="Park/Fog Lamp Front">
                          Park/Fog Lamp Front
                        </option>
                        <option value="Parcel Shelf">Parcel Shelf</option>
                        <option value="Park Lamp Rear (Side)">
                          Park Lamp Rear (Side)
                        </option>
                        <option value="Parking Assist Camera">
                          Parking Assist Camera
                        </option>
                        <option value="Pickup Bed">Pickup Bed</option>
                        <option value="Pickup Bed Floor">
                          Pickup Bed Floor
                        </option>
                        <option value="Pickup Bed Front Panel">
                          Pickup Bed Front Panel
                        </option>
                        <option value="Pickup Bed Side">Pickup Bed Side</option>
                        <option value="Pickup Cap/Camper Shell">
                          Pickup Cap/Camper Shell
                        </option>
                        <option value="Piston">Piston</option>
                        <option value="Pitman Arm">Pitman Arm</option>
                        <option value="Power Brake Booster">
                          Power Brake Booster
                        </option>
                        <option value="Power Inverter (Hybrid)">
                          Power Inverter (Hybrid)
                        </option>
                        <option value="Power Steering Assy">
                          Power Steering Assy
                        </option>
                        <option value="Power Steering Control Valve">
                          Power Steering Control Valve
                        </option>
                        <option value="Power Steering Cooler">
                          Power Steering Cooler
                        </option>
                        <option value="Power Steering Motor">
                          Power Steering Motor
                        </option>
                        <option value="Power Steering Pressure Cyl">
                          Power Steering Pressure Cyl
                        </option>
                        <option value="Power Steering Pressure Hose">
                          Power Steering Pressure Hose
                        </option>
                        <option value="Power Steering Pump">
                          Power Steering Pump
                        </option>
                        <option value="Power Steering Rack/Box/Gear">
                          Power Steering Rack/Box/Gear
                        </option>
                        <option value="Power Steering Reservoir">
                          Power Steering Reservoir
                        </option>
                        <option value="Pressure Plate">Pressure Plate</option>
                        <option value="Push Rod (Engine)">
                          Push Rod (Engine)
                        </option>
                        <option value="Quarter Interior Trim Panel">
                          Quarter Interior Trim Panel
                        </option>
                        <option value="Quarter Moulding">
                          Quarter Moulding
                        </option>
                        <option value="Quarter Panel">Quarter Panel</option>
                        <option value="Quarter Panel Extension">
                          Quarter Panel Extension
                        </option>
                        <option value="Quarter Repair Panel">
                          Quarter Repair Panel
                        </option>
                        <option value="Quarter Window">Quarter Window</option>
                        <option value="Quarter Window Motor">
                          Quarter Window Motor
                        </option>
                        <option value="Quarter Window Regulator">
                          Quarter Window Regulator
                        </option>
                        <option value="Rack &amp; Pinion (Steering)">
                          Rack &amp; Pinion (Steering)
                        </option>
                        <option value="Radiator">Radiator</option>
                        <option value="Radiator/Condenser mtd. Cooling Fan">
                          Radiator/Condenser mtd. Cooling Fan
                        </option>
                        <option value="Radiator Air Shutter">
                          Radiator Air Shutter
                        </option>
                        <option value="Radiator Core Support">
                          Radiator Core Support
                        </option>
                        <option value="Radiator Cover Baffle">
                          Radiator Cover Baffle
                        </option>
                        <option value="Radiator Fan Shroud">
                          Radiator Fan Shroud
                        </option>
                        <option value="Radiator Overflow Bottle">
                          Radiator Overflow Bottle
                        </option>
                        <option value="Radio/CD (see also A/C Control or TV Screen)">
                          Radio/CD (see also A/C Control or TV Screen)
                        </option>
                        <option value="Radio Bezel Trim">
                          Radio Bezel Trim
                        </option>
                        <option value="Radio Face Plate">
                          Radio Face Plate
                        </option>
                        <option value="Radius Arm, Front">
                          Radius Arm, Front
                        </option>
                        <option value="Rag Joint (see also Steering Coupler)">
                          Rag Joint (see also Steering Coupler)
                        </option>
                        <option value="Rear Axle Assy (RWD)">
                          Rear Axle Assy (RWD)
                        </option>
                        <option value="Rear Axle Beam (FWD)">
                          Rear Axle Beam (FWD)
                        </option>
                        <option value="Rear Body Panel">Rear Body Panel</option>
                        <option value="Rear Bumper Assembly (includes cover)">
                          Rear Bumper Assembly (includes cover)
                        </option>
                        <option value="Rear Bumper Cover">
                          Rear Bumper Cover
                        </option>
                        <option value="Rear Bumper Face Bar">
                          Rear Bumper Face Bar
                        </option>
                        <option value="Rear Bumper Guard">
                          Rear Bumper Guard
                        </option>
                        <option value="Rear Bumper Reinforcement/Misc">
                          Rear Bumper Reinforcement/Misc
                        </option>
                        <option value="Rear Clip">Rear Clip</option>
                        <option value="Rear Console">Rear Console</option>
                        <option value="Rear Crossmember">
                          Rear Crossmember
                        </option>
                        <option value="Rear Door (side of vehicle)">
                          Rear Door (side of vehicle)
                        </option>
                        <option value="Rear Door Handle, Inside">
                          Rear Door Handle, Inside
                        </option>
                        <option value="Rear Door Handle, Outside">
                          Rear Door Handle, Outside
                        </option>
                        <option value="Rear Door Hinge">Rear Door Hinge</option>
                        <option value="Rear Door Moulding">
                          Rear Door Moulding
                        </option>
                        <option value="Rear Door Regulator">
                          Rear Door Regulator
                        </option>
                        <option value="Rear Door Shell">Rear Door Shell</option>
                        <option value="Rear Door Switch">
                          Rear Door Switch
                        </option>
                        <option value="Rear Door Trim Panel">
                          Rear Door Trim Panel
                        </option>
                        <option value="Rear Door Vent Glass">
                          Rear Door Vent Glass
                        </option>
                        <option value="Rear Door Vent Glass regulator">
                          Rear Door Vent Glass regulator
                        </option>
                        <option value="Rear Door Window">
                          Rear Door Window
                        </option>
                        <option value="Rear Door Window Motor">
                          Rear Door Window Motor
                        </option>
                        <option value="Rear Door Window Regulator">
                          Rear Door Window Regulator
                        </option>
                        <option value="Rear Drive Shaft">
                          Rear Drive Shaft
                        </option>
                        <option value="Rear Finish Panel">
                          Rear Finish Panel
                        </option>
                        <option value="Rear Gate/Lid">Rear Gate/Lid</option>
                        <option value="Rear Gate Window Motor">
                          Rear Gate Window Motor
                        </option>
                        <option value="Rear Knuckle/Stub Axle">
                          Rear Knuckle/Stub Axle
                        </option>
                        <option value="Rear Lower Valance">
                          Rear Lower Valance
                        </option>
                        <option value="Rear Seat Belt Assembly">
                          Rear Seat Belt Assembly
                        </option>
                        <option value="Rear Suspension (see also Control Arms)">
                          Rear Suspension (see also Control Arms)
                        </option>
                        <option value="Rear Suspension Locating Arm">
                          Rear Suspension Locating Arm
                        </option>
                        <option value="Rear Suspension Trailing Arm">
                          Rear Suspension Trailing Arm
                        </option>
                        <option value="Rear Window Defogger">
                          Rear Window Defogger
                        </option>
                        <option value="Rear Window Washer Motor">
                          Rear Window Washer Motor
                        </option>
                        <option value="Receiver Dryer">Receiver Dryer</option>
                        <option value="Relay (Misc)">Relay (Misc)</option>
                        <option value="Ring and Pinion Only">
                          Ring and Pinion Only
                        </option>
                        <option value="Rocker Arm">Rocker Arm</option>
                        <option value="Rocker Moulding">Rocker Moulding</option>
                        <option value="Rocker Panel (see also Center Pillar)">
                          Rocker Panel (see also Center Pillar)
                        </option>
                        <option value="Roll Bar">Roll Bar</option>
                        <option value="Roll Bar Padding">
                          Roll Bar Padding
                        </option>
                        <option value="Roof">Roof</option>
                        <option value="Roof Glass Frame/Track">
                          Roof Glass Frame/Track
                        </option>
                        <option value="Roof Panel (see also Sunroof)">
                          Roof Panel (see also Sunroof)
                        </option>
                        <option value="Roof Rack">Roof Rack</option>
                        <option value="Running Boards">Running Boards</option>
                        <option value="Running Board Motor">
                          Running Board Motor
                        </option>
                        <option value="Seat, Back (3rd Row)">
                          Seat, Back (3rd Row)
                        </option>
                        <option value="Seat, Back (4th Row)">
                          Seat, Back (4th Row)
                        </option>
                        <option value="Seat, Back (5th Row)">
                          Seat, Back (5th Row)
                        </option>
                        <option value="Seat, Front">Seat, Front</option>
                        <option value="Seat, Rear (2nd Row)">
                          Seat, Rear (2nd Row)
                        </option>
                        <option value="Seat Belt, Front">
                          Seat Belt, Front
                        </option>
                        <option value="Seat Belt, Rear">Seat Belt, Rear</option>
                        <option value="Seat Belt Motor">Seat Belt Motor</option>
                        <option value="Seat Belt Pretensioner">
                          Seat Belt Pretensioner
                        </option>
                        <option value="Seat Belt Track (Electric)">
                          Seat Belt Track (Electric)
                        </option>
                        <option value="Seat Motor">Seat Motor</option>
                        <option value="Seat Switch">Seat Switch</option>
                        <option value="Seat Track, Front Only">
                          Seat Track, Front Only
                        </option>
                        <option value="Sensor (Body, Misc)">
                          Sensor (Body, Misc)
                        </option>
                        <option value="Sensor (Chassis, Misc)">
                          Sensor (Chassis, Misc)
                        </option>
                        <option value="Sensor (Drivetrain, Misc)">
                          Sensor (Drivetrain, Misc)
                        </option>
                        <option value="Shifter Assembly (Floor)">
                          Shifter Assembly (Floor)
                        </option>
                        <option value="Shifter Linkage">Shifter Linkage</option>
                        <option value="Shock Absorber">Shock Absorber</option>
                        <option value="Shock Mount">Shock Mount</option>
                        <option value="Sill Plate">Sill Plate</option>
                        <option value="Skid Plate">Skid Plate</option>
                        <option value="Slave Cylinder">Slave Cylinder</option>
                        <option value="Smog Pump">Smog Pump</option>
                        <option value="Spare Tire Carrier">
                          Spare Tire Carrier
                        </option>
                        <option value="Spare Tire Cover">
                          Spare Tire Cover
                        </option>
                        <option value="Spare Tire Hoist">
                          Spare Tire Hoist
                        </option>
                        <option value="Speaker">Speaker</option>
                        <option value="Special Glass">Special Glass</option>
                        <option value="Speedometer (see also Instr. Cluster)">
                          Speedometer (see also Instr. Cluster)
                        </option>
                        <option value="Speedometer Cable">
                          Speedometer Cable
                        </option>
                        <option value="Spindle">Spindle</option>
                        <option value="Spoiler, Front">Spoiler, Front</option>
                        <option value="Spoiler, Rear">Spoiler, Rear</option>
                        <option value="Spring Hanger">Spring Hanger</option>
                        <option value="Stabilizer Bar Only">
                          Stabilizer Bar Only
                        </option>
                        <option value="Starter">Starter</option>
                        <option value="Steering Column">Steering Column</option>
                        <option value="Steering Column Shaft">
                          Steering Column Shaft
                        </option>
                        <option value="Steering Coupler">
                          Steering Coupler
                        </option>
                        <option value="Steering Knuckle (see also Knee &amp; Strut)">
                          Steering Knuckle (see also Knee &amp; Strut)
                        </option>
                        <option value="Steering Pump">Steering Pump</option>
                        <option value="Steering Rack/Box/Gear">
                          Steering Rack/Box/Gear
                        </option>
                        <option value="Steering Wheel">Steering Wheel</option>
                        <option value="Strut (see also Knee Assy)">
                          Strut (see also Knee Assy)
                        </option>
                        <option value="Strut Tower Brace">
                          Strut Tower Brace
                        </option>
                        <option value="Sun Roof / T-Top">
                          Sun Roof / T-Top
                        </option>
                        <option value="Sun Roof Motor">Sun Roof Motor</option>
                        <option value="Sunvisor">Sunvisor</option>
                        <option value="Supercharger/Turbocharger">
                          Supercharger/Turbocharger
                        </option>
                        <option value="Tachometer">Tachometer</option>
                        <option value="Tail Light">Tail Light</option>
                        <option value="Tail Light Circuit Board">
                          Tail Light Circuit Board
                        </option>
                        <option value="Tail Light Lens">Tail Light Lens</option>
                        <option value="Tailgate Cable">Tailgate Cable</option>
                        <option value="Tailgate/Trunk Lid">
                          Tailgate/Trunk Lid
                        </option>
                        <option value="Tailgate Hinge">Tailgate Hinge</option>
                        <option value="Tailgate Lift Motor">
                          Tailgate Lift Motor
                        </option>
                        <option value="Tailgate Shell">Tailgate Shell</option>
                        <option value="Tailgate Window Regulator">
                          Tailgate Window Regulator
                        </option>
                        <option value="Thermostat Housing">
                          Thermostat Housing
                        </option>
                        <option value="Third Brake Light">
                          Third Brake Light
                        </option>
                        <option value="Throttle Body/Throttle Valve Housing">
                          Throttle Body/Throttle Valve Housing
                        </option>
                        <option value="Throwout Bearing">
                          Throwout Bearing
                        </option>
                        <option value="Tie Rod">Tie Rod</option>
                        <option value="Timing Belt/Chain">
                          Timing Belt/Chain
                        </option>
                        <option value="Timing Cover">Timing Cover</option>
                        <option value="Timing Gears">Timing Gears</option>
                        <option value="Tire">Tire</option>
                        <option value="Tonneau Cover">Tonneau Cover</option>
                        <option value="Torque Convertor">
                          Torque Convertor
                        </option>
                        <option value="Torsion Bar">Torsion Bar</option>
                        <option value="Tow Hook">Tow Hook</option>
                        <option value="Track/Watts Linkage">
                          Track/Watts Linkage
                        </option>
                        <option value="Trailer Brake Controller">
                          Trailer Brake Controller
                        </option>
                        <option value="Trailer Hitch">Trailer Hitch</option>
                        <option value="Trans OD Unit (see also Transmission)">
                          Trans OD Unit (see also Transmission)
                        </option>
                        <option value="Transaxle Housing Only">
                          Transaxle Housing Only
                        </option>
                        <option value="Transfer Case">Transfer Case</option>
                        <option value="Transfer Case Adapter">
                          Transfer Case Adapter
                        </option>
                        <option value="Transfer Case Core">
                          Transfer Case Core
                        </option>
                        <option value="Transfer Case Electric Motor">
                          Transfer Case Electric Motor
                        </option>
                        <option value="Transfer Case Switch">
                          Transfer Case Switch
                        </option>
                        <option value="Transmission">Transmission</option>
                        <option value="Transmission Bellhousing Only">
                          Transmission Bellhousing Only
                        </option>
                        <option value="Transmission Clutch Actuator">
                          Transmission Clutch Actuator
                        </option>
                        <option value="Transmission Computer">
                          Transmission Computer
                        </option>
                        <option value="Transmission Core">
                          Transmission Core
                        </option>
                        <option value="Transmission Crossmember">
                          Transmission Crossmember
                        </option>
                        <option value="Transmission Front Pump">
                          Transmission Front Pump
                        </option>
                        <option value="Transmission Mount">
                          Transmission Mount
                        </option>
                        <option value="Transmission Pan">
                          Transmission Pan
                        </option>
                        <option value="Transmission Torque Converter">
                          Transmission Torque Converter
                        </option>
                        <option value="Transmission Valve Body">
                          Transmission Valve Body
                        </option>
                        <option value="Transmission Wiring Harness">
                          Transmission Wiring Harness
                        </option>
                        <option value="Trim Ring">Trim Ring</option>
                        <option value="Trunk Lid Pull Down Motor">
                          Trunk Lid Pull Down Motor
                        </option>
                        <option value="Trunk Lid/Hatch">Trunk Lid/Hatch</option>
                        <option value="Trunk Lid/Hatch Hinge">
                          Trunk Lid/Hatch Hinge
                        </option>
                        <option value="Trunk Lid/Hatch Shock">
                          Trunk Lid/Hatch Shock
                        </option>
                        <option value="Trunk Lid/Tailgate Moulding">
                          Trunk Lid/Tailgate Moulding
                        </option>
                        <option value="TTop/Sunroof">TTop/Sunroof</option>
                        <option value="Turbo/Supercharger Core">
                          Turbo/Supercharger Core
                        </option>
                        <option value="Turbocharger/Supercharger">
                          Turbocharger/Supercharger
                        </option>
                        <option value="Turn Signal/Fog Lamp">
                          Turn Signal/Fog Lamp
                        </option>
                        <option value="TV Screen (see also Radio or Heater/AC Control)">
                          TV Screen (see also Radio or Heater/AC Control)
                        </option>
                        <option value="Uniside">Uniside</option>
                        <option value="Utility Bed">Utility Bed</option>
                        <option value="Utility Box">Utility Box</option>
                        <option value="Vacuum Pump">Vacuum Pump</option>
                        <option value="Vacuum Storage Tank">
                          Vacuum Storage Tank
                        </option>
                        <option value="Valance, Front">Valance, Front</option>
                        <option value="Valance, Rear">Valance, Rear</option>
                        <option value="Valve Cover">Valve Cover</option>
                        <option value="Vapor Canister">Vapor Canister</option>
                        <option value="Voltage Regulator">
                          Voltage Regulator
                        </option>
                        <option value="Washer Nozzle">Washer Nozzle</option>
                        <option value="Water Pump">Water Pump</option>
                        <option value="Water Separator">Water Separator</option>
                        <option value="Weather Stripping">
                          Weather Stripping
                        </option>
                        <option value="Wheel (display w image)">
                          Wheel (display w image)
                        </option>
                        <option value="Wheel (display w/o image)">
                          Wheel (display w/o image)
                        </option>
                        <option value="Wheel Bearing">Wheel Bearing</option>
                        <option value="Wheel Cover/Hubcap (display w image)">
                          Wheel Cover/Hubcap (display w image)
                        </option>
                        <option value="Wheel Cover/Hubcap (display w/o image)">
                          Wheel Cover/Hubcap (display w/o image)
                        </option>
                        <option value="Wheel Lug Nut">Wheel Lug Nut</option>
                        <option value="Wheel Opening Moulding">
                          Wheel Opening Moulding
                        </option>
                        <option value="Wheelchair Lift">Wheelchair Lift</option>
                        <option value="Wheelchair Ramp">Wheelchair Ramp</option>
                        <option value="Wheelhouse (Rear)">
                          Wheelhouse (Rear)
                        </option>
                        <option value="Winch">Winch</option>
                        <option value="Window Motor">Window Motor</option>
                        <option value="Window Regulator (Front)">
                          Window Regulator (Front)
                        </option>
                        <option value="Window Regulator (Rear)">
                          Window Regulator (Rear)
                        </option>
                        <option value="Window Shade">Window Shade</option>
                        <option value="Window Switch (Front Door)">
                          Window Switch (Front Door)
                        </option>
                        <option value="Window Switch (Rear Door)">
                          Window Switch (Rear Door)
                        </option>
                        <option value="Window Washer Motor, Rear">
                          Window Washer Motor, Rear
                        </option>
                        <option value="Windshield">Windshield</option>
                        <option value="Windshield Frame">
                          Windshield Frame
                        </option>
                        <option value="Windshield Hinge">
                          Windshield Hinge
                        </option>
                        <option value="Windshield Washer Motor (Front)">
                          Windshield Washer Motor (Front)
                        </option>
                        <option value="Windshield Washer Reservoir">
                          Windshield Washer Reservoir
                        </option>
                        <option value="Wiper Arm">Wiper Arm</option>
                        <option value="Wiper Linkage">Wiper Linkage</option>
                        <option value="Wiper Motor, Front (Windshield)">
                          Wiper Motor, Front (Windshield)
                        </option>
                        <option value="Wiper Motor, Rear">
                          Wiper Motor, Rear
                        </option>
                        <option value="Wiring Harness (Air Conditioning)">
                          Wiring Harness (Air Conditioning)
                        </option>
                        <option value="Wiring Harness (Body)">
                          Wiring Harness (Body)
                        </option>
                        <option value="Wiring Harness (Dash)">
                          Wiring Harness (Dash)
                        </option>
                        <option value="Wiring Harness (Engine)">
                          Wiring Harness (Engine)
                        </option>
                        <option value="Wiring Harness (Lamp)">
                          Wiring Harness (Lamp)
                        </option>
                        <option value="Wiring Harness (Misc)">
                          Wiring Harness (Misc)
                        </option>
                        <option value="Wiring Harness (Transmission)">
                          Wiring Harness (Transmission)
                        </option>
                        <option value="Yoke/U-Joint">Yoke/U-Joint</option>
                      </select>
                    </div>
                    {/* Make Dropdown */}
                    <div className="flex flex-col">
                      <label className="text-black mb-2">
                        Make <span className="text-red-500">*</span>
                      </label>
                      <select
                        name="make"
                        value={formData?.make}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 bg-white text-black"
                      >
                        <option>Select Make</option>
                        <option value="Acura">Acura</option>
                        <option value="Alfa">Alfa</option>
                        <option value="Aston Martin">Aston Martin</option>
                        <option value="Audi">Audi</option>
                        <option value="Autocar">Autocar</option>
                        <option value="BMW">BMW</option>
                        <option value="Bentley">Bentley</option>
                        <option value="Buick">Buick</option>
                        <option value="Cadillac">Cadillac</option>
                        <option value="Chevy">Chevy</option>
                        <option value="Chrysler">Chrysler</option>
                        <option value="Dodge">Dodge</option>
                        <option value="Ferrari">Ferrari</option>
                        <option value="Fiat">Fiat</option>
                        <option value="Fisker">Fisker</option>
                        <option value="Ford">Ford</option>
                        <option value="Freightliner">Freightliner</option>
                        <option value="GMC">GMC</option>
                        <option value="Genesis">Genesis</option>
                        <option value="Honda">Honda</option>
                        <option value="Hyundai">Hyundai</option>
                        <option value="IH">IH</option>
                        <option value="Infiniti">Infiniti</option>
                        <option value="Isuzu">Isuzu</option>
                        <option value="Jaguar">Jaguar</option>
                        <option value="Jeep">Jeep</option>
                        <option value="Kenworth">Kenworth</option>
                        <option value="Kia">Kia</option>
                        <option value="Lamborghini">Lamborghini</option>
                        <option value="Land-Rover">Land-Rover</option>
                        <option value="Lexus">Lexus</option>
                        <option value="Lincoln">Lincoln</option>
                        <option value="Lotus">Lotus</option>
                        <option value="Lucid">Lucid</option>
                        <option value="Mac">Mac</option>
                        <option value="Maserati">Maserati</option>
                        <option value="Mazda">Mazda</option>
                        <option value="McLaren">McLaren</option>
                        <option value="Mercedes">Mercedes</option>
                        <option value="Mini">Mini</option>
                        <option value="Mitsubishi">Mitsubishi</option>
                        <option value="Nissan">Nissan</option>
                        <option value="Peterbilt">Peterbilt</option>
                        <option value="Plymouth">Plymouth</option>
                        <option value="Polestar">Polestar</option>
                        <option value="Porsche">Porsche</option>
                        <option value="Ram">Ram</option>
                        <option value="Rivian">Rivian</option>
                        <option value="RollsRoyce">RollsRoyce</option>
                        <option value="Subaru">Subaru</option>
                        <option value="Tesla">Tesla</option>
                        <option value="Toyota">Toyota</option>
                        <option value="Utilimaster">Utilimaster</option>
                        <option value="Volkswagen">Volkswagen</option>
                        <option value="Volvo">Volvo</option>
                        <option value="Western Star">Western Star</option>
                      </select>
                    </div>

                    {/* Model Dropdown */}
                    <div className="flex flex-col">
                      <label className="text-black mb-2">
                        Model <span className="text-red-500">*</span>
                      </label>
                      <select
                        name="model"
                        value={formData?.model}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 bg-white text-black"
                      >
                        <option selected="selected">Select Model</option>
                        <option value="AMC Ambassador">AMC Ambassador</option>
                        <option value="AMC American">AMC American</option>
                        <option value="AMC AMX">AMC AMX</option>
                        <option value="AMC Classic">AMC Classic</option>
                        <option value="AMC Concord">AMC Concord</option>
                        <option value="AMC Eagle">AMC Eagle</option>
                        <option value="AMC Gremlin">AMC Gremlin</option>
                        <option value="AMC Hornet">AMC Hornet</option>
                        <option value="AMC Javelin">AMC Javelin</option>
                        <option value="AMC Marlin">AMC Marlin</option>
                        <option value="AMC Matador">AMC Matador</option>
                        <option value="AMC Pacer">AMC Pacer</option>
                        <option value="AMC Rambler">AMC Rambler</option>
                        <option value="AMC Rebel">AMC Rebel</option>
                        <option value="AMC Spirit">AMC Spirit</option>
                        <option value="AMC Other">AMC Other</option>
                        <option value="Acura CL">Acura CL</option>
                        <option value="Acura CSX">Acura CSX</option>
                        <option value="Acura EL">Acura EL</option>
                        <option value="Acura ILX">Acura ILX</option>
                        <option value="Acura Integra">Acura Integra</option>
                        <option value="Acura Legend">Acura Legend</option>
                        <option value="Acura MDX">Acura MDX</option>
                        <option value="Acura NSX">Acura NSX</option>
                        <option value="Acura RDX">Acura RDX</option>
                        <option value="Acura RL">Acura RL</option>
                        <option value="Acura RLX">Acura RLX</option>
                        <option value="Acura RSX">Acura RSX</option>
                        <option value="Acura SLX">Acura SLX</option>
                        <option value="Acura TL">Acura TL</option>
                        <option value="Acura TLX">Acura TLX</option>
                        <option value="Acura TSX">Acura TSX</option>
                        <option value="Acura Vigor">Acura Vigor</option>
                        <option value="Acura ZDX">Acura ZDX</option>
                        <option value="Alfa 147">Alfa 147</option>
                        <option value="Alfa 164 Sedan">Alfa 164 Sedan</option>
                        <option value="Alfa 1750">Alfa 1750</option>
                        <option value="Alfa 4C">Alfa 4C</option>
                        <option value="Alfa Alfetta">Alfa Alfetta</option>
                        <option value="Alfa GTV6">Alfa GTV6</option>
                        <option value="Alfa Giulia">Alfa Giulia</option>
                        <option value="Alfa Giulia 1600">
                          Alfa Giulia 1600
                        </option>
                        <option value="Alfa Giulietta">Alfa Giulietta</option>
                        <option value="Alfa Milano">Alfa Milano</option>
                        <option value="Alfa Mito">Alfa Mito</option>
                        <option value="Alfa Spider-1600">
                          Alfa Spider-1600
                        </option>
                        <option value="Alfa Spider-1600 Duetto">
                          Alfa Spider-1600 Duetto
                        </option>
                        <option value="Alfa Spider-2000">
                          Alfa Spider-2000
                        </option>
                        <option value="Alfa Stelvio">Alfa Stelvio</option>
                        <option value="Alfa Tonale">Alfa Tonale</option>
                        <option value="Aston Martin">Aston Martin</option>
                        <option value="Asuna">Asuna</option>
                        <option value="Audi 100">Audi 100</option>
                        <option value="Audi 200">Audi 200</option>
                        <option value="Audi 4000 2 &amp; 4 Door Sedan">
                          Audi 4000 2 &amp; 4 Door Sedan
                        </option>
                        <option value="Audi 4000 Quattro">
                          Audi 4000 Quattro
                        </option>
                        <option value="Audi 5000 &amp; 5000 Quattro">
                          Audi 5000 &amp; 5000 Quattro
                        </option>
                        <option value="Audi 80 Series">Audi 80 Series</option>
                        <option value="Audi 90 Series">Audi 90 Series</option>
                        <option value="Audi A3">Audi A3</option>
                        <option value="Audi A4">Audi A4</option>
                        <option value="Audi A5">Audi A5</option>
                        <option value="Audi A6">Audi A6</option>
                        <option value="Audi A7">Audi A7</option>
                        <option value="Audi A8">Audi A8</option>
                        <option value="Audi AllRoad">Audi AllRoad</option>
                        <option value="Audi AllRoad A4">Audi AllRoad A4</option>
                        <option value="Audi AllRoad A6">Audi AllRoad A6</option>
                        <option value="Audi Cabriolet">Audi Cabriolet</option>
                        <option value="Audi Coupe GT">Audi Coupe GT</option>
                        <option value="Audi Coupe Quattro">
                          Audi Coupe Quattro
                        </option>
                        <option value="Audi E-tron">Audi E-tron</option>
                        <option value="Audi E-tron GT">Audi E-tron GT</option>
                        <option value="Audi E-tron S">Audi E-tron S</option>
                        <option value="Audi Fox">Audi Fox</option>
                        <option value="Audi Q3">Audi Q3</option>
                        <option value="Audi Q4 E-tron">Audi Q4 E-tron</option>
                        <option value="Audi Q5">Audi Q5</option>
                        <option value="Audi Q7">Audi Q7</option>
                        <option value="Audi Q8">Audi Q8</option>
                        <option value="Audi Q8 E tron">Audi Q8 E tron</option>
                        <option value="Audi R8">Audi R8</option>
                        <option value="Audi RS3">Audi RS3</option>
                        <option value="Audi RS4">Audi RS4</option>
                        <option value="Audi RS5">Audi RS5</option>
                        <option value="Audi RS6">Audi RS6</option>
                        <option value="Audi RS7">Audi RS7</option>
                        <option value="Audi RS E-tron GT">
                          Audi RS E-tron GT
                        </option>
                        <option value="Audi RS Q8">Audi RS Q8</option>
                        <option value="Audi S3">Audi S3</option>
                        <option value="Audi S4">Audi S4</option>
                        <option value="Audi S5">Audi S5</option>
                        <option value="Audi S6">Audi S6</option>
                        <option value="Audi S7">Audi S7</option>
                        <option value="Audi S8">Audi S8</option>
                        <option value="Audi SQ5">Audi SQ5</option>
                        <option value="Audi SQ7">Audi SQ7</option>
                        <option value="Audi SQ8">Audi SQ8</option>
                        <option value="Audi SQ8 E tron">Audi SQ8 E tron</option>
                        <option value="Audi Sport Coupe">
                          Audi Sport Coupe
                        </option>
                        <option value="Audi Super 90">Audi Super 90</option>
                        <option value="Audi TT">Audi TT</option>
                        <option value="Audi V8 Quattro">Audi V8 Quattro</option>
                        <option value="Austin">Austin</option>
                        <option value="Austin Mini">Austin Mini</option>
                        <option value="Autocar">Autocar</option>
                        <option value="Avanti">Avanti</option>
                        <option value="BMW 1M">BMW 1M</option>
                        <option value="BMW 128i">BMW 128i</option>
                        <option value="BMW 135i">BMW 135i</option>
                        <option value="BMW 1602">BMW 1602</option>
                        <option value="BMW 1800">BMW 1800</option>
                        <option value="BMW 228i">BMW 228i</option>
                        <option value="BMW 230i">BMW 230i</option>
                        <option value="BMW 2002">BMW 2002</option>
                        <option value="BMW 2500">BMW 2500</option>
                        <option value="BMW 2800">BMW 2800</option>
                        <option value="BMW 3.0">BMW 3.0</option>
                        <option value="BMW 318i">BMW 318i</option>
                        <option value="BMW 320i">BMW 320i</option>
                        <option value="BMW 323i">BMW 323i</option>
                        <option value="BMW 325e">BMW 325e</option>
                        <option value="BMW 325i">BMW 325i</option>
                        <option value="BMW 328i">BMW 328i</option>
                        <option value="BMW 328i GT">BMW 328i GT</option>
                        <option value="BMW 330e">BMW 330e</option>
                        <option value="BMW 330i">BMW 330i</option>
                        <option value="BMW 330i GT">BMW 330i GT</option>
                        <option value="BMW 335i">BMW 335i</option>
                        <option value="BMW 335i GT">BMW 335i GT</option>
                        <option value="BMW 340i">BMW 340i</option>
                        <option value="BMW 340i GT">BMW 340i GT</option>
                        <option value="BMW 428i">BMW 428i</option>
                        <option value="BMW 430i">BMW 430i</option>
                        <option value="BMW 435i">BMW 435i</option>
                        <option value="BMW 440i">BMW 440i</option>
                        <option value="BMW 524TD">BMW 524TD</option>
                        <option value="BMW 525i">BMW 525i</option>
                        <option value="BMW 528e">BMW 528e</option>
                        <option value="BMW 528i">BMW 528i</option>
                        <option value="BMW 530e">BMW 530e</option>
                        <option value="BMW 530i">BMW 530i</option>
                        <option value="BMW 533i">BMW 533i</option>
                        <option value="BMW 535i">BMW 535i</option>
                        <option value="BMW 535i GT">BMW 535i GT</option>
                        <option value="BMW 540i">BMW 540i</option>
                        <option value="BMW 545i">BMW 545i</option>
                        <option value="BMW 550i">BMW 550i</option>
                        <option value="BMW 550i GT">BMW 550i GT</option>
                        <option value="BMW 630CSi">BMW 630CSi</option>
                        <option value="BMW 633CSi">BMW 633CSi</option>
                        <option value="BMW 635CSi">BMW 635CSi</option>
                        <option value="BMW 640i">BMW 640i</option>
                        <option value="BMW 640i GT">BMW 640i GT</option>
                        <option value="BMW 645Ci">BMW 645Ci</option>
                        <option value="BMW 650i">BMW 650i</option>
                        <option value="BMW 728">BMW 728</option>
                        <option value="BMW 732">BMW 732</option>
                        <option value="BMW 733i">BMW 733i</option>
                        <option value="BMW 735i">BMW 735i</option>
                        <option value="BMW 740e">BMW 740e</option>
                        <option value="BMW 740i">BMW 740i</option>
                        <option value="BMW 745e">BMW 745e</option>
                        <option value="BMW 745i">BMW 745i</option>
                        <option value="BMW 750e">BMW 750e</option>
                        <option value="BMW 750i">BMW 750i</option>
                        <option value="BMW 760i">BMW 760i</option>
                        <option value="BMW 840ci">BMW 840ci</option>
                        <option value="BMW 840i">BMW 840i</option>
                        <option value="BMW 850i">BMW 850i</option>
                        <option value="BMW ActiveE">BMW ActiveE</option>
                        <option value="BMW ActiveHybrid 3">
                          BMW ActiveHybrid 3
                        </option>
                        <option value="BMW ActiveHybrid 5">
                          BMW ActiveHybrid 5
                        </option>
                        <option value="BMW ActiveHybrid 7">
                          BMW ActiveHybrid 7
                        </option>
                        <option value="BMW Alpina B6">BMW Alpina B6</option>
                        <option value="BMW Alpina B7">BMW Alpina B7</option>
                        <option value="BMW Alpina B8">BMW Alpina B8</option>
                        <option value="BMW Alpina XB7">BMW Alpina XB7</option>
                        <option value="BMW I3">BMW I3</option>
                        <option value="BMW I4">BMW I4</option>
                        <option value="BMW I5">BMW I5</option>
                        <option value="BMW I7">BMW I7</option>
                        <option value="BMW I8">BMW I8</option>
                        <option value="BMW IX">BMW IX</option>
                        <option value="BMW L6">BMW L6</option>
                        <option value="BMW Mini Cooper">BMW Mini Cooper</option>
                        <option value="BMW Mini Cooper Clubman">
                          BMW Mini Cooper Clubman
                        </option>
                        <option value="BMW Mini Cooper Countryman">
                          BMW Mini Cooper Countryman
                        </option>
                        <option value="BMW Mini Cooper Paceman">
                          BMW Mini Cooper Paceman
                        </option>
                        <option value="BMW M1">BMW M1</option>
                        <option value="BMW M2">BMW M2</option>
                        <option value="BMW M3">BMW M3</option>
                        <option value="BMW M4">BMW M4</option>
                        <option value="BMW M5">BMW M5</option>
                        <option value="BMW M6">BMW M6</option>
                        <option value="BMW M8">BMW M8</option>
                        <option value="BMW M235i">BMW M235i</option>
                        <option value="BMW M240i">BMW M240i</option>
                        <option value="BMW M340i">BMW M340i</option>
                        <option value="BMW M440i">BMW M440i</option>
                        <option value="BMW M550i">BMW M550i</option>
                        <option value="BMW M760i">BMW M760i</option>
                        <option value="BMW M850i">BMW M850i</option>
                        <option value="BMW X1">BMW X1</option>
                        <option value="BMW X2">BMW X2</option>
                        <option value="BMW X3">BMW X3</option>
                        <option value="BMW X3M">BMW X3M</option>
                        <option value="BMW X4">BMW X4</option>
                        <option value="BMW X4M">BMW X4M</option>
                        <option value="BMW X5">BMW X5</option>
                        <option value="BMW X5M">BMW X5M</option>
                        <option value="BMW X6">BMW X6</option>
                        <option value="BMW X6M">BMW X6M</option>
                        <option value="BMW X7">BMW X7</option>
                        <option value="BMW XM">BMW XM</option>
                        <option value="BMW Z3">BMW Z3</option>
                        <option value="BMW Z4">BMW Z4</option>
                        <option value="BMW Z8">BMW Z8</option>
                        <option value="BMW Other">BMW Other</option>
                        <option value="Bentley">Bentley</option>
                        <option value="Bentley Arnage">Bentley Arnage</option>
                        <option value="Bentley Azure">Bentley Azure</option>
                        <option value="Bentley Bentayga">
                          Bentley Bentayga
                        </option>
                        <option value="Bentley Brooklands">
                          Bentley Brooklands
                        </option>
                        <option value="Bentley Continental">
                          Bentley Continental
                        </option>
                        <option value="Bentley Corniche">
                          Bentley Corniche
                        </option>
                        <option value="Bentley Eight">Bentley Eight</option>
                        <option value="Bentley Flying Spur">
                          Bentley Flying Spur
                        </option>
                        <option value="Bentley Mulsanne">
                          Bentley Mulsanne
                        </option>
                        <option value="Bentley Turbo R">Bentley Turbo R</option>
                        <option value="Bricklin">Bricklin</option>
                        <option value="Brockway">Brockway</option>
                        <option value="Buick Allure">Buick Allure</option>
                        <option value="Buick Apollo">Buick Apollo</option>
                        <option value="Buick Cascada">Buick Cascada</option>
                        <option value="Buick Century">Buick Century</option>
                        <option value="Buick Electra (1979 Down)">
                          Buick Electra (1979 Down)
                        </option>
                        <option value="Buick Electra (1980 Up)">
                          Buick Electra (1980 Up)
                        </option>
                        <option value="Buick Enclave">Buick Enclave</option>
                        <option value="Buick Encore">Buick Encore</option>
                        <option value="Buick Encore GX">Buick Encore GX</option>
                        <option value="Buick Envision">Buick Envision</option>
                        <option value="Buick Envista">Buick Envista</option>
                        <option value="Buick Lacrosse">Buick Lacrosse</option>
                        <option value="Buick LeSabre (1979 Down)">
                          Buick LeSabre (1979 Down)
                        </option>
                        <option value="Buick LeSabre (1980 Up)">
                          Buick LeSabre (1980 Up)
                        </option>
                        <option value="Buick Limited">Buick Limited</option>
                        <option value="Buick Lucerne">Buick Lucerne</option>
                        <option value="Buick Park Ave (1979 Down)">
                          Buick Park Ave (1979 Down)
                        </option>
                        <option value="Buick Park Ave (1980 Up)">
                          Buick Park Ave (1980 Up)
                        </option>
                        <option value="Buick Rainier">Buick Rainier</option>
                        <option value="Buick Reatta">Buick Reatta</option>
                        <option value="Buick Regal">Buick Regal</option>
                        <option value="Buick Regal Somerset (1984 Down)">
                          Buick Regal Somerset (1984 Down)
                        </option>
                        <option value="Buick Rendezvous">
                          Buick Rendezvous
                        </option>
                        <option value="Buick Riviera">Buick Riviera</option>
                        <option value="Buick Roadmaster (1979 Down)">
                          Buick Roadmaster (1979 Down)
                        </option>
                        <option value="Buick Roadmaster (1980 Up)">
                          Buick Roadmaster (1980 Up)
                        </option>
                        <option value="Buick Skyhawk">Buick Skyhawk</option>
                        <option value="Buick Skylark">Buick Skylark</option>
                        <option value="Buick Somerset (1985 Up)">
                          Buick Somerset (1985 Up)
                        </option>
                        <option value="Buick Special">Buick Special</option>
                        <option value="Buick Terraza">Buick Terraza</option>
                        <option value="Buick Verano">Buick Verano</option>
                        <option value="Buick Other">Buick Other</option>
                        <option value="Cadillac Allante">
                          Cadillac Allante
                        </option>
                        <option value="Cadillac ATS">Cadillac ATS</option>
                        <option value="Cadillac Brougham">
                          Cadillac Brougham
                        </option>
                        <option value="Cadillac CT4">Cadillac CT4</option>
                        <option value="Cadillac CT5">Cadillac CT5</option>
                        <option value="Cadillac CT6">Cadillac CT6</option>
                        <option value="Cadillac CTS">Cadillac CTS</option>
                        <option value="Cadillac Catera">Cadillac Catera</option>
                        <option value="Cadillac Cimarron">
                          Cadillac Cimarron
                        </option>
                        <option value="Cadillac Concours">
                          Cadillac Concours
                        </option>
                        <option value="Cadillac DeVille (1979 Down)">
                          Cadillac DeVille (1979 Down)
                        </option>
                        <option value="Cadillac DeVille (1980 Up)">
                          Cadillac DeVille (1980 Up)
                        </option>
                        <option value="Cadillac DHS">Cadillac DHS</option>
                        <option value="Cadillac DTS (2005 Down)">
                          Cadillac DTS (2005 Down)
                        </option>
                        <option value="Cadillac DTS (2006 Up)">
                          Cadillac DTS (2006 Up)
                        </option>
                        <option value="Cadillac ELR">Cadillac ELR</option>
                        <option value="Cadillac Eldorado (1966 Down)">
                          Cadillac Eldorado (1966 Down)
                        </option>
                        <option value="Cadillac Eldorado (1967 Up)">
                          Cadillac Eldorado (1967 Up)
                        </option>
                        <option value="Cadillac Escalade">
                          Cadillac Escalade
                        </option>
                        <option value="Cadillac Escalade-ESV">
                          Cadillac Escalade-ESV
                        </option>
                        <option value="Cadillac Escalade-EXT">
                          Cadillac Escalade-EXT
                        </option>
                        <option value="Cadillac Fleetwood (1979 Down)">
                          Cadillac Fleetwood (1979 Down)
                        </option>
                        <option value="Cadillac Fleetwood (1980 Up)">
                          Cadillac Fleetwood (1980 Up)
                        </option>
                        <option value="Cadillac LYRIQ">Cadillac LYRIQ</option>
                        <option value="Cadillac Seville (incl STS)">
                          Cadillac Seville (incl STS)
                        </option>
                        <option value="Cadillac SRX">Cadillac SRX</option>
                        <option value="Cadillac STS">Cadillac STS</option>
                        <option value="Cadillac XLR">Cadillac XLR</option>
                        <option value="Cadillac XT4">Cadillac XT4</option>
                        <option value="Cadillac XT5">Cadillac XT5</option>
                        <option value="Cadillac XT6">Cadillac XT6</option>
                        <option value="Cadillac XTS">Cadillac XTS</option>
                        <option value="Cadillac Other">Cadillac Other</option>
                        <option value="Checker">Checker</option>
                        <option value="Checker Cab">Checker Cab</option>
                        <option value="Chevy Astra">Chevy Astra</option>
                        <option value="Chevy Astro">Chevy Astro</option>
                        <option value="Chevy Aveo">Chevy Aveo</option>
                        <option value="Chevy Beretta">Chevy Beretta</option>
                        <option value="Chevy Blazer, Full Size (1994 Down)">
                          Chevy Blazer, Full Size (1994 Down)
                        </option>
                        <option value="Chevy Blazer (2019 Up)">
                          Chevy Blazer (2019 Up)
                        </option>
                        <option value="Chevy Blazer EV">Chevy Blazer EV</option>
                        <option value="Chevy Blazer, S10/S15">
                          Chevy Blazer, S10/S15
                        </option>
                        <option value="Chevy Bolt">Chevy Bolt</option>
                        <option value="Chevy Bolt EUV">Chevy Bolt EUV</option>
                        <option value="Chevy C2">Chevy C2</option>
                        <option value="Chevy Camaro">Chevy Camaro</option>
                        <option value="Chevy Caprice (1979 Down)">
                          Chevy Caprice (1979 Down)
                        </option>
                        <option value="Chevy Caprice (1980 Up)">
                          Chevy Caprice (1980 Up)
                        </option>
                        <option value="Chevy Captiva Sport">
                          Chevy Captiva Sport
                        </option>
                        <option value="Chevy Cavalier">Chevy Cavalier</option>
                        <option value="Chevy Celebrity">Chevy Celebrity</option>
                        <option value="Chevy Chevelle">Chevy Chevelle</option>
                        <option value="Chevy Chevette">Chevy Chevette</option>
                        <option value="Chevy Chevy Pickup FWD (Non US Mkt)">
                          Chevy Chevy Pickup FWD (Non US Mkt)
                        </option>
                        <option value="Chevy Chevy Small Car (Non US Mkt)">
                          Chevy Chevy Small Car (Non US Mkt)
                        </option>
                        <option value="Chevy Citation">Chevy Citation</option>
                        <option value="Chevy City Express">
                          Chevy City Express
                        </option>
                        <option value="Chevy Cobalt">Chevy Cobalt</option>
                        <option value="Chevy Corsa">Chevy Corsa</option>
                        <option value="Chevy Corsica">Chevy Corsica</option>
                        <option value="Chevy Corvair">Chevy Corvair</option>
                        <option value="Chevy Corvette">Chevy Corvette</option>
                        <option value="Chevy Cruze">Chevy Cruze</option>
                        <option value="Chevy El Camino (1963 Down)">
                          Chevy El Camino (1963 Down)
                        </option>
                        <option value="Chevy El Camino (1964-1977)">
                          Chevy El Camino (1964-1977)
                        </option>
                        <option value="Chevy El Camino (1978 Up)">
                          Chevy El Camino (1978 Up)
                        </option>
                        <option value="Chevy Epica">Chevy Epica</option>
                        <option value="Chevy Equinox">Chevy Equinox</option>
                        <option value="Chevy HHR">Chevy HHR</option>
                        <option value="Chevy Impala (1979 Down)">
                          Chevy Impala (1979 Down)
                        </option>
                        <option value="Chevy Impala (1980 Up)">
                          Chevy Impala (1980 Up)
                        </option>
                        <option value="Chevy Lumina Car">
                          Chevy Lumina Car
                        </option>
                        <option value="Chevy Lumina Van">
                          Chevy Lumina Van
                        </option>
                        <option value="Chevy Luv (See Also Isuzu Mini P-Up)">
                          Chevy Luv (See Also Isuzu Mini P-Up)
                        </option>
                        <option value="Chevy Malibu">Chevy Malibu</option>
                        <option value="Chevy Meriva">Chevy Meriva</option>
                        <option value="Chevy Metro">Chevy Metro</option>
                        <option value="Chevy Monte Carlo">
                          Chevy Monte Carlo
                        </option>
                        <option value="Chevy Monza">Chevy Monza</option>
                        <option value="Chevy Nova &amp; Chevy II (1967 Down)">
                          Chevy Nova &amp; Chevy II (1967 Down)
                        </option>
                        <option value="Chevy Nova (1968 Up)">
                          Chevy Nova (1968 Up)
                        </option>
                        <option value="Chevy Optra">Chevy Optra</option>
                        <option value="Chevy Orlando">Chevy Orlando</option>
                        <option value="Chevy Prizm">Chevy Prizm</option>
                        <option value="Chevy S10/S15/Sonoma">
                          Chevy S10/S15/Sonoma
                        </option>
                        <option value="Chevy Sonic">Chevy Sonic</option>
                        <option value="Chevy Spark">Chevy Spark</option>
                        <option value="Chevy Spectrum">Chevy Spectrum</option>
                        <option value="Chevy Sprint">Chevy Sprint</option>
                        <option value="Chevy SS">Chevy SS</option>
                        <option value="Chevy SSR">Chevy SSR</option>
                        <option value="Chevy Suburban-10 (1988 Down)">
                          Chevy Suburban-10 (1988 Down)
                        </option>
                        <option value="Chevy Suburban-20 (1988 Down)">
                          Chevy Suburban-20 (1988 Down)
                        </option>
                        <option value="Chevy Suburban-30 (1966 Down)">
                          Chevy Suburban-30 (1966 Down)
                        </option>
                        <option value="Chevy Suburban-1000 (1963-1966)">
                          Chevy Suburban-1000 (1963-1966)
                        </option>
                        <option value="Chevy Suburban-1500">
                          Chevy Suburban-1500
                        </option>
                        <option value="Chevy Suburban-2500 (1967 Up)">
                          Chevy Suburban-2500 (1967 Up)
                        </option>
                        <option value="Chevy Suburban-3500">
                          Chevy Suburban-3500
                        </option>
                        <option value="Chevy Tahoe">Chevy Tahoe</option>
                        <option value="Chevy Tigra">Chevy Tigra</option>
                        <option value="Chevy Tornado">Chevy Tornado</option>
                        <option value="Chevy Tracker">Chevy Tracker</option>
                        <option value="Chevy TrailBlazer">
                          Chevy TrailBlazer
                        </option>
                        <option value="Chevy TrailBlazer-EXT">
                          Chevy TrailBlazer-EXT
                        </option>
                        <option value="Chevy Traverse">Chevy Traverse</option>
                        <option value="Chevy Trax">Chevy Trax</option>
                        <option value="Chevy Truck-10 Series (1987 Down)">
                          Chevy Truck-10 Series (1987 Down)
                        </option>
                        <option value="Chevy Truck-20 Series (1988 Down)">
                          Chevy Truck-20 Series (1988 Down)
                        </option>
                        <option value="Chevy Truck-30 Series (1988 Down)">
                          Chevy Truck-30 Series (1988 Down)
                        </option>
                        <option value="Chevy Truck-1500 Series (1988-1999)">
                          Chevy Truck-1500 Series (1988-1999)
                        </option>
                        <option value="Chevy Truck-2500 Series (1988-2000)">
                          Chevy Truck-2500 Series (1988-2000)
                        </option>
                        <option value="Chevy Truck-3500 Series (1988-2001)">
                          Chevy Truck-3500 Series (1988-2001)
                        </option>
                        <option value="Chevy Truck-Avalanche 1500">
                          Chevy Truck-Avalanche 1500
                        </option>
                        <option value="Chevy Truck-Avalanche 2500">
                          Chevy Truck-Avalanche 2500
                        </option>
                        <option value="Chevy Truck-C3100">
                          Chevy Truck-C3100
                        </option>
                        <option value="Chevy Truck-C3600">
                          Chevy Truck-C3600
                        </option>
                        <option value="Chevy Truck-C3800">
                          Chevy Truck-C3800
                        </option>
                        <option value="Chevy Truck-Colorado">
                          Chevy Truck-Colorado
                        </option>
                        <option value="Chevy Truck-Forward Control">
                          Chevy Truck-Forward Control
                        </option>
                        <option value="Chevy Truck-Kodiak">
                          Chevy Truck-Kodiak
                        </option>
                        <option value="Chevy Truck-Luv Mini Pup">
                          Chevy Truck-Luv Mini Pup
                        </option>
                        <option value="Chevy Truck-S10/S15/Sonoma">
                          Chevy Truck-S10/S15/Sonoma
                        </option>
                        <option value="Chevy Truck-Silverado 1500 (1999 Up)">
                          Chevy Truck-Silverado 1500 (1999 Up)
                        </option>
                        <option value="Chevy Truck-Silverado 2500 (1999 Up)">
                          Chevy Truck-Silverado 2500 (1999 Up)
                        </option>
                        <option value="Chevy Truck-Silverado 3500 (2001 Up)">
                          Chevy Truck-Silverado 3500 (2001 Up)
                        </option>
                        <option value="Chevy Truck-Tilt Cab">
                          Chevy Truck-Tilt Cab
                        </option>
                        <option value="Chevy Uplander">Chevy Uplander</option>
                        <option value="Chevy Van 10">Chevy Van 10</option>
                        <option value="Chevy Van 20">Chevy Van 20</option>
                        <option value="Chevy Van 30">Chevy Van 30</option>
                        <option value="Chevy Van Express 1500">
                          Chevy Van Express 1500
                        </option>
                        <option value="Chevy Van Express 2500">
                          Chevy Van Express 2500
                        </option>
                        <option value="Chevy Van Express 3500">
                          Chevy Van Express 3500
                        </option>
                        <option value="Chevy Vectra">Chevy Vectra</option>
                        <option value="Chevy Vega">Chevy Vega</option>
                        <option value="Chevy Venture">Chevy Venture</option>
                        <option value="Chevy Volt">Chevy Volt</option>
                        <option value="Chevy Zafira">Chevy Zafira</option>
                        <option value="Chevy Other">Chevy Other</option>
                        <option value="Chrysler 200">Chrysler 200</option>
                        <option value="Chrysler 300">Chrysler 300</option>
                        <option value="Chrysler 300M">Chrysler 300M</option>
                        <option value="Chrysler Aspen">Chrysler Aspen</option>
                        <option value="Chrysler Atos">Chrysler Atos</option>
                        <option value="Chrysler Attitude">
                          Chrysler Attitude
                        </option>
                        <option value="Chrysler Cirrus">Chrysler Cirrus</option>
                        <option value="Chrysler Concorde">
                          Chrysler Concorde
                        </option>
                        <option value="Chrysler Conquest">
                          Chrysler Conquest
                        </option>
                        <option value="Chrysler Cordoba">
                          Chrysler Cordoba
                        </option>
                        <option value="Chrysler Crossfire">
                          Chrysler Crossfire
                        </option>
                        <option value="Chrysler E Class">
                          Chrysler E Class
                        </option>
                        <option value="Chrysler Fifth Avenue - FWD">
                          Chrysler Fifth Avenue - FWD
                        </option>
                        <option value="Chrysler Fifth Avenue - RWD (1979 Up)">
                          Chrysler Fifth Avenue - RWD (1979 Up)
                        </option>
                        <option value="Chrysler Imperial">
                          Chrysler Imperial
                        </option>
                        <option value="Chrysler LHS">Chrysler LHS</option>
                        <option value="Chrysler Laser">Chrysler Laser</option>
                        <option value="Chrysler Lebaron">
                          Chrysler Lebaron
                        </option>
                        <option value="Chrysler New Yorker - FWD">
                          Chrysler New Yorker - FWD
                        </option>
                        <option value="Chrysler New Yorker - RWD">
                          Chrysler New Yorker - RWD
                        </option>
                        <option value="Chrysler Newport">
                          Chrysler Newport
                        </option>
                        <option value="Chrysler Pacifica">
                          Chrysler Pacifica
                        </option>
                        <option value="Chrysler Prowler">
                          Chrysler Prowler
                        </option>
                        <option value="Chrysler PT Cruiser">
                          Chrysler PT Cruiser
                        </option>
                        <option value="Chrysler Sebring">
                          Chrysler Sebring
                        </option>
                        <option value="Chrysler TC">Chrysler TC</option>
                        <option value="Chrysler Town and Country">
                          Chrysler Town and Country
                        </option>
                        <option value="Chrysler Voyager">
                          Chrysler Voyager
                        </option>
                        <option value="Chrysler Other">Chrysler Other</option>
                        <option value="Citroen">Citroen</option>
                        <option value="Daewoo Lanos">Daewoo Lanos</option>
                        <option value="Daewoo Leganza">Daewoo Leganza</option>
                        <option value="Daewoo Nubira">Daewoo Nubira</option>
                        <option value="Daihatsu Charade">
                          Daihatsu Charade
                        </option>
                        <option value="Daihatsu Hijet">Daihatsu Hijet</option>
                        <option value="Daihatsu Rocky">Daihatsu Rocky</option>
                        <option value="Delorean">Delorean</option>
                        <option value="Desoto">Desoto</option>
                        <option value="Diamond Reo">Diamond Reo</option>
                        <option value="Dodge 400">Dodge 400</option>
                        <option value="Dodge 600">Dodge 600</option>
                        <option value="Dodge Aries">Dodge Aries</option>
                        <option value="Dodge Aspen">Dodge Aspen</option>
                        <option value="Dodge Avenger">Dodge Avenger</option>
                        <option value="Dodge Caliber">Dodge Caliber</option>
                        <option value="Dodge Caravan">Dodge Caravan</option>
                        <option value="Dodge Challenger (Chrysler)">
                          Dodge Challenger (Chrysler)
                        </option>
                        <option value="Dodge Challenger (Mitsubishi)">
                          Dodge Challenger (Mitsubishi)
                        </option>
                        <option value="Dodge Charger">Dodge Charger</option>
                        <option value="Dodge Colt-not Vista">
                          Dodge Colt-not Vista
                        </option>
                        <option value="Dodge Colt Vista">
                          Dodge Colt Vista
                        </option>
                        <option value="Dodge Cricket">Dodge Cricket</option>
                        <option value="Dodge D50/Ram 50 (See also Plymouth Arrow Truck)">
                          Dodge D50/Ram 50 (See also Plymouth Arrow Truck)
                        </option>
                        <option value="Dodge Dakota">Dodge Dakota</option>
                        <option value="Dodge Dart">Dodge Dart</option>
                        <option value="Dodge Daytona">Dodge Daytona</option>
                        <option value="Dodge Diplomat">Dodge Diplomat</option>
                        <option value="Dodge Durango">Dodge Durango</option>
                        <option value="Dodge Dynasty">Dodge Dynasty</option>
                        <option value="Dodge Hornet">Dodge Hornet</option>
                        <option value="Dodge Intrepid">Dodge Intrepid</option>
                        <option value="Dodge Journey">Dodge Journey</option>
                        <option value="Dodge Lancer">Dodge Lancer</option>
                        <option value="Dodge Magnum">Dodge Magnum</option>
                        <option value="Dodge Mirada">Dodge Mirada</option>
                        <option value="Dodge Monaco (1978 Down)">
                          Dodge Monaco (1978 Down)
                        </option>
                        <option value="Dodge Monaco (1990 Up)">
                          Dodge Monaco (1990 Up)
                        </option>
                        <option value="Dodge Neon">Dodge Neon</option>
                        <option value="Dodge Nitro">Dodge Nitro</option>
                        <option value="Dodge Omni">Dodge Omni</option>
                        <option value="Dodge Raider">Dodge Raider</option>
                        <option value="Dodge Ramcharger">
                          Dodge Ramcharger
                        </option>
                        <option value="Dodge Rampage">Dodge Rampage</option>
                        <option value="Dodge Shadow">Dodge Shadow</option>
                        <option value="Dodge Spirit">Dodge Spirit</option>
                        <option value="Dodge St Regis">Dodge St Regis</option>
                        <option value="Dodge Stealth">Dodge Stealth</option>
                        <option value="Dodge Stratus">Dodge Stratus</option>
                        <option value="Dodge Truck-100 Series (1989 Down)">
                          Dodge Truck-100 Series (1989 Down)
                        </option>
                        <option value="Dodge Truck-200 Series (1980 Down)">
                          Dodge Truck-200 Series (1980 Down)
                        </option>
                        <option value="Dodge Truck-300 Series (1980 Down)">
                          Dodge Truck-300 Series (1980 Down)
                        </option>
                        <option value="Dodge Truck-400 Series">
                          Dodge Truck-400 Series
                        </option>
                        <option value="Dodge Truck-150 (1978-1993)">
                          Dodge Truck-150 (1978-1993)
                        </option>
                        <option value="Dodge Truck-250 Series (1981-1993)">
                          Dodge Truck-250 Series (1981-1993)
                        </option>
                        <option value="Dodge Truck-350 Series (1981-1993)">
                          Dodge Truck-350 Series (1981-1993)
                        </option>
                        <option value="Dodge Truck-450 Series">
                          Dodge Truck-450 Series
                        </option>
                        <option value="Dodge Truck-1500 (1994 Up)">
                          Dodge Truck-1500 (1994 Up)
                        </option>
                        <option value="Dodge Truck-2500 Series (1994 Up)">
                          Dodge Truck-2500 Series (1994 Up)
                        </option>
                        <option value="Dodge Truck-3500 (1994 Up)">
                          Dodge Truck-3500 (1994 Up)
                        </option>
                        <option value="Dodge Truck-4500 Series">
                          Dodge Truck-4500 Series
                        </option>
                        <option value="Dodge Truck-5500 Series">
                          Dodge Truck-5500 Series
                        </option>
                        <option value="Dodge Truck-D50/Ram 50">
                          Dodge Truck-D50/Ram 50
                        </option>
                        <option value="Dodge Truck-Dakota">
                          Dodge Truck-Dakota
                        </option>
                        <option value="Dodge Truck-Forward Control">
                          Dodge Truck-Forward Control
                        </option>
                        <option value="Dodge Truck-Rampage">
                          Dodge Truck-Rampage
                        </option>
                        <option value="Dodge Van 100">Dodge Van 100</option>
                        <option value="Dodge Van 150">Dodge Van 150</option>
                        <option value="Dodge Van 200">Dodge Van 200</option>
                        <option value="Dodge Van 250">Dodge Van 250</option>
                        <option value="Dodge Van 300">Dodge Van 300</option>
                        <option value="Dodge Van 350">Dodge Van 350</option>
                        <option value="Dodge Van 1500">Dodge Van 1500</option>
                        <option value="Dodge Van 2500">Dodge Van 2500</option>
                        <option value="Dodge Van 3500">Dodge Van 3500</option>
                        <option value="Dodge Van (Sprinter 2500)">
                          Dodge Van (Sprinter 2500)
                        </option>
                        <option value="Dodge Van (Sprinter 3500)">
                          Dodge Van (Sprinter 3500)
                        </option>
                        <option value="Dodge Verna">Dodge Verna</option>
                        <option value="Dodge Viper">Dodge Viper</option>
                        <option value="Dodge Other">Dodge Other</option>
                        <option value="Eagle 2000 GTX">Eagle 2000 GTX</option>
                        <option value="Eagle Premier">Eagle Premier</option>
                        <option value="Eagle Summit">Eagle Summit</option>
                        <option value="Eagle Talon">Eagle Talon</option>
                        <option value="Eagle Vision">Eagle Vision</option>
                        <option value="Edsel">Edsel</option>
                        <option value="FWD Trucks">FWD Trucks</option>
                        <option value="Ferrari">Ferrari</option>
                        <option value="Fiat 1100R">Fiat 1100R</option>
                        <option value="Fiat 124 (1983 Down, includes Spider)">
                          Fiat 124 (1983 Down, includes Spider)
                        </option>
                        <option value="Fiat 124 Spider (2016 Up)">
                          Fiat 124 Spider (2016 Up)
                        </option>
                        <option value="Fiat 128">Fiat 128</option>
                        <option value="Fiat 131/Brava">Fiat 131/Brava</option>
                        <option value="Fiat 500">Fiat 500</option>
                        <option value="Fiat 600">Fiat 600</option>
                        <option value="Fiat 850">Fiat 850</option>
                        <option value="Fiat Spider (includes 2000)">
                          Fiat Spider (includes 2000)
                        </option>
                        <option value="Fiat Strada">Fiat Strada</option>
                        <option value="Fiat X 1/9">Fiat X 1/9</option>
                        <option value="Fiat Other">Fiat Other</option>
                        <option value="Fisker Karma">Fisker Karma</option>
                        <option value="Fisker Ocean">Fisker Ocean</option>
                        <option value="Ford 500">Ford 500</option>
                        <option value="Ford Aerostar">Ford Aerostar</option>
                        <option value="Ford Aspire">Ford Aspire</option>
                        <option value="Ford Bronco (Full Size)">
                          Ford Bronco (Full Size)
                        </option>
                        <option value="Ford Bronco II">Ford Bronco II</option>
                        <option value="Ford Bronco Raptor">
                          Ford Bronco Raptor
                        </option>
                        <option value="Ford Bronco Sport">
                          Ford Bronco Sport
                        </option>
                        <option value="Ford C-Max">Ford C-Max</option>
                        <option value="Ford Contour">Ford Contour</option>
                        <option value="Ford Cortina">Ford Cortina</option>
                        <option value="Ford Courier">Ford Courier</option>
                        <option value="Ford Crown Vic (1982 Down)">
                          Ford Crown Vic (1982 Down)
                        </option>
                        <option value="Ford Crown Vic (1983 Up)">
                          Ford Crown Vic (1983 Up)
                        </option>
                        <option value="Ford E Transit">Ford E Transit</option>
                        <option value="Ford Ecosport">Ford Ecosport</option>
                        <option value="Ford Edge">Ford Edge</option>
                        <option value="Ford Escape">Ford Escape</option>
                        <option value="Ford Escort">Ford Escort</option>
                        <option value="Ford Excursion">Ford Excursion</option>
                        <option value="Ford EXP">Ford EXP</option>
                        <option value="Ford Expedition">Ford Expedition</option>
                        <option value="Ford Explorer">Ford Explorer</option>
                        <option value="Ford Fairlane">Ford Fairlane</option>
                        <option value="Ford Fairmont">Ford Fairmont</option>
                        <option value="Ford Falcon">Ford Falcon</option>
                        <option value="Ford Festiva">Ford Festiva</option>
                        <option value="Ford Fiesta">Ford Fiesta</option>
                        <option value="Ford Five Hundred">
                          Ford Five Hundred
                        </option>
                        <option value="Ford Flex">Ford Flex</option>
                        <option value="Ford Focus">Ford Focus</option>
                        <option value="Ford Focus RS">Ford Focus RS</option>
                        <option value="Ford Freestar">Ford Freestar</option>
                        <option value="Ford Freestyle">Ford Freestyle</option>
                        <option value="Ford Fusion">Ford Fusion</option>
                        <option value="Ford Galaxie">Ford Galaxie</option>
                        <option value="Ford Granada">Ford Granada</option>
                        <option value="Ford GT">Ford GT</option>
                        <option value="Ford Ikon">Ford Ikon</option>
                        <option value="Ford KA">Ford KA</option>
                        <option value="Ford LTD (1978 Down)">
                          Ford LTD (1978 Down)
                        </option>
                        <option value="Ford LTD (1979 Up)">
                          Ford LTD (1979 Up)
                        </option>
                        <option value="Ford LTD II">Ford LTD II</option>
                        <option value="Ford Maverick">Ford Maverick</option>
                        <option value="Ford Maverick Pickup">
                          Ford Maverick Pickup
                        </option>
                        <option value="Ford Mondeo">Ford Mondeo</option>
                        <option value="Ford Mustang">Ford Mustang</option>
                        <option value="Ford Mustang Mach E">
                          Ford Mustang Mach E
                        </option>
                        <option value="Ford Pinto">Ford Pinto</option>
                        <option value="Ford Probe">Ford Probe</option>
                        <option value="Ford Ranchero (1967-1970)">
                          Ford Ranchero (1967-1970)
                        </option>
                        <option value="Ford Ranchero (1971-1976)">
                          Ford Ranchero (1971-1976)
                        </option>
                        <option value="Ford Ranchero (1977 up)">
                          Ford Ranchero (1977 up)
                        </option>
                        <option value="Ford Ranchero (1957-1959)">
                          Ford Ranchero (1957-1959)
                        </option>
                        <option value="Ford Ranchero (1960-1966)">
                          Ford Ranchero (1960-1966)
                        </option>
                        <option value="Ford Ranger">Ford Ranger</option>
                        <option value="Ford Ranger Raptor">
                          Ford Ranger Raptor
                        </option>
                        <option value="Ford Taurus">Ford Taurus</option>
                        <option value="Ford Taurus X">Ford Taurus X</option>
                        <option value="Ford Tempo">Ford Tempo</option>
                        <option value="Ford ThinkCity-Electric">
                          Ford ThinkCity-Electric
                        </option>
                        <option value="Ford Thunderbird">
                          Ford Thunderbird
                        </option>
                        <option value="Ford Torino">Ford Torino</option>
                        <option value="Ford Transit 150">
                          Ford Transit 150
                        </option>
                        <option value="Ford Transit 250">
                          Ford Transit 250
                        </option>
                        <option value="Ford Transit 350">
                          Ford Transit 350
                        </option>
                        <option value="Ford Transit Connect">
                          Ford Transit Connect
                        </option>
                        <option value="Ford Truck-Courier">
                          Ford Truck-Courier
                        </option>
                        <option value="Ford Truck-F100">Ford Truck-F100</option>
                        <option value="Ford Truck-F150">Ford Truck-F150</option>
                        <option value="Ford Truck F150 Lightning (Electric)">
                          Ford Truck F150 Lightning (Electric)
                        </option>
                        <option value="Ford Truck-F150 Lightning (SVT Gas)">
                          Ford Truck-F150 Lightning (SVT Gas)
                        </option>
                        <option value="Ford Truck-F150 Raptor">
                          Ford Truck-F150 Raptor
                        </option>
                        <option value="Ford Truck-F250 not Super Duty (1999 Down)">
                          Ford Truck-F250 not Super Duty (1999 Down)
                        </option>
                        <option value="Ford Truck-F250 Super Duty (1999 Up)">
                          Ford Truck-F250 Super Duty (1999 Up)
                        </option>
                        <option value="Ford Truck-F350 not Super Duty (1997 Down)">
                          Ford Truck-F350 not Super Duty (1997 Down)
                        </option>
                        <option value="Ford Truck-F350 Super Duty (1999 Up)">
                          Ford Truck-F350 Super Duty (1999 Up)
                        </option>
                        <option value="Ford Truck-F450 not Super Duty (1997 Down)">
                          Ford Truck-F450 not Super Duty (1997 Down)
                        </option>
                        <option value="Ford Truck-F450 Super Duty (1999 Up)">
                          Ford Truck-F450 Super Duty (1999 Up)
                        </option>
                        <option value="Ford Truck-F550 Super Duty (1999 Up)">
                          Ford Truck-F550 Super Duty (1999 Up)
                        </option>
                        <option value="Ford Truck-Forward Control">
                          Ford Truck-Forward Control
                        </option>
                        <option value="Ford Truck-Ranger">
                          Ford Truck-Ranger
                        </option>
                        <option value="Ford Van E100">Ford Van E100</option>
                        <option value="Ford Van E150">Ford Van E150</option>
                        <option value="Ford Van E200">Ford Van E200</option>
                        <option value="Ford Van E250">Ford Van E250</option>
                        <option value="Ford Van E300">Ford Van E300</option>
                        <option value="Ford Van E350">Ford Van E350</option>
                        <option value="Ford Van E450 Super Duty">
                          Ford Van E450 Super Duty
                        </option>
                        <option value="Ford Van E550 Super Duty">
                          Ford Van E550 Super Duty
                        </option>
                        <option value="Ford Windstar">Ford Windstar</option>
                        <option value="Ford Other">Ford Other</option>
                        <option value="Freightliner">Freightliner</option>
                        <option value="Freuhauf">Freuhauf</option>
                        <option value="GMC Acadia">GMC Acadia</option>
                        <option value="GMC Hummer EV">GMC Hummer EV</option>
                        <option value="GMC Jimmy, Full Size">
                          GMC Jimmy, Full Size
                        </option>
                        <option value="GMC Jimmy, S10/S15">
                          GMC Jimmy, S10/S15
                        </option>
                        <option value="GMC Safari Van">GMC Safari Van</option>
                        <option value="GMC Sprint">GMC Sprint</option>
                        <option value="GMC Suburban-10 (1988 Down)">
                          GMC Suburban-10 (1988 Down)
                        </option>
                        <option value="GMC Suburban-20 (1988 Down)">
                          GMC Suburban-20 (1988 Down)
                        </option>
                        <option value="GMC Suburban-30 (1965-1966)">
                          GMC Suburban-30 (1965-1966)
                        </option>
                        <option value="GMC Suburban-1000 (1965-1966)">
                          GMC Suburban-1000 (1965-1966)
                        </option>
                        <option value="GMC Suburban-1500 (2001 Down)">
                          GMC Suburban-1500 (2001 Down)
                        </option>
                        <option value="GMC Suburban-2500 (1967 Up)">
                          GMC Suburban-2500 (1967 Up)
                        </option>
                        <option value="GMC Syclone">GMC Syclone</option>
                        <option value="GMC Terrain">GMC Terrain</option>
                        <option value="GMC Truck-1000 Series (1966 Down)">
                          GMC Truck-1000 Series (1966 Down)
                        </option>
                        <option value="GMC Truck-1500 Series (1999 Down)">
                          GMC Truck-1500 Series (1999 Down)
                        </option>
                        <option value="GMC Truck-2500 Series (2000 Down)">
                          GMC Truck-2500 Series (2000 Down)
                        </option>
                        <option value="GMC Truck-3500 Series (2001 Down)">
                          GMC Truck-3500 Series (2001 Down)
                        </option>
                        <option value="GMC Truck-Canyon">
                          GMC Truck-Canyon
                        </option>
                        <option value="GMC Truck-Envoy">GMC Truck-Envoy</option>
                        <option value="GMC Truck-Envoy XL">
                          GMC Truck-Envoy XL
                        </option>
                        <option value="GMC Truck-Envoy XUV">
                          GMC Truck-Envoy XUV
                        </option>
                        <option value="GMC Truck-Forward Control">
                          GMC Truck-Forward Control
                        </option>
                        <option value="GMC Truck-S10/S15/Sonoma">
                          GMC Truck-S10/S15/Sonoma
                        </option>
                        <option value="GMC Truck-Sierra 1500 (1999 Up)">
                          GMC Truck-Sierra 1500 (1999 Up)
                        </option>
                        <option value="GMC Truck-Sierra 2500 (1999 Up)">
                          GMC Truck-Sierra 2500 (1999 Up)
                        </option>
                        <option value="GMC Truck-Sierra 3500 (2001 Up)">
                          GMC Truck-Sierra 3500 (2001 Up)
                        </option>
                        <option value="GMC Truck-Sierra Denali">
                          GMC Truck-Sierra Denali
                        </option>
                        <option value="GMC Truck-Sierra Denali 1500 (2011 Up)">
                          GMC Truck-Sierra Denali 1500 (2011 Up)
                        </option>
                        <option value="GMC Truck-Sierra Denali 2500 (2011 Up)">
                          GMC Truck-Sierra Denali 2500 (2011 Up)
                        </option>
                        <option value="GMC Truck-Sierra Denali 3500 (2011 Up)">
                          GMC Truck-Sierra Denali 3500 (2011 Up)
                        </option>
                        <option value="GMC Truck-Topkick">
                          GMC Truck-Topkick
                        </option>
                        <option value="GMC Truck-Yukon (except XL)">
                          GMC Truck-Yukon (except XL)
                        </option>
                        <option value="GMC Truck-Yukon XL1500">
                          GMC Truck-Yukon XL1500
                        </option>
                        <option value="GMC Truck-Yukon XL2500">
                          GMC Truck-Yukon XL2500
                        </option>
                        <option value="GMC Typhoon">GMC Typhoon</option>
                        <option value="GMC Van 1000">GMC Van 1000</option>
                        <option value="GMC Van 1500">GMC Van 1500</option>
                        <option value="GMC Van 2500">GMC Van 2500</option>
                        <option value="GMC Van 3500">GMC Van 3500</option>
                        <option value="GMC Van Savana 1500">
                          GMC Van Savana 1500
                        </option>
                        <option value="GMC Van Savana 2500">
                          GMC Van Savana 2500
                        </option>
                        <option value="GMC Van Savana 3500">
                          GMC Van Savana 3500
                        </option>
                        <option value="Genesis G70">Genesis G70</option>
                        <option value="Genesis G80">Genesis G80</option>
                        <option value="Genesis G90">Genesis G90</option>
                        <option value="Genesis GV60">Genesis GV60</option>
                        <option value="Genesis GV70">Genesis GV70</option>
                        <option value="Genesis GV80">Genesis GV80</option>
                        <option value="Geo Metro">Geo Metro</option>
                        <option value="Geo Prizm">Geo Prizm</option>
                        <option value="Geo Spectrum">Geo Spectrum</option>
                        <option value="Geo Storm">Geo Storm</option>
                        <option value="Geo Tracker">Geo Tracker</option>
                        <option value="Hino Truck">Hino Truck</option>
                        <option value="Honda 600">Honda 600</option>
                        <option value="Honda Accord">Honda Accord</option>
                        <option value="Honda Acty">Honda Acty</option>
                        <option value="Honda Civic">Honda Civic</option>
                        <option value="Honda Clarity">Honda Clarity</option>
                        <option value="Honda Clarity Electric">
                          Honda Clarity Electric
                        </option>
                        <option value="Honda Clarity Fuel Cell">
                          Honda Clarity Fuel Cell
                        </option>
                        <option value="Honda Crosstour">Honda Crosstour</option>
                        <option value="Honda CRV">Honda CRV</option>
                        <option value="Honda CRX">Honda CRX</option>
                        <option value="Honda CRZ">Honda CRZ</option>
                        <option value="Honda DelSol">Honda DelSol</option>
                        <option value="Honda Element">Honda Element</option>
                        <option value="Honda FCX">Honda FCX</option>
                        <option value="Honda Fit">Honda Fit</option>
                        <option value="Honda HRV">Honda HRV</option>
                        <option value="Honda Insight">Honda Insight</option>
                        <option value="Honda Odyssey">Honda Odyssey</option>
                        <option value="Honda Passport">Honda Passport</option>
                        <option value="Honda Pilot">Honda Pilot</option>
                        <option value="Honda Prelude">Honda Prelude</option>
                        <option value="Honda Prologue">Honda Prologue</option>
                        <option value="Honda Ridgeline">Honda Ridgeline</option>
                        <option value="Honda S2000">Honda S2000</option>
                        <option value="Hudson">Hudson</option>
                        <option value="Hummer">Hummer</option>
                        <option value="Hummer H1">Hummer H1</option>
                        <option value="Hummer H2">Hummer H2</option>
                        <option value="Hummer H3">Hummer H3</option>
                        <option value="Hyundai Accent">Hyundai Accent</option>
                        <option value="Hyundai Azera">Hyundai Azera</option>
                        <option value="Hyundai Elantra">Hyundai Elantra</option>
                        <option value="Hyundai Entourage">
                          Hyundai Entourage
                        </option>
                        <option value="Hyundai Equus">Hyundai Equus</option>
                        <option value="Hyundai Excel">Hyundai Excel</option>
                        <option value="Hyundai Genesis">Hyundai Genesis</option>
                        <option value="Hyundai Ioniq">Hyundai Ioniq</option>
                        <option value="Hyundai Ioniq 5">Hyundai Ioniq 5</option>
                        <option value="Hyundai Ioniq 6">Hyundai Ioniq 6</option>
                        <option value="Hyundai Kona">Hyundai Kona</option>
                        <option value="Hyundai Kona Electric">
                          Hyundai Kona Electric
                        </option>
                        <option value="Hyundai Nexo">Hyundai Nexo</option>
                        <option value="Hyundai Palisade">
                          Hyundai Palisade
                        </option>
                        <option value="Hyundai Pony">Hyundai Pony</option>
                        <option value="Hyundai Santa Cruz">
                          Hyundai Santa Cruz
                        </option>
                        <option value="Hyundai Santa Fe">
                          Hyundai Santa Fe
                        </option>
                        <option value="Hyundai Scoupe">Hyundai Scoupe</option>
                        <option value="Hyundai Sonata">Hyundai Sonata</option>
                        <option value="Hyundai Stellar">Hyundai Stellar</option>
                        <option value="Hyundai Tiburon">Hyundai Tiburon</option>
                        <option value="Hyundai Tucson">Hyundai Tucson</option>
                        <option value="Hyundai Veloster">
                          Hyundai Veloster
                        </option>
                        <option value="Hyundai Venue">Hyundai Venue</option>
                        <option value="Hyundai Veracruz">
                          Hyundai Veracruz
                        </option>
                        <option value="Hyundai XG Series">
                          Hyundai XG Series
                        </option>
                        <option value="IH (Pickup &amp; Travelall)">
                          IH (Pickup &amp; Travelall)
                        </option>
                        <option value="IH Scout &amp; Scout II">
                          IH Scout &amp; Scout II
                        </option>
                        <option value="IH Truck (Big)">IH Truck (Big)</option>
                        <option value="Infiniti EX35">Infiniti EX35</option>
                        <option value="Infiniti EX37">Infiniti EX37</option>
                        <option value="Infiniti FX">Infiniti FX</option>
                        <option value="Infiniti G20">Infiniti G20</option>
                        <option value="Infiniti G25">Infiniti G25</option>
                        <option value="Infiniti G35">Infiniti G35</option>
                        <option value="Infiniti G37">Infiniti G37</option>
                        <option value="Infiniti I30">Infiniti I30</option>
                        <option value="Infiniti I35">Infiniti I35</option>
                        <option value="Infiniti J30">Infiniti J30</option>
                        <option value="Infiniti JX35">Infiniti JX35</option>
                        <option value="Infiniti M30">Infiniti M30</option>
                        <option value="Infiniti M35">Infiniti M35</option>
                        <option value="Infiniti M37">Infiniti M37</option>
                        <option value="Infiniti M45">Infiniti M45</option>
                        <option value="Infiniti M56">Infiniti M56</option>
                        <option value="Infiniti Q40">Infiniti Q40</option>
                        <option value="Infiniti Q45">Infiniti Q45</option>
                        <option value="Infiniti Q50">Infiniti Q50</option>
                        <option value="Infiniti Q60">Infiniti Q60</option>
                        <option value="Infiniti Q70">Infiniti Q70</option>
                        <option value="Infiniti QX4">Infiniti QX4</option>
                        <option value="Infiniti QX30">Infiniti QX30</option>
                        <option value="Infiniti QX50">Infiniti QX50</option>
                        <option value="Infiniti QX55">Infiniti QX55</option>
                        <option value="Infiniti QX56">Infiniti QX56</option>
                        <option value="Infiniti QX60">Infiniti QX60</option>
                        <option value="Infiniti QX70">Infiniti QX70</option>
                        <option value="Infiniti QX80">Infiniti QX80</option>
                        <option value="Isuzu Amigo">Isuzu Amigo</option>
                        <option value="Isuzu Ascender">Isuzu Ascender</option>
                        <option value="Isuzu Axiom">Isuzu Axiom</option>
                        <option value="Isuzu Gemini">Isuzu Gemini</option>
                        <option value="Isuzu IMark">Isuzu IMark</option>
                        <option value="Isuzu Impulse">Isuzu Impulse</option>
                        <option value="Isuzu Oasis">Isuzu Oasis</option>
                        <option value="Isuzu Optima">Isuzu Optima</option>
                        <option value="Isuzu Reach">Isuzu Reach</option>
                        <option value="Isuzu Rodeo">Isuzu Rodeo</option>
                        <option value="Isuzu Stylus">Isuzu Stylus</option>
                        <option value="Isuzu Trooper/Trooper II">
                          Isuzu Trooper/Trooper II
                        </option>
                        <option value="Isuzu Truck (Big)">
                          Isuzu Truck (Big)
                        </option>
                        <option value="Isuzu Truck-(Mini Pickup)">
                          Isuzu Truck-(Mini Pickup)
                        </option>
                        <option value="Isuzu Truck-(Mini Pickup) Hombre">
                          Isuzu Truck-(Mini Pickup) Hombre
                        </option>
                        <option value="Isuzu Truck i280 (Pickup)">
                          Isuzu Truck i280 (Pickup)
                        </option>
                        <option value="Isuzu Truck i290 (Pickup)">
                          Isuzu Truck i290 (Pickup)
                        </option>
                        <option value="Isuzu Truck i350 (Pickup)">
                          Isuzu Truck i350 (Pickup)
                        </option>
                        <option value="Isuzu Truck i370 (Pickup)">
                          Isuzu Truck i370 (Pickup)
                        </option>
                        <option value="Isuzu Vehicross">Isuzu Vehicross</option>
                        <option value="Jaguar 120">Jaguar 120</option>
                        <option value="Jaguar 140">Jaguar 140</option>
                        <option value="Jaguar 150">Jaguar 150</option>
                        <option value="Jaguar E Pace">Jaguar E Pace</option>
                        <option value="Jaguar F Pace">Jaguar F Pace</option>
                        <option value="Jaguar F Type">Jaguar F Type</option>
                        <option value="Jaguar I Pace">Jaguar I Pace</option>
                        <option value="Jaguar Mark 10">Jaguar Mark 10</option>
                        <option value="Jaguar S Type">Jaguar S Type</option>
                        <option value="Jaguar Sedan">Jaguar Sedan</option>
                        <option value="Jaguar Vanden Plas (1997 Down)">
                          Jaguar Vanden Plas (1997 Down)
                        </option>
                        <option value="Jaguar Vanden Plas (1998 to 2007)">
                          Jaguar Vanden Plas (1998 to 2007)
                        </option>
                        <option value="Jaguar Vanden Plas (2008 Up)">
                          Jaguar Vanden Plas (2008 Up)
                        </option>
                        <option value="Jaguar X Type">Jaguar X Type</option>
                        <option value="Jaguar XE">Jaguar XE</option>
                        <option value="Jaguar XF">Jaguar XF</option>
                        <option value="Jaguar XF Sportbrake">
                          Jaguar XF Sportbrake
                        </option>
                        <option value="Jaguar XJ Series (2008 Up)">
                          Jaguar XJ Series (2008 Up)
                        </option>
                        <option value="Jaguar XJR (1993)">
                          Jaguar XJR (1993)
                        </option>
                        <option value="Jaguar XJR (1995 to 1997)">
                          Jaguar XJR (1995 to 1997)
                        </option>
                        <option value="Jaguar XJR (1998 to 2007)">
                          Jaguar XJR (1998 to 2007)
                        </option>
                        <option value="Jaguar XJR (2008 Up)">
                          Jaguar XJR (2008 Up)
                        </option>
                        <option value="Jaguar XJS">Jaguar XJS</option>
                        <option value="Jaguar XJ6">Jaguar XJ6</option>
                        <option value="Jaguar XJ8 (2008 Up)">
                          Jaguar XJ8 (2008 Up)
                        </option>
                        <option value="Jaguar XJ8 (2007 Down)">
                          Jaguar XJ8 (2007 Down)
                        </option>
                        <option value="Jaguar XJ12">Jaguar XJ12</option>
                        <option value="Jaguar XK Series (2007 Up)">
                          Jaguar XK Series (2007 Up)
                        </option>
                        <option value="Jaguar XKE">Jaguar XKE</option>
                        <option value="Jaguar XKR (2006 Down)">
                          Jaguar XKR (2006 Down)
                        </option>
                        <option value="Jaguar XKR (2007 Up)">
                          Jaguar XKR (2007 Up)
                        </option>
                        <option value="Jaguar XK8">Jaguar XK8</option>
                        <option value="Jeep Cherokee (except Grand Cherokee)">
                          Jeep Cherokee (except Grand Cherokee)
                        </option>
                        <option value="Jeep CJSeries">Jeep CJSeries</option>
                        <option value="Jeep Comanche">Jeep Comanche</option>
                        <option value="Jeep Commander">Jeep Commander</option>
                        <option value="Jeep Compass">Jeep Compass</option>
                        <option value="Jeep DJ Series">Jeep DJ Series</option>
                        <option value="Jeep FC Series">Jeep FC Series</option>
                        <option value="Jeep Gladiator">Jeep Gladiator</option>
                        <option value="Jeep Grand Cherokee">
                          Jeep Grand Cherokee
                        </option>
                        <option value="Jeep Grand Wagoneer">
                          Jeep Grand Wagoneer
                        </option>
                        <option value="Jeep J-Series">Jeep J-Series</option>
                        <option value="Jeep Jeepster">Jeep Jeepster</option>
                        <option value="Jeep Liberty">Jeep Liberty</option>
                        <option value="Jeep Patriot">Jeep Patriot</option>
                        <option value="Jeep Renegade">Jeep Renegade</option>
                        <option value="Jeep Station Wagon">
                          Jeep Station Wagon
                        </option>
                        <option value="Jeep Truck">Jeep Truck</option>
                        <option value="Jeep Wagoneer (except Grand Wagoneer)">
                          Jeep Wagoneer (except Grand Wagoneer)
                        </option>
                        <option value="Jeep Wrangler">Jeep Wrangler</option>
                        <option value="Kaiser">Kaiser</option>
                        <option value="Kenworth">Kenworth</option>
                        <option value="Kia Amanti">Kia Amanti</option>
                        <option value="Kia Besta">Kia Besta</option>
                        <option value="Kia Borrego">Kia Borrego</option>
                        <option value="Kia Cadenza">Kia Cadenza</option>
                        <option value="Kia Carnival">Kia Carnival</option>
                        <option value="Kia EV6">Kia EV6</option>
                        <option value="Kia EV9">Kia EV9</option>
                        <option value="Kia Forte">Kia Forte</option>
                        <option value="Kia K5">Kia K5</option>
                        <option value="Kia K900">Kia K900</option>
                        <option value="Kia Magentis">Kia Magentis</option>
                        <option value="Kia Niro">Kia Niro</option>
                        <option value="Kia Niro EV">Kia Niro EV</option>
                        <option value="Kia Optima">Kia Optima</option>
                        <option value="Kia Rio">Kia Rio</option>
                        <option value="Kia Rondo">Kia Rondo</option>
                        <option value="Kia Sedona">Kia Sedona</option>
                        <option value="Kia Seltos">Kia Seltos</option>
                        <option value="Kia Sephia">Kia Sephia</option>
                        <option value="Kia Sorento">Kia Sorento</option>
                        <option value="Kia Soul">Kia Soul</option>
                        <option value="Kia Spectra">Kia Spectra</option>
                        <option value="Kia Sportage">Kia Sportage</option>
                        <option value="Kia Stinger">Kia Stinger</option>
                        <option value="Kia Telluride">Kia Telluride</option>
                        <option value="Lada">Lada</option>
                        <option value="Lamborghini">Lamborghini</option>
                        <option value="Lancia">Lancia</option>
                        <option value="LandRover Defender (1997 Down)">
                          LandRover Defender (1997 Down)
                        </option>
                        <option value="LandRover Defender (2020 Up)">
                          LandRover Defender (2020 Up)
                        </option>
                        <option value="LandRover Discovery (2004 Down)">
                          LandRover Discovery (2004 Down)
                        </option>
                        <option value="LandRover Discovery (2017 Up)">
                          LandRover Discovery (2017 Up)
                        </option>
                        <option value="LandRover Discovery Sport">
                          LandRover Discovery Sport
                        </option>
                        <option value="LandRover Freelander">
                          LandRover Freelander
                        </option>
                        <option value="LandRover LR2">LandRover LR2</option>
                        <option value="LandRover LR3">LandRover LR3</option>
                        <option value="LandRover LR4">LandRover LR4</option>
                        <option value="LandRover Range Rover">
                          LandRover Range Rover
                        </option>
                        <option value="LandRover Range Rover Evoque">
                          LandRover Range Rover Evoque
                        </option>
                        <option value="LandRover Range Rover Sport">
                          LandRover Range Rover Sport
                        </option>
                        <option value="LandRover Range Rover Velar">
                          LandRover Range Rover Velar
                        </option>
                        <option value="LandRover Other">LandRover Other</option>
                        <option value="Lexus CT 200H">Lexus CT 200H</option>
                        <option value="Lexus ES250">Lexus ES250</option>
                        <option value="Lexus ES300">Lexus ES300</option>
                        <option value="Lexus ES300H">Lexus ES300H</option>
                        <option value="Lexus ES330">Lexus ES330</option>
                        <option value="Lexus ES350">Lexus ES350</option>
                        <option value="Lexus GS200t">Lexus GS200t</option>
                        <option value="Lexus GS300">Lexus GS300</option>
                        <option value="Lexus GS350">Lexus GS350</option>
                        <option value="Lexus GS400">Lexus GS400</option>
                        <option value="Lexus GS430">Lexus GS430</option>
                        <option value="Lexus GS450h">Lexus GS450h</option>
                        <option value="Lexus GS460">Lexus GS460</option>
                        <option value="Lexus GS F">Lexus GS F</option>
                        <option value="Lexus GX460">Lexus GX460</option>
                        <option value="Lexus GX470">Lexus GX470</option>
                        <option value="Lexus GX550">Lexus GX550</option>
                        <option value="Lexus HS250H">Lexus HS250H</option>
                        <option value="Lexus IS200t">Lexus IS200t</option>
                        <option value="Lexus IS250">Lexus IS250</option>
                        <option value="Lexus IS300">Lexus IS300</option>
                        <option value="Lexus IS350">Lexus IS350</option>
                        <option value="Lexus IS500">Lexus IS500</option>
                        <option value="Lexus IS F">Lexus IS F</option>
                        <option value="Lexus LC500">Lexus LC500</option>
                        <option value="Lexus LC500h">Lexus LC500h</option>
                        <option value="Lexus LFA">Lexus LFA</option>
                        <option value="Lexus LS400">Lexus LS400</option>
                        <option value="Lexus LS430">Lexus LS430</option>
                        <option value="Lexus LS460">Lexus LS460</option>
                        <option value="Lexus LS500">Lexus LS500</option>
                        <option value="Lexus LS500h">Lexus LS500h</option>
                        <option value="Lexus LS600HL">Lexus LS600HL</option>
                        <option value="Lexus LX450">Lexus LX450</option>
                        <option value="Lexus LX470">Lexus LX470</option>
                        <option value="Lexus LX570">Lexus LX570</option>
                        <option value="Lexus LX600">Lexus LX600</option>
                        <option value="Lexus NX200t">Lexus NX200t</option>
                        <option value="Lexus NX250">Lexus NX250</option>
                        <option value="Lexus NX300">Lexus NX300</option>
                        <option value="Lexus NX300h">Lexus NX300h</option>
                        <option value="Lexus NX350">Lexus NX350</option>
                        <option value="Lexus NX350H">Lexus NX350H</option>
                        <option value="Lexus NX450h+">Lexus NX450h+</option>
                        <option value="Lexus RC 200t">Lexus RC 200t</option>
                        <option value="Lexus RC 300">Lexus RC 300</option>
                        <option value="Lexus RC 350">Lexus RC 350</option>
                        <option value="Lexus RC F">Lexus RC F</option>
                        <option value="Lexus RX300">Lexus RX300</option>
                        <option value="Lexus RX330">Lexus RX330</option>
                        <option value="Lexus RX350">Lexus RX350</option>
                        <option value="Lexus RX350h">Lexus RX350h</option>
                        <option value="Lexus RX350L">Lexus RX350L</option>
                        <option value="Lexus RX400h">Lexus RX400h</option>
                        <option value="Lexus RX450 Hybrid">
                          Lexus RX450 Hybrid
                        </option>
                        <option value="Lexus RX450 Hybrid L">
                          Lexus RX450 Hybrid L
                        </option>
                        <option value="Lexus RX500h">Lexus RX500h</option>
                        <option value="Lexus RZ450e">Lexus RZ450e</option>
                        <option value="Lexus SC (excl 430)">
                          Lexus SC (excl 430)
                        </option>
                        <option value="Lexus SC430">Lexus SC430</option>
                        <option value="Lexus TX350">Lexus TX350</option>
                        <option value="Lexus TX500h">Lexus TX500h</option>
                        <option value="Lexus TX550h">Lexus TX550h</option>
                        <option value="Lexus UX 200">Lexus UX 200</option>
                        <option value="Lexus UX 250h">Lexus UX 250h</option>
                        <option value="Lincoln Aviator">Lincoln Aviator</option>
                        <option value="Lincoln Blackwood">
                          Lincoln Blackwood
                        </option>
                        <option value="Lincoln Continental">
                          Lincoln Continental
                        </option>
                        <option value="Lincoln Corsair">Lincoln Corsair</option>
                        <option value="Lincoln LS">Lincoln LS</option>
                        <option value="Lincoln Mark LT">Lincoln Mark LT</option>
                        <option value="Lincoln Mark Series">
                          Lincoln Mark Series
                        </option>
                        <option value="Lincoln MKC">Lincoln MKC</option>
                        <option value="Lincoln MKS">Lincoln MKS</option>
                        <option value="Lincoln MKT">Lincoln MKT</option>
                        <option value="Lincoln MKX">Lincoln MKX</option>
                        <option value="Lincoln MKZ">Lincoln MKZ</option>
                        <option value="Lincoln Nautilus">
                          Lincoln Nautilus
                        </option>
                        <option value="Lincoln Navigator">
                          Lincoln Navigator
                        </option>
                        <option value="Lincoln Versailles">
                          Lincoln Versailles
                        </option>
                        <option value="Lincoln Zephyr">Lincoln Zephyr</option>
                        <option value="Lincoln Other (includes Town Car)">
                          Lincoln Other (includes Town Car)
                        </option>
                        <option value="Lotus">Lotus</option>
                        <option value="Lucid Air">Lucid Air</option>
                        <option value="Lucid Gravity">Lucid Gravity</option>
                        <option value="MG MGB">MG MGB</option>
                        <option value="MG Midget">MG Midget</option>
                        <option value="MG Other">MG Other</option>
                        <option value="Mac">Mac</option>
                        <option value="Marmon Truck">Marmon Truck</option>
                        <option value="Maserati">Maserati</option>
                        <option value="Maserati BiTurbo">
                          Maserati BiTurbo
                        </option>
                        <option value="Maserati Ghibli">Maserati Ghibli</option>
                        <option value="Maserati GranTurismo">
                          Maserati GranTurismo
                        </option>
                        <option value="Maserati Grecale">
                          Maserati Grecale
                        </option>
                        <option value="Maserati Grecale GT">
                          Maserati Grecale GT
                        </option>
                        <option value="Maserati Levante">
                          Maserati Levante
                        </option>
                        <option value="Maserati Quattroporte">
                          Maserati Quattroporte
                        </option>
                        <option value="Maybach">Maybach</option>
                        <option value="Mazda 2">Mazda 2</option>
                        <option value="Mazda 3">Mazda 3</option>
                        <option value="Mazda 5">Mazda 5</option>
                        <option value="Mazda 6">Mazda 6</option>
                        <option value="Mazda 323">Mazda 323</option>
                        <option value="Mazda 626">Mazda 626</option>
                        <option value="Mazda 808">Mazda 808</option>
                        <option value="Mazda 929">Mazda 929</option>
                        <option value="Mazda 1200">Mazda 1200</option>
                        <option value="Mazda 1800">Mazda 1800</option>
                        <option value="Mazda Cosmo">Mazda Cosmo</option>
                        <option value="Mazda CX3">Mazda CX3</option>
                        <option value="Mazda CX5">Mazda CX5</option>
                        <option value="Mazda CX7">Mazda CX7</option>
                        <option value="Mazda CX9">Mazda CX9</option>
                        <option value="Mazda CX30">Mazda CX30</option>
                        <option value="Mazda CX50">Mazda CX50</option>
                        <option value="Mazda CX70">Mazda CX70</option>
                        <option value="Mazda CX90">Mazda CX90</option>
                        <option value="Mazda GLC">Mazda GLC</option>
                        <option value="Mazda MPV Van">Mazda MPV Van</option>
                        <option value="Mazda MX3">Mazda MX3</option>
                        <option value="Mazda MX6">Mazda MX6</option>
                        <option value="Mazda MX30">Mazda MX30</option>
                        <option value="Mazda Miata MX5">Mazda Miata MX5</option>
                        <option value="Mazda Millenia">Mazda Millenia</option>
                        <option value="Mazda Navajo">Mazda Navajo</option>
                        <option value="Mazda Pickup-B1600">
                          Mazda Pickup-B1600
                        </option>
                        <option value="Mazda Pickup-B1800">
                          Mazda Pickup-B1800
                        </option>
                        <option value="Mazda Pickup-B2000">
                          Mazda Pickup-B2000
                        </option>
                        <option value="Mazda Pickup-B2200">
                          Mazda Pickup-B2200
                        </option>
                        <option value="Mazda Pickup-B2300">
                          Mazda Pickup-B2300
                        </option>
                        <option value="Mazda Pickup-B2500">
                          Mazda Pickup-B2500
                        </option>
                        <option value="Mazda Pickup-B2600">
                          Mazda Pickup-B2600
                        </option>
                        <option value="Mazda Pickup-B3000">
                          Mazda Pickup-B3000
                        </option>
                        <option value="Mazda Pickup-B4000">
                          Mazda Pickup-B4000
                        </option>
                        <option value="Mazda Pickup-Rotary">
                          Mazda Pickup-Rotary
                        </option>
                        <option value="Mazda Protege">Mazda Protege</option>
                        <option value="Mazda RX2">Mazda RX2</option>
                        <option value="Mazda RX3">Mazda RX3</option>
                        <option value="Mazda RX4">Mazda RX4</option>
                        <option value="Mazda RX7">Mazda RX7</option>
                        <option value="Mazda RX8">Mazda RX8</option>
                        <option value="Mazda Tribute">Mazda Tribute</option>
                        <option value="McLaren 540C">McLaren 540C</option>
                        <option value="McLaren 570GT">McLaren 570GT</option>
                        <option value="McLaren 570S">McLaren 570S</option>
                        <option value="McLaren 600LT">McLaren 600LT</option>
                        <option value="McLaren 620R">McLaren 620R</option>
                        <option value="McLaren 650S">McLaren 650S</option>
                        <option value="McLaren 675LT">McLaren 675LT</option>
                        <option value="McLaren 720S">McLaren 720S</option>
                        <option value="McLaren 765LT">McLaren 765LT</option>
                        <option value="McLaren Artura">McLaren Artura</option>
                        <option value="McLaren GT">McLaren GT</option>
                        <option value="McLaren MP4 12C">McLaren MP4 12C</option>
                        <option value="McLaren P1">McLaren P1</option>
                        <option value="McLaren Senna">McLaren Senna</option>
                        <option value="Mercedes 170">Mercedes 170</option>
                        <option value="Mercedes 190">Mercedes 190</option>
                        <option value="Mercedes 200">Mercedes 200</option>
                        <option value="Mercedes 218">Mercedes 218</option>
                        <option value="Mercedes 219">Mercedes 219</option>
                        <option value="Mercedes 220">Mercedes 220</option>
                        <option value="Mercedes 230-4 Cyl">
                          Mercedes 230-4 Cyl
                        </option>
                        <option value="Mercedes 230-6 Cyl">
                          Mercedes 230-6 Cyl
                        </option>
                        <option value="Mercedes 240D">Mercedes 240D</option>
                        <option value="Mercedes 250">Mercedes 250</option>
                        <option value="Mercedes 260E">Mercedes 260E</option>
                        <option value="Mercedes 280">Mercedes 280</option>
                        <option value="Mercedes 300D (includes CD/D/SD/TD)">
                          Mercedes 300D (includes CD/D/SD/TD)
                        </option>
                        <option value="Mercedes 300E">Mercedes 300E</option>
                        <option value="Mercedes 300SL">Mercedes 300SL</option>
                        <option value="Mercedes 320">Mercedes 320</option>
                        <option value="Mercedes 350">Mercedes 350</option>
                        <option value="Mercedes 380">Mercedes 380</option>
                        <option value="Mercedes 400">Mercedes 400</option>
                        <option value="Mercedes 420">Mercedes 420</option>
                        <option value="Mercedes 450">Mercedes 450</option>
                        <option value="Mercedes 500">Mercedes 500</option>
                        <option value="Mercedes 560">Mercedes 560</option>
                        <option value="Mercedes 600">Mercedes 600</option>
                        <option value="Mercedes AMG GT">Mercedes AMG GT</option>
                        <option value="Mercedes A Class">
                          Mercedes A Class
                        </option>
                        <option value="Mercedes B Class">
                          Mercedes B Class
                        </option>
                        <option value="Mercedes C Class">
                          Mercedes C Class
                        </option>
                        <option value="Mercedes CL Class">
                          Mercedes CL Class
                        </option>
                        <option value="Mercedes CLA Class">
                          Mercedes CLA Class
                        </option>
                        <option value="Mercedes CLK">Mercedes CLK</option>
                        <option value="Mercedes CLS">Mercedes CLS</option>
                        <option value="Mercedes E Class">
                          Mercedes E Class
                        </option>
                        <option value="Mercedes EQB Class">
                          Mercedes EQB Class
                        </option>
                        <option value="Mercedes EQE Class">
                          Mercedes EQE Class
                        </option>
                        <option value="Mercedes EQE Class SUV">
                          Mercedes EQE Class SUV
                        </option>
                        <option value="Mercedes EQS Class">
                          Mercedes EQS Class
                        </option>
                        <option value="Mercedes EQS Class SUV">
                          Mercedes EQS Class SUV
                        </option>
                        <option value="Mercedes eSprinter 2500">
                          Mercedes eSprinter 2500
                        </option>
                        <option value="Mercedes G Class">
                          Mercedes G Class
                        </option>
                        <option value="Mercedes GL Class">
                          Mercedes GL Class
                        </option>
                        <option value="Mercedes GLA Class">
                          Mercedes GLA Class
                        </option>
                        <option value="Mercedes GLB Class">
                          Mercedes GLB Class
                        </option>
                        <option value="Mercedes GLC Class">
                          Mercedes GLC Class
                        </option>
                        <option value="Mercedes GLE Class">
                          Mercedes GLE Class
                        </option>
                        <option value="Mercedes GLK Class">
                          Mercedes GLK Class
                        </option>
                        <option value="Mercedes GLS Class">
                          Mercedes GLS Class
                        </option>
                        <option value="Mercedes ML Series">
                          Mercedes ML Series
                        </option>
                        <option value="Mercedes Metris">Mercedes Metris</option>
                        <option value="Mercedes R Class">
                          Mercedes R Class
                        </option>
                        <option value="Mercedes S Class">
                          Mercedes S Class
                        </option>
                        <option value="Mercedes SL Class">
                          Mercedes SL Class
                        </option>
                        <option value="Mercedes SLC Class">
                          Mercedes SLC Class
                        </option>
                        <option value="Mercedes SLK">Mercedes SLK</option>
                        <option value="Mercedes SLR">Mercedes SLR</option>
                        <option value="Mercedes SLS">Mercedes SLS</option>
                        <option value="Mercedes Sprinter 1500">
                          Mercedes Sprinter 1500
                        </option>
                        <option value="Mercedes Sprinter 2500">
                          Mercedes Sprinter 2500
                        </option>
                        <option value="Mercedes Sprinter 3500">
                          Mercedes Sprinter 3500
                        </option>
                        <option value="Mercedes Sprinter 4500">
                          Mercedes Sprinter 4500
                        </option>
                        <option value="Mercedes Truck">Mercedes Truck</option>
                        <option value="Mercury Bobcat">Mercury Bobcat</option>
                        <option value="Mercury Capri">Mercury Capri</option>
                        <option value="Mercury Comet">Mercury Comet</option>
                        <option value="Mercury Cougar">Mercury Cougar</option>
                        <option value="Mercury Grand Marquis (1979 Down)">
                          Mercury Grand Marquis (1979 Down)
                        </option>
                        <option value="Mercury Grand Marquis (1980 Up)">
                          Mercury Grand Marquis (1980 Up)
                        </option>
                        <option value="Mercury LN7">Mercury LN7</option>
                        <option value="Mercury Lynx (except LN7)">
                          Mercury Lynx (except LN7)
                        </option>
                        <option value="Mercury Marauder">
                          Mercury Marauder
                        </option>
                        <option value="Mercury Mariner">Mercury Mariner</option>
                        <option value="Mercury Marquis (not Grand)">
                          Mercury Marquis (not Grand)
                        </option>
                        <option value="Mercury Merkur (includes XR4TI and Scorpio)">
                          Mercury Merkur (includes XR4TI and Scorpio)
                        </option>
                        <option value="Mercury Milan">Mercury Milan</option>
                        <option value="Mercury Monarch">Mercury Monarch</option>
                        <option value="Mercury Montego">Mercury Montego</option>
                        <option value="Mercury Monterey">
                          Mercury Monterey
                        </option>
                        <option value="Mercury Mountaineer">
                          Mercury Mountaineer
                        </option>
                        <option value="Mercury Mystique">
                          Mercury Mystique
                        </option>
                        <option value="Mercury Sable">Mercury Sable</option>
                        <option value="Mercury Topaz">Mercury Topaz</option>
                        <option value="Mercury Tracer">Mercury Tracer</option>
                        <option value="Mercury Villager">
                          Mercury Villager
                        </option>
                        <option value="Mercury Zephyr">Mercury Zephyr</option>
                        <option value="Mercury Other">Mercury Other</option>
                        <option value="Mini (Austin)">Mini (Austin)</option>
                        <option value="Mini Cooper">Mini Cooper</option>
                        <option value="Mini Cooper Clubman">
                          Mini Cooper Clubman
                        </option>
                        <option value="Mini Cooper Countryman">
                          Mini Cooper Countryman
                        </option>
                        <option value="Mini Cooper Paceman">
                          Mini Cooper Paceman
                        </option>
                        <option value="Mitsubishi 3000">Mitsubishi 3000</option>
                        <option value="Mitsubishi Cordia">
                          Mitsubishi Cordia
                        </option>
                        <option value="Mitsubishi Diamante">
                          Mitsubishi Diamante
                        </option>
                        <option value="Mitsubishi Eclipse">
                          Mitsubishi Eclipse
                        </option>
                        <option value="Mitsubishi Eclipse Cross">
                          Mitsubishi Eclipse Cross
                        </option>
                        <option value="Mitsubishi Endeavor">
                          Mitsubishi Endeavor
                        </option>
                        <option value="Mitsubishi Expo">Mitsubishi Expo</option>
                        <option value="Mitsubishi Fuso">Mitsubishi Fuso</option>
                        <option value="Mitsubishi Galant">
                          Mitsubishi Galant
                        </option>
                        <option value="Mitsubishi i MiEV">
                          Mitsubishi i MiEV
                        </option>
                        <option value="Mitsubishi Lancer">
                          Mitsubishi Lancer
                        </option>
                        <option value="Mitsubishi Minicab">
                          Mitsubishi Minicab
                        </option>
                        <option value="Mitsubishi Mirage">
                          Mitsubishi Mirage
                        </option>
                        <option value="Mitsubishi Montero">
                          Mitsubishi Montero
                        </option>
                        <option value="Mitsubishi Montero-Sport">
                          Mitsubishi Montero-Sport
                        </option>
                        <option value="Mitsubishi Outlander">
                          Mitsubishi Outlander
                        </option>
                        <option value="Mitsubishi Outlander Sport">
                          Mitsubishi Outlander Sport
                        </option>
                        <option value="Mitsubishi Pickup (See also Dodge D50)">
                          Mitsubishi Pickup (See also Dodge D50)
                        </option>
                        <option value="Mitsubishi Precis">
                          Mitsubishi Precis
                        </option>
                        <option value="Mitsubishi Raider">
                          Mitsubishi Raider
                        </option>
                        <option value="Mitsubishi RVR">Mitsubishi RVR</option>
                        <option value="Mitsubishi Sigma">
                          Mitsubishi Sigma
                        </option>
                        <option value="Mitsubishi Space Wagon">
                          Mitsubishi Space Wagon
                        </option>
                        <option value="Mitsubishi Starion">
                          Mitsubishi Starion
                        </option>
                        <option value="Mitsubishi Tredia">
                          Mitsubishi Tredia
                        </option>
                        <option value="Mitsubishi Van">Mitsubishi Van</option>
                        <option value="Morris">Morris</option>
                        <option value="Nash">Nash</option>
                        <option value="Nissan 1200">Nissan 1200</option>
                        <option value="Nissan 1600">Nissan 1600</option>
                        <option value="Nissan 200SX">Nissan 200SX</option>
                        <option value="Nissan 210">Nissan 210</option>
                        <option value="Nissan 240SX">Nissan 240SX</option>
                        <option value="Nissan 240Z">Nissan 240Z</option>
                        <option value="Nissan 260Z">Nissan 260Z</option>
                        <option value="Nissan 280-Z">Nissan 280-Z</option>
                        <option value="Nissan 280-ZX">Nissan 280-ZX</option>
                        <option value="Nissan 300ZX">Nissan 300ZX</option>
                        <option value="Nissan 350Z">Nissan 350Z</option>
                        <option value="Nissan 370Z">Nissan 370Z</option>
                        <option value="Nissan 310">Nissan 310</option>
                        <option value="Nissan 311">Nissan 311</option>
                        <option value="Nissan 410">Nissan 410</option>
                        <option value="Nissan 411">Nissan 411</option>
                        <option value="Nissan 510">Nissan 510</option>
                        <option value="Nissan 610">Nissan 610</option>
                        <option value="Nissan 710">Nissan 710</option>
                        <option value="Nissan 810">Nissan 810</option>
                        <option value="Nissan Almera">Nissan Almera</option>
                        <option value="Nissan Altima">Nissan Altima</option>
                        <option value="Nissan Ariya">Nissan Ariya</option>
                        <option value="Nissan Armada">Nissan Armada</option>
                        <option value="Nissan Axxess">Nissan Axxess</option>
                        <option value="Nissan B210">Nissan B210</option>
                        <option value="Nissan Cube">Nissan Cube</option>
                        <option value="Nissan F10">Nissan F10</option>
                        <option value="Nissan Frontier">Nissan Frontier</option>
                        <option value="Nissan GTR">Nissan GTR</option>
                        <option value="Nissan Juke">Nissan Juke</option>
                        <option value="Nissan Kicks">Nissan Kicks</option>
                        <option value="Nissan Leaf">Nissan Leaf</option>
                        <option value="Nissan Lucino">Nissan Lucino</option>
                        <option value="Nissan Maxima (1981 Down)">
                          Nissan Maxima (1981 Down)
                        </option>
                        <option value="Nissan Maxima (1982 Up)">
                          Nissan Maxima (1982 Up)
                        </option>
                        <option value="Nissan Micra">Nissan Micra</option>
                        <option value="Nissan Murano">Nissan Murano</option>
                        <option value="Nissan NV 200">Nissan NV 200</option>
                        <option value="Nissan NV 1500">Nissan NV 1500</option>
                        <option value="Nissan NV 2500">Nissan NV 2500</option>
                        <option value="Nissan NV 3500">Nissan NV 3500</option>
                        <option value="Nissan NX">Nissan NX</option>
                        <option value="Nissan Pathfinder">
                          Nissan Pathfinder
                        </option>
                        <option value="Nissan Patrol">Nissan Patrol</option>
                        <option value="Nissan Platina">Nissan Platina</option>
                        <option value="Nissan Pulsar">Nissan Pulsar</option>
                        <option value="Nissan Qashqai">Nissan Qashqai</option>
                        <option value="Nissan Quest">Nissan Quest</option>
                        <option value="Nissan Rogue">Nissan Rogue</option>
                        <option value="Nissan Rogue Sport">
                          Nissan Rogue Sport
                        </option>
                        <option value="Nissan Sentra">Nissan Sentra</option>
                        <option value="Nissan Stanza (Excl Van)">
                          Nissan Stanza (Excl Van)
                        </option>
                        <option value="Nissan Stanza Van">
                          Nissan Stanza Van
                        </option>
                        <option value="Nissan Tida">Nissan Tida</option>
                        <option value="Nissan Truck">Nissan Truck</option>
                        <option value="Nissan Truck-Titan">
                          Nissan Truck-Titan
                        </option>
                        <option value="Nissan Truck-Titan XD">
                          Nissan Truck-Titan XD
                        </option>
                        <option value="Nissan Tsubame">Nissan Tsubame</option>
                        <option value="Nissan UD Series">
                          Nissan UD Series
                        </option>
                        <option value="Nissan Van GC22">Nissan Van GC22</option>
                        <option value="Nissan Versa">Nissan Versa</option>
                        <option value="Nissan X Trail">Nissan X Trail</option>
                        <option value="Nissan Xterra">Nissan Xterra</option>
                        <option value="Nissan Z">Nissan Z</option>
                        <option value="Oldsmobile 88 (1979 Down)">
                          Oldsmobile 88 (1979 Down)
                        </option>
                        <option value="Oldsmobile 88 (1980 Up)">
                          Oldsmobile 88 (1980 Up)
                        </option>
                        <option value="Oldsmobile 98 (1979 Down)">
                          Oldsmobile 98 (1979 Down)
                        </option>
                        <option value="Oldsmobile 98 (1980 Up)">
                          Oldsmobile 98 (1980 Up)
                        </option>
                        <option value="Oldsmobile Achieva">
                          Oldsmobile Achieva
                        </option>
                        <option value="Oldsmobile Alero">
                          Oldsmobile Alero
                        </option>
                        <option value="Oldsmobile Aurora">
                          Oldsmobile Aurora
                        </option>
                        <option value="Oldsmobile Bravada">
                          Oldsmobile Bravada
                        </option>
                        <option value="Oldsmobile Calais (1984 Down)">
                          Oldsmobile Calais (1984 Down)
                        </option>
                        <option value="Oldsmobile Calais (1985 Up)">
                          Oldsmobile Calais (1985 Up)
                        </option>
                        <option value="Oldsmobile Ciera">
                          Oldsmobile Ciera
                        </option>
                        <option value="Oldsmobile Custom Cruiser (1979 Down)">
                          Oldsmobile Custom Cruiser (1979 Down)
                        </option>
                        <option value="Oldsmobile Custom Cruiser (1980 Up)">
                          Oldsmobile Custom Cruiser (1980 Up)
                        </option>
                        <option value="Oldsmobile Cutlass (1972 Down)">
                          Oldsmobile Cutlass (1972 Down)
                        </option>
                        <option value="Oldsmobile Cutlass (1973 Up)">
                          Oldsmobile Cutlass (1973 Up)
                        </option>
                        <option value="Oldsmobile F85">Oldsmobile F85</option>
                        <option value="Oldsmobile Firenza">
                          Oldsmobile Firenza
                        </option>
                        <option value="Oldsmobile Intrigue">
                          Oldsmobile Intrigue
                        </option>
                        <option value="Oldsmobile Omega">
                          Oldsmobile Omega
                        </option>
                        <option value="Oldsmobile Silhouette">
                          Oldsmobile Silhouette
                        </option>
                        <option value="Oldsmobile Starfire">
                          Oldsmobile Starfire
                        </option>
                        <option value="Oldsmobile Supreme-Calais (1988 Up)">
                          Oldsmobile Supreme-Calais (1988 Up)
                        </option>
                        <option value="Oldsmobile Supreme-Cutlass (1988 Up)">
                          Oldsmobile Supreme-Cutlass (1988 Up)
                        </option>
                        <option value="Oldsmobile Supreme (1972 Down)">
                          Oldsmobile Supreme (1972 Down)
                        </option>
                        <option value="Oldsmobile Supreme (1973-1987)">
                          Oldsmobile Supreme (1973-1987)
                        </option>
                        <option value="Oldsmobile Toronado">
                          Oldsmobile Toronado
                        </option>
                        <option value="Oldsmobile Other">
                          Oldsmobile Other
                        </option>
                        <option value="Opel">Opel</option>
                        <option value="Oshkosh">Oshkosh</option>
                        <option value="Pace Arrow">Pace Arrow</option>
                        <option value="Packard">Packard</option>
                        <option value="Pantera">Pantera</option>
                        <option value="Peterbilt">Peterbilt</option>
                        <option value="Peugeot 304">Peugeot 304</option>
                        <option value="Peugeot 403">Peugeot 403</option>
                        <option value="Peugeot 404">Peugeot 404</option>
                        <option value="Peugeot 405">Peugeot 405</option>
                        <option value="Peugeot 504">Peugeot 504</option>
                        <option value="Peugeot 505">Peugeot 505</option>
                        <option value="Peugeot 604">Peugeot 604</option>
                        <option value="Plymouth Acclaim">
                          Plymouth Acclaim
                        </option>
                        <option value="Plymouth Arrow-Car">
                          Plymouth Arrow-Car
                        </option>
                        <option value="Plymouth Arrow-Truck (See also Dodge D50)">
                          Plymouth Arrow-Truck (See also Dodge D50)
                        </option>
                        <option value="Plymouth Barracuda">
                          Plymouth Barracuda
                        </option>
                        <option value="Plymouth Breeze">Plymouth Breeze</option>
                        <option value="Plymouth Caravelle">
                          Plymouth Caravelle
                        </option>
                        <option value="Plymouth Champ">Plymouth Champ</option>
                        <option value="Plymouth Cricket">
                          Plymouth Cricket
                        </option>
                        <option value="Plymouth Duster (1970-1976)">
                          Plymouth Duster (1970-1976)
                        </option>
                        <option value="Plymouth Duster (1979-1980)">
                          Plymouth Duster (1979-1980)
                        </option>
                        <option value="Plymouth Duster (1985-1987)">
                          Plymouth Duster (1985-1987)
                        </option>
                        <option value="Plymouth Duster (1992-1994)">
                          Plymouth Duster (1992-1994)
                        </option>
                        <option value="Plymouth Grand Fury (1979 Down)">
                          Plymouth Grand Fury (1979 Down)
                        </option>
                        <option value="Plymouth Grand Fury (1980 Up)">
                          Plymouth Grand Fury (1980 Up)
                        </option>
                        <option value="Plymouth Horizon">
                          Plymouth Horizon
                        </option>
                        <option value="Plymouth Laser">Plymouth Laser</option>
                        <option value="Plymouth Neon">Plymouth Neon</option>
                        <option value="Plymouth Prowler">
                          Plymouth Prowler
                        </option>
                        <option value="Plymouth Reliant">
                          Plymouth Reliant
                        </option>
                        <option value="Plymouth Sapporo">
                          Plymouth Sapporo
                        </option>
                        <option value="Plymouth Scamp (1983 only)">
                          Plymouth Scamp (1983 only)
                        </option>
                        <option value="Plymouth Scamp (except 1983)">
                          Plymouth Scamp (except 1983)
                        </option>
                        <option value="Plymouth Sundance">
                          Plymouth Sundance
                        </option>
                        <option value="Plymouth Trailduster">
                          Plymouth Trailduster
                        </option>
                        <option value="Plymouth Valiant">
                          Plymouth Valiant
                        </option>
                        <option value="Plymouth Van 100">
                          Plymouth Van 100
                        </option>
                        <option value="Plymouth Van 150">
                          Plymouth Van 150
                        </option>
                        <option value="Plymouth Van 200">
                          Plymouth Van 200
                        </option>
                        <option value="Plymouth Van 250">
                          Plymouth Van 250
                        </option>
                        <option value="Plymouth Van 300">
                          Plymouth Van 300
                        </option>
                        <option value="Plymouth Van 350">
                          Plymouth Van 350
                        </option>
                        <option value="Plymouth Volare">Plymouth Volare</option>
                        <option value="Plymouth Voyager">
                          Plymouth Voyager
                        </option>
                        <option value="Plymouth Other">Plymouth Other</option>
                        <option value="Polestar 1">Polestar 1</option>
                        <option value="Polestar 2">Polestar 2</option>
                        <option value="Pontiac 1000">Pontiac 1000</option>
                        <option value="Pontiac 2000-P/J/Sunbird">
                          Pontiac 2000-P/J/Sunbird
                        </option>
                        <option value="Pontiac 6000">Pontiac 6000</option>
                        <option value="Pontiac Acadian">Pontiac Acadian</option>
                        <option value="Pontiac Astre">Pontiac Astre</option>
                        <option value="Pontiac Aztek">Pontiac Aztek</option>
                        <option value="Pontiac Bonneville (1979 Down)">
                          Pontiac Bonneville (1979 Down)
                        </option>
                        <option value="Pontiac Bonneville (1980 Up)">
                          Pontiac Bonneville (1980 Up)
                        </option>
                        <option value="Pontiac Catalina (1979 Down)">
                          Pontiac Catalina (1979 Down)
                        </option>
                        <option value="Pontiac Catalina (1980 Up)">
                          Pontiac Catalina (1980 Up)
                        </option>
                        <option value="Pontiac Fiero">Pontiac Fiero</option>
                        <option value="Pontiac Firebird">
                          Pontiac Firebird
                        </option>
                        <option value="Pontiac Firefly">Pontiac Firefly</option>
                        <option value="Pontiac G3">Pontiac G3</option>
                        <option value="Pontiac G4">Pontiac G4</option>
                        <option value="Pontiac G5">Pontiac G5</option>
                        <option value="Pontiac G6">Pontiac G6</option>
                        <option value="Pontiac G8">Pontiac G8</option>
                        <option value="Pontiac Grand AM">
                          Pontiac Grand AM
                        </option>
                        <option value="Pontiac Grand Prix">
                          Pontiac Grand Prix
                        </option>
                        <option value="Pontiac GTO (New Style)">
                          Pontiac GTO (New Style)
                        </option>
                        <option value="Pontiac GTO (Old Style)">
                          Pontiac GTO (Old Style)
                        </option>
                        <option value="Pontiac Lemans">Pontiac Lemans</option>
                        <option value="Pontiac Matiz">Pontiac Matiz</option>
                        <option value="Pontiac Montana">Pontiac Montana</option>
                        <option value="Pontiac Parisienne (1979 Down)">
                          Pontiac Parisienne (1979 Down)
                        </option>
                        <option value="Pontiac Parisienne (1980 Up)">
                          Pontiac Parisienne (1980 Up)
                        </option>
                        <option value="Pontiac Phoenix">Pontiac Phoenix</option>
                        <option value="Pontiac Pursuit">Pontiac Pursuit</option>
                        <option value="Pontiac Solstice">
                          Pontiac Solstice
                        </option>
                        <option value="Pontiac Sunbird">Pontiac Sunbird</option>
                        <option value="Pontiac Sunburst">
                          Pontiac Sunburst
                        </option>
                        <option value="Pontiac Sunfire">Pontiac Sunfire</option>
                        <option value="Pontiac Sunrunner">
                          Pontiac Sunrunner
                        </option>
                        <option value="Pontiac Tempest">Pontiac Tempest</option>
                        <option value="Pontiac Torrent">Pontiac Torrent</option>
                        <option value="Pontiac Trans Sport">
                          Pontiac Trans Sport
                        </option>
                        <option value="Pontiac Van-Montana">
                          Pontiac Van-Montana
                        </option>
                        <option value="Pontiac Ventura">Pontiac Ventura</option>
                        <option value="Pontiac Vibe">Pontiac Vibe</option>
                        <option value="Pontiac Wave">Pontiac Wave</option>
                        <option value="Pontiac Other">Pontiac Other</option>
                        <option value="Porsche 356">Porsche 356</option>
                        <option value="Porsche 911/930">Porsche 911/930</option>
                        <option value="Porsche 912/e">Porsche 912/e</option>
                        <option value="Porsche 914">Porsche 914</option>
                        <option value="Porsche 918">Porsche 918</option>
                        <option value="Porsche 924">Porsche 924</option>
                        <option value="Porsche 928">Porsche 928</option>
                        <option value="Porsche 944">Porsche 944</option>
                        <option value="Porsche 968">Porsche 968</option>
                        <option value="Porsche Boxster">Porsche Boxster</option>
                        <option value="Porsche Carrera-GT">
                          Porsche Carrera-GT
                        </option>
                        <option value="Porsche Cayenne">Porsche Cayenne</option>
                        <option value="Porsche Cayman S">
                          Porsche Cayman S
                        </option>
                        <option value="Porsche Macan">Porsche Macan</option>
                        <option value="Porsche Panamera">
                          Porsche Panamera
                        </option>
                        <option value="Porsche Taycan">Porsche Taycan</option>
                        <option value="Renault 18I">Renault 18I</option>
                        <option value="Ram Promaster 1500">
                          Ram Promaster 1500
                        </option>
                        <option value="Ram Promaster 2500">
                          Ram Promaster 2500
                        </option>
                        <option value="Ram Promaster 3500">
                          Ram Promaster 3500
                        </option>
                        <option value="Ram Promaster City">
                          Ram Promaster City
                        </option>
                        <option value="Ram Truck 1500 Series">
                          Ram Truck 1500 Series
                        </option>
                        <option value="Ram Truck 2500 Series">
                          Ram Truck 2500 Series
                        </option>
                        <option value="Ram Truck 3500 Series">
                          Ram Truck 3500 Series
                        </option>
                        <option value="Ram Truck 4500 Series">
                          Ram Truck 4500 Series
                        </option>
                        <option value="Ram Truck 5500 Series">
                          Ram Truck 5500 Series
                        </option>
                        <option value="Renault Alliance">
                          Renault Alliance
                        </option>
                        <option value="Renault Clio">Renault Clio</option>
                        <option value="Renault Dauphine">
                          Renault Dauphine
                        </option>
                        <option value="Renault Encore">Renault Encore</option>
                        <option value="Renault Fuego">Renault Fuego</option>
                        <option value="Renault Gordini">Renault Gordini</option>
                        <option value="Renault Lecar/R5">
                          Renault Lecar/R5
                        </option>
                        <option value="Renault Medallion">
                          Renault Medallion
                        </option>
                        <option value="Renault Megane">Renault Megane</option>
                        <option value="Renault R8">Renault R8</option>
                        <option value="Renault R10">Renault R10</option>
                        <option value="Renault R12">Renault R12</option>
                        <option value="Renault R15">Renault R15</option>
                        <option value="Renault R16">Renault R16</option>
                        <option value="Renault R17">Renault R17</option>
                        <option value="Renault R30">Renault R30</option>
                        <option value="Renault Other">Renault Other</option>
                        <option value="REO">REO</option>
                        <option value="Rivian R1S">Rivian R1S</option>
                        <option value="Rivian R1T">Rivian R1T</option>
                        <option value="RollsRoyce">RollsRoyce</option>
                        <option value="Rover 3 Litre">Rover 3 Litre</option>
                        <option value="Rover 100">Rover 100</option>
                        <option value="Rover 2000">Rover 2000</option>
                        <option value="Rover 3500">Rover 3500</option>
                        <option value="Rover 3500S">Rover 3500S</option>
                        <option value="Saab 9-3 (1999 Up)">
                          Saab 9-3 (1999 Up)
                        </option>
                        <option value="Saab 9-5 (1999 Up)">
                          Saab 9-5 (1999 Up)
                        </option>
                        <option value="Saab 92x">Saab 92x</option>
                        <option value="Saab 93 (1960 Down)">
                          Saab 93 (1960 Down)
                        </option>
                        <option value="Saab 94x">Saab 94x</option>
                        <option value="Saab 95 (1972 Down)">
                          Saab 95 (1972 Down)
                        </option>
                        <option value="Saab 96">Saab 96</option>
                        <option value="Saab 97x">Saab 97x</option>
                        <option value="Saab 99">Saab 99</option>
                        <option value="Saab 900 (incl Turbo)">
                          Saab 900 (incl Turbo)
                        </option>
                        <option value="Saab 9000 (incl Turbo)">
                          Saab 9000 (incl Turbo)
                        </option>
                        <option value="Saab Monte Carlo">
                          Saab Monte Carlo
                        </option>
                        <option value="Saab Sonett">Saab Sonett</option>
                        <option value="Saab Sonett III">Saab Sonett III</option>
                        <option value="Saturn Astra">Saturn Astra</option>
                        <option value="Saturn Aura">Saturn Aura</option>
                        <option value="Saturn EV1">Saturn EV1</option>
                        <option value="Saturn Ion">Saturn Ion</option>
                        <option value="Saturn L Series">Saturn L Series</option>
                        <option value="Saturn S Series">Saturn S Series</option>
                        <option value="Saturn Outlook">Saturn Outlook</option>
                        <option value="Saturn Relay">Saturn Relay</option>
                        <option value="Saturn Sky">Saturn Sky</option>
                        <option value="Saturn Vue">Saturn Vue</option>
                        <option value="Scion FRS">Scion FRS</option>
                        <option value="Scion iA">Scion iA</option>
                        <option value="Scion iM">Scion iM</option>
                        <option value="Scion iQ">Scion iQ</option>
                        <option value="Scion tC">Scion tC</option>
                        <option value="Scion xA">Scion xA</option>
                        <option value="Scion xB">Scion xB</option>
                        <option value="Scion xD">Scion xD</option>
                        <option value="Seat Cordova">Seat Cordova</option>
                        <option value="Seat Ibiza">Seat Ibiza</option>
                        <option value="Seat Leon">Seat Leon</option>
                        <option value="Seat Toledo">Seat Toledo</option>
                        <option value="Simca">Simca</option>
                        <option value="Smart Fortwo">Smart Fortwo</option>
                        <option value="SterlingRover">SterlingRover</option>
                        <option value="Studebaker">Studebaker</option>
                        <option value="Subaru Ascent">Subaru Ascent</option>
                        <option value="Subaru Baja">Subaru Baja</option>
                        <option value="Subaru Brat">Subaru Brat</option>
                        <option value="Subaru BRZ">Subaru BRZ</option>
                        <option value="Subaru Chaser">Subaru Chaser</option>
                        <option value="Subaru Crosstrek">
                          Subaru Crosstrek
                        </option>
                        <option value="Subaru Forester">Subaru Forester</option>
                        <option value="Subaru Impreza">Subaru Impreza</option>
                        <option value="Subaru Justy">Subaru Justy</option>
                        <option value="Subaru Legacy">Subaru Legacy</option>
                        <option value="Subaru Loyale">Subaru Loyale</option>
                        <option value="Subaru Outback (Impreza)">
                          Subaru Outback (Impreza)
                        </option>
                        <option value="Subaru Outback (Legacy)">
                          Subaru Outback (Legacy)
                        </option>
                        <option value="Subaru Sambar">Subaru Sambar</option>
                        <option value="Subaru Solterra">Subaru Solterra</option>
                        <option value="Subaru Streega">Subaru Streega</option>
                        <option value="Subaru SVX">Subaru SVX</option>
                        <option value="Subaru Tribeca">Subaru Tribeca</option>
                        <option value="Subaru WRX (2014 Down)">
                          Subaru WRX (2014 Down)
                        </option>
                        <option value="Subaru WRX (2015 Up)">
                          Subaru WRX (2015 Up)
                        </option>
                        <option value="Subaru XT">Subaru XT</option>
                        <option value="Subaru XV Crosstrek">
                          Subaru XV Crosstrek
                        </option>
                        <option value="Subaru Other">Subaru Other</option>
                        <option value="Sunbeam">Sunbeam</option>
                        <option value="Suzuki Aerio">Suzuki Aerio</option>
                        <option value="Suzuki Carry">Suzuki Carry</option>
                        <option value="Suzuki Esteem">Suzuki Esteem</option>
                        <option value="Suzuki Equator">Suzuki Equator</option>
                        <option value="Suzuki Forenza">Suzuki Forenza</option>
                        <option value="Suzuki Forsa">Suzuki Forsa</option>
                        <option value="Suzuki Kizashi">Suzuki Kizashi</option>
                        <option value="Suzuki Reno">Suzuki Reno</option>
                        <option value="Suzuki Samurai">Suzuki Samurai</option>
                        <option value="Suzuki Sidekick">Suzuki Sidekick</option>
                        <option value="Suzuki SJ410">Suzuki SJ410</option>
                        <option value="Suzuki Swift">Suzuki Swift</option>
                        <option value="Suzuki SX4">Suzuki SX4</option>
                        <option value="Suzuki Verona">Suzuki Verona</option>
                        <option value="Suzuki Vitara">Suzuki Vitara</option>
                        <option value="Suzuki X90">Suzuki X90</option>
                        <option value="Suzuki XL7">Suzuki XL7</option>
                        <option value="Tesla Model 3">Tesla Model 3</option>
                        <option value="Tesla Model S">Tesla Model S</option>
                        <option value="Tesla Model X">Tesla Model X</option>
                        <option value="Tesla Model Y">Tesla Model Y</option>
                        <option value="Tesla Roadster">Tesla Roadster</option>
                        <option value="Thomas Truck">Thomas Truck</option>
                        <option value="Toyota 86">Toyota 86</option>
                        <option value="Toyota 4Runner">Toyota 4Runner</option>
                        <option value="Toyota Aristo">Toyota Aristo</option>
                        <option value="Toyota Avalon">Toyota Avalon</option>
                        <option value="Toyota BZ4X">Toyota BZ4X</option>
                        <option value="Toyota CHR">Toyota CHR</option>
                        <option value="Toyota Camry">Toyota Camry</option>
                        <option value="Toyota Carina">Toyota Carina</option>
                        <option value="Toyota Celica">Toyota Celica</option>
                        <option value="Toyota Corolla">Toyota Corolla</option>
                        <option value="Toyota Corolla Cross">
                          Toyota Corolla Cross
                        </option>
                        <option value="Toyota Corolla FX/FX16">
                          Toyota Corolla FX/FX16
                        </option>
                        <option value="Toyota Corolla iM">
                          Toyota Corolla iM
                        </option>
                        <option value="Toyota Corona MKII">
                          Toyota Corona MKII
                        </option>
                        <option value="Toyota Corona not MKII">
                          Toyota Corona not MKII
                        </option>
                        <option value="Toyota Cressida">Toyota Cressida</option>
                        <option value="Toyota Crown">Toyota Crown</option>
                        <option value="Toyota Echo">Toyota Echo</option>
                        <option value="Toyota FJ Cruiser">
                          Toyota FJ Cruiser
                        </option>
                        <option value="Toyota FX/FX16">Toyota FX/FX16</option>
                        <option value="Toyota GR86">Toyota GR86</option>
                        <option value="Toyota GR Corolla">
                          Toyota GR Corolla
                        </option>
                        <option value="Toyota GR Supra">Toyota GR Supra</option>
                        <option value="Toyota Grand Highlander">
                          Toyota Grand Highlander
                        </option>
                        <option value="Toyota HiAce">Toyota HiAce</option>
                        <option value="Toyota Highlander">
                          Toyota Highlander
                        </option>
                        <option value="Toyota Land Cruiser">
                          Toyota Land Cruiser
                        </option>
                        <option value="Toyota Matrix">Toyota Matrix</option>
                        <option value="Toyota Mirai">Toyota Mirai</option>
                        <option value="Toyota MR2">Toyota MR2</option>
                        <option value="Toyota Paseo">Toyota Paseo</option>
                        <option value="Toyota Previa">Toyota Previa</option>
                        <option value="Toyota Prius">Toyota Prius</option>
                        <option value="Toyota RAV4">Toyota RAV4</option>
                        <option value="Toyota Sequoia">Toyota Sequoia</option>
                        <option value="Toyota Sienna">Toyota Sienna</option>
                        <option value="Toyota Solara">Toyota Solara</option>
                        <option value="Toyota Starlet">Toyota Starlet</option>
                        <option value="Toyota Stout">Toyota Stout</option>
                        <option value="Toyota Supra">Toyota Supra</option>
                        <option value="Toyota T100">Toyota T100</option>
                        <option value="Toyota Tacoma">Toyota Tacoma</option>
                        <option value="Toyota Tercel">Toyota Tercel</option>
                        <option value="Toyota Truck (except T100 &amp; Tundra)">
                          Toyota Truck (except T100 &amp; Tundra)
                        </option>
                        <option value="Toyota Tundra">Toyota Tundra</option>
                        <option value="Toyota Van (See also Previa)">
                          Toyota Van (See also Previa)
                        </option>
                        <option value="Toyota Venza">Toyota Venza</option>
                        <option value="Toyota Yaris">Toyota Yaris</option>
                        <option value="Toyota Yaris iA">Toyota Yaris iA</option>
                        <option value="Triumph GT6">Triumph GT6</option>
                        <option value="Triumph Spitfire">
                          Triumph Spitfire
                        </option>
                        <option value="Triumph Stag">Triumph Stag</option>
                        <option value="Triumph TR2">Triumph TR2</option>
                        <option value="Triumph TR3">Triumph TR3</option>
                        <option value="Triumph TR4">Triumph TR4</option>
                        <option value="Triumph TR4A">Triumph TR4A</option>
                        <option value="Triumph TR6">Triumph TR6</option>
                        <option value="Triumph TR7">Triumph TR7</option>
                        <option value="Triumph TR8">Triumph TR8</option>
                        <option value="Triumph TR250">Triumph TR250</option>
                        <option value="Utilimaster Step Van">
                          Utilimaster Step Van
                        </option>
                        <option value="Volkswagen 412/411">
                          Volkswagen 412/411
                        </option>
                        <option value="Volkswagen Arteon">
                          Volkswagen Arteon
                        </option>
                        <option value="Volkswagen Atlas">
                          Volkswagen Atlas
                        </option>
                        <option value="Volkswagen Atlas Cross Sport">
                          Volkswagen Atlas Cross Sport
                        </option>
                        <option value="Volkswagen Beetle/Bug">
                          Volkswagen Beetle/Bug
                        </option>
                        <option value="Volkswagen Cabrio">
                          Volkswagen Cabrio
                        </option>
                        <option value="Volkswagen Cabriolet">
                          Volkswagen Cabriolet
                        </option>
                        <option value="Volkswagen CC">Volkswagen CC</option>
                        <option value="Volkswagen Corrado">
                          Volkswagen Corrado
                        </option>
                        <option value="Volkswagen Dasher">
                          Volkswagen Dasher
                        </option>
                        <option value="Volkswagen Derby">
                          Volkswagen Derby
                        </option>
                        <option value="Volkswagen Eos">Volkswagen Eos</option>
                        <option value="Volkswagen Fox">Volkswagen Fox</option>
                        <option value="Volkswagen Golf">Volkswagen Golf</option>
                        <option value="Volkswagen Golf GTI">
                          Volkswagen Golf GTI
                        </option>
                        <option value="Volkswagen ID.4">Volkswagen ID.4</option>
                        <option value="Volkswagen Jetta">
                          Volkswagen Jetta
                        </option>
                        <option value="Volkswagen Jetta GLI">
                          Volkswagen Jetta GLI
                        </option>
                        <option value="Volkswagen Karmann Ghia">
                          Volkswagen Karmann Ghia
                        </option>
                        <option value="Volkswagen Passat">
                          Volkswagen Passat
                        </option>
                        <option value="Volkswagen Phaeton">
                          Volkswagen Phaeton
                        </option>
                        <option value="Volkswagen Pointer">
                          Volkswagen Pointer
                        </option>
                        <option value="Volkswagen Pointer Truck">
                          Volkswagen Pointer Truck
                        </option>
                        <option value="Volkswagen Quantum">
                          Volkswagen Quantum
                        </option>
                        <option value="Volkswagen Rabbit">
                          Volkswagen Rabbit
                        </option>
                        <option value="Volkswagen Routan">
                          Volkswagen Routan
                        </option>
                        <option value="Volkswagen Scirocco">
                          Volkswagen Scirocco
                        </option>
                        <option value="Volkswagen Sedan">
                          Volkswagen Sedan
                        </option>
                        <option value="Volkswagen Sharan">
                          Volkswagen Sharan
                        </option>
                        <option value="Volkswagen Taos">Volkswagen Taos</option>
                        <option value="Volkswagen Thing">
                          Volkswagen Thing
                        </option>
                        <option value="Volkswagen Tiguan">
                          Volkswagen Tiguan
                        </option>
                        <option value="Volkswagen Touareg">
                          Volkswagen Touareg
                        </option>
                        <option value="Volkswagen Type 3">
                          Volkswagen Type 3
                        </option>
                        <option value="Volkswagen Van-EuroVan">
                          Volkswagen Van-EuroVan
                        </option>
                        <option value="Volkswagen Van-Transporter">
                          Volkswagen Van-Transporter
                        </option>
                        <option value="Volkswagen Van-Vanagon">
                          Volkswagen Van-Vanagon
                        </option>
                        <option value="Volkswagen Other">
                          Volkswagen Other
                        </option>
                        <option value="Volvo 30 Series">Volvo 30 Series</option>
                        <option value="Volvo 40 Series">Volvo 40 Series</option>
                        <option value="Volvo 50 Series">Volvo 50 Series</option>
                        <option value="Volvo 60 Series">Volvo 60 Series</option>
                        <option value="Volvo 70 Series">Volvo 70 Series</option>
                        <option value="Volvo 80 Series">Volvo 80 Series</option>
                        <option value="Volvo 90 Series">Volvo 90 Series</option>
                        <option value="Volvo 120 Series">
                          Volvo 120 Series
                        </option>
                        <option value="Volvo 140 Series">
                          Volvo 140 Series
                        </option>
                        <option value="Volvo 160 Series">
                          Volvo 160 Series
                        </option>
                        <option value="Volvo 240">Volvo 240</option>
                        <option value="Volvo 260">Volvo 260</option>
                        <option value="Volvo 444/445">Volvo 444/445</option>
                        <option value="Volvo 544">Volvo 544</option>
                        <option value="Volvo 740">Volvo 740</option>
                        <option value="Volvo 760">Volvo 760</option>
                        <option value="Volvo 780">Volvo 780</option>
                        <option value="Volvo 850">Volvo 850</option>
                        <option value="Volvo 940">Volvo 940</option>
                        <option value="Volvo 960">Volvo 960</option>
                        <option value="Volvo 1800">Volvo 1800</option>
                        <option value="Volvo C40">Volvo C40</option>
                        <option value="Volvo F7">Volvo F7</option>
                        <option value="Volvo FE6">Volvo FE6</option>
                        <option value="Volvo S60 (2013 Down)">
                          Volvo S60 (2013 Down)
                        </option>
                        <option value="Volvo S60 (2014 Up)">
                          Volvo S60 (2014 Up)
                        </option>
                        <option value="Volvo S90">Volvo S90</option>
                        <option value="Volvo Truck">Volvo Truck</option>
                        <option value="Volvo V60">Volvo V60</option>
                        <option value="Volvo V70">Volvo V70</option>
                        <option value="Volvo V90">Volvo V90</option>
                        <option value="Volvo XC40">Volvo XC40</option>
                        <option value="Volvo XC60 (2013 Down)">
                          Volvo XC60 (2013 Down)
                        </option>
                        <option value="Volvo XC60 (2014 Up)">
                          Volvo XC60 (2014 Up)
                        </option>
                        <option value="Volvo XC70">Volvo XC70</option>
                        <option value="Volvo XC90">Volvo XC90</option>
                        <option value="VPG MV1">VPG MV1</option>
                        <option value="Western Star">Western Star</option>
                        <option value="White">White</option>
                        <option value="Willys">Willys</option>
                        <option value="Winnebago">Winnebago</option>
                        <option value="Yugo">Yugo</option>
                      </select>
                    </div>

                    {/* Name Input */}
                    <div className="flex flex-col">
                      <label className="text-black mb-2">
                        Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData?.name}
                        onChange={handleChange}
                        placeholder="Enter your name"
                        className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 bg-white text-black"
                        required
                      />
                    </div>

                    {/* Email Input */}
                    <div className="flex flex-col">
                      <label className="text-black mb-2">
                        Email <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData?.email}
                        onChange={handleChange}
                        placeholder="Enter your email"
                        className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 bg-white text-black"
                        required
                      />
                    </div>

                    {/* Phone Number Input */}
                    <div className="flex flex-col">
                      <label className="text-black mb-2">
                        Phone Number <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="tel"
                        name="phone"
                        value={formData?.phone}
                        onChange={handleChange}
                        placeholder="Enter your phone number"
                        className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 bg-white text-black"
                        required
                      />
                    </div>

                    {/* Zip Code Input */}
                    <div className="flex flex-col">
                      <label className="text-black mb-2">
                        Zip Code <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="zip"
                        value={formData?.zip}
                        onChange={handleChange}
                        placeholder="Enter your zip code"
                        className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 bg-white text-black"
                        required
                      />
                    </div>

                    {/* Year Input */}
                    <div className="flex flex-col">
                      <label className="text-black mb-2">
                        Year <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="year"
                        value={formData?.year}
                        onChange={handleChange}
                        placeholder="Enter year"
                        className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 bg-white text-black"
                        required
                      />
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className="mt-6">
                    <button
                      type="submit"
                      className="w-full bg-[#ffec16] text-black font-semibold py-3 rounded-md hover:bg-yellow-400 transition-colors"
                    >
                      Find My Part Now
                    </button>
                  </div>
                </form>
              </div>

              {/* Call Now Section */}
              <div className="bg-[#2563eb] text-center py-4 sm:py-6 px-4 rounded-lg shadow-md">
                <p className="text-2xl sm:text-3xl md:text-4xl font-bold text-white">
                  +1-888-711-6990
                </p>
                <p className="text-base sm:text-xl text-white">
                  CALL NOW TO ORDER
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
