import React from "react";

const SearchByCar = () => {
  const carParts = [
    [
      "Front End Assembly",
      "Front Clip",
      "Header Panel",
      "Spoiler - Front",
      "Front Spoiler",
      "Valance - Front",
      "Grille",
      "Bumper - Front",
      "Front Bumper",
      "Bumper Reinforcement - Front",
      "Front Bumper Reinforcement",
      "Radiator Core Support",
      "Fender",
      "Front Body Panel",
      "Front Fender",
      "Head Light Assembly",
      "Side Lamp (Front)",
      "Front Side Lamp",
      "Hood",
      "Hood Hinge",
      "Door Assembly - Front",
      "Front Door Hinge",
    ],
    [
      "Window Regulator - Front",
      "Door Window Regulator - Front",
      "Car Window Lifter",
      "Car Window Regulator",
      "Front Window Lifter",
      "Window Lifter",
      "Window Regulator",
      "Side View Mirror",
      "Door Assembly - Rear",
      "Rear Door Assembly",
      "Window Regulator - Rear",
      "Door Window Regulator - Rear",
      "Rear Clip Assembly",
      "Quarter Panel",
      "Rear Body Panel",
      "Rear Fender",
      "Rear Quarter Panel",
      "Quarter Window Regulator - Rear",
      "Window Regulator (Quarter) - Rear",
      "Tail Light",
      "Backup Lamp",
      "Side Lamp (Rear)",
    ],
    [
      "Rear Side Lamp",
      "Spoiler - Rear",
      "Rear Spoiler",
      "Trunk Lid",
      "Tailgate",
      "Decklid",
      "High Mounted Stop Light",
      "Tail Gate Hinge",
      "Tail Gate Window Regulator",
      "Window Regulator (Tail Gate)",
      "Tail Gate Molding",
      "Rear Window Washer Motor",
      "Bumper - Rear",
      "Rear Bumper",
      "Bumper Reinforcement - Rear",
      "Rear Bumper Reinforcement",
      "Tail Panel",
      "Tail Finish Panel",
      "Seat Track - Front",
      "Steering Column",
      "Head Light Switch",
      "Windshield Wiper Switch",
    ],
    [
      "Cruise Switch",
      "Turn Signal Lever",
      "Fog Light Stalk",
      "Dash Panel",
      "Speedometer Cluster",
      "Rear View Mirror",
      "Back Glass",
      "Door Glass - Front (Side)",
      "Front Door Glass (Side)",
      "Door Glass - Rear (Side)",
      "Rear Door Glass (Side)",
      "Door Vent Glass - Rear (Side)",
      "Rear Door Vent Glass (Side)",
      "Door Vent Glass - Front (Side)",
      "Front Door Vent Glass (Side)",
      "Quarter Glass",
      "Roof Glass",
      "Sun Roof",
      "Moon Roof",
      "Engine Assembly",
      "Front Door Assembly",
    ],
    [
      "Cylinder Block",
      "Crankshaft",
      "Camshaft",
      "Cylinder Head",
      "Rocker Arm",
      "Timing Cover",
      "Harmonic Balancer",
      "Oil Pan",
      "Intercooler",
      "Engine Oil Cooler",
      "Air Cleaner Box",
      "Carburetor",
      "Turbocharger",
      "Supercharger",
      "Fuel Injection Parts",
      "Fuel Pump",
      "Water Pump",
      "Fan Blade",
      "Fan Clutch",
      "Exhaust Manifold",
    ],
    [
      "Intake Manifold",
      "Air Flow Meter",
      "Throttle Body Assembly",
      "Air Injection Pump",
      "Vacuum Pump",
      "Transmission",
      "Overdrive Unit Transmission",
      "Pressure Plate",
      "Torque Converter",
      "Bell Housing",
      "Flywheel",
      "Clutch Disc",
      "Transfer Case Assembly",
      "Clutch Master Cylinder",
      "Clutch Slave Cylinder",
      "Transfer Case",
      "Drive Shaft - Front",
      "Drive Shaft - Rear",
      "Axle - Front",
      "Axle - Rear",
    ],
    [
      "Front Axle",
      "Rear Axle",
      "Axle Housing",
      "Carrier Case",
      "Carrier Assembly",
      "Differential Flange",
      "Differential Case",
      "Differential Assembly",
      "Ring Gear and Pinion Assembly",
      "Axle Shaft",
      "Rear Independent Suspension Assembly",
      "Beam Axle",
      "Loaded Beam Axle",
      "Suspension Crossmember",
      "K-Frame",
      "Stub Axle - Rear",
      "Upper Control Arm - Rear",
      "Control Arm - Upper (Rear)",
      "Knee",
      "Upper Control Arm - Front",
    ],
    [
      "Control Arm - Upper (Front)",
      "Lower Control Arm - Front",
      "Control Arm - Lower (Front)",
      "Lower Control Arm - Rear",
      "Control Arm - Lower (Rear)",
      "Rear Lower Control Arm",
      "Spindle Knuckle - Front",
      "Leaf Spring - Front",
      "Coil Spring",
      "Leaf Spring - Rear",
      "Front Axle I-Beam (2WD)",
      "Torsion Bar",
      "Suspension Compressor/Pump",
      "Stabilizer Bar",
      "Shock Absorber",
      "Strut",
      "Hub Brakes",
      "Power Brake Boosters",
      "Brake Master Cylinder",
      "ABS System (Anti-Lock)",
    ],
  ];

  return (
    <div className="md:p-20 p-10">
      <h1 className="text-xl font-bold mb-4">Search by Part Type</h1>
      <div className="grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-4 gap-4 md:px-10">
        {carParts?.map((column, colIndex) => (
          <div key={colIndex} className="space-y-2">
            {column?.map((part, partIndex) => (
              <div
                key={partIndex}
                className="border border-gray-300 p-2 rounded-md text-center shadow-sm hover:bg-gray-100"
              >
                {part}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchByCar;
