import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Banner from "../components/common/Banner";
import banner from "../assets/part.jpg";
import BottomCarousel from "../components/common/BottomCarousel";
import SearchByPart from "../components/part/SearchByPart";
import SearchByCar from "../components/part/SearchByCar";
import { useNavigate } from "react-router-dom";

const PartList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('car-make');

  useEffect(() => {
    // Check if state is passed from navigation
    if (location.state && location.state.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Banner pageName="PART LIST" backgroundImage={banner} />

      <div className="bg-gray-300 md:py-20 py-10">
        <p className="text-black md:text-xl text-lg text-center md:px-32 px-10 font-semibold mb-3 md:mb-0">
          We carry huge stock{" "}
          <span className="text-blue-600">Global Auto Experts</span> in our store.
          Auto Body Parts stock comprises of doors, bonnets, wind shields,
          shock absorbers, front grills, back cabins, roofs and all other body parts.
          We have in our stock body parts of all popular models including
          AMC, Acura, Ford, BMW etc..
        </p>
      </div>

      {/* Tabs for Part Search */}
      <div className="flex justify-center mt-10 mb-6">
        <div className="flex border-2 border-blue-600 rounded-lg overflow-hidden">
          <button
            onClick={() => handleTabChange('car-make')}
            className={`px-6 py-2 ${
              activeTab === 'car-make'
                ? 'bg-blue-600 text-white'
                : 'bg-white text-blue-600 hover:bg-blue-100'
            }`}
          >
            Search By Car Make
          </button>
          <button
            onClick={() => handleTabChange('part-type')}
            className={`px-6 py-2 ${
              activeTab === 'part-type'
                ? 'bg-blue-600 text-white'
                : 'bg-white text-blue-600 hover:bg-blue-100'
            }`}
          >
            Search By Part Type
          </button>
        </div>
      </div>

      {/* Conditional Rendering of Search Components */}
      {activeTab === 'part-type' ? <SearchByCar />:<SearchByPart />  }

      {/* Find Your Orders Section */}
      <div className="bg-blue-600 py-5">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-center md:justify-between px-5">
          <div className="flex flex-col items-center md:items-start gap-1 text-center md:text-left">
            <p className="text-white md:text-5xl text-3xl font-semibold mb-3 md:mb-0">
              FIND YOUR ORDERS?
            </p>
            <span className="md:text-4xl text-2xl text-white">
              +1-888-711-6990
            </span>
          </div>
          <button
            onClick={() => navigate("/part-request")}
            className="bg-yellow-400 text-black font-bold py-2 px-6 mt-4 md:mt-0 rounded-md hover:bg-yellow-300"
          >
            Find My Part Now
          </button>
        </div>
      </div>

      <BottomCarousel />
    </div>
  );
};

export default PartList;